import { ApolloError, MutationHookOptions, OperationVariables, QueryHookOptions, useMutation as useMutationApollo, useQuery as useQueryApollo } from "@apollo/client";
import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { message, notification } from "antd";
import { DocumentNode } from "graphql";
import i18n from "../common/i18n";

interface NotifyQueryOptions {
  errorText?: string;
  errorDescription?: string;
}

export const useQuery = <
  TData = any,
  TVariables = OperationVariables
>(query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables> & NotifyQueryOptions,
) => {
  const opts = {
    ...options,
    onError: (e: ApolloError) => {
      notification.error({
        message: options?.errorText || i18n.t("apollo:Error"),
        description: options?.errorDescription || e.message,
      });

      if (options?.onError) {
        options.onError(e);
      }
    }
  };

  return useQueryApollo<TData, TVariables>(query, opts);
};

interface NotifyMutationOptions {
  okText?: string;
  errorText?: string;
  errorDescription?: string;
}

export const useMutation = <
  TData = any,
  TVariables = OperationVariables
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables> & NotifyMutationOptions
) => {
  const opts = {
    ...options,
    onCompleted: (d: TData) => {
      message.success(options?.okText || i18n.t("apollo:Success"));

      if (options?.onCompleted) {
        options.onCompleted(d);
      }
    },
    onError: (e: ApolloError) => {
      notification.error({
        message: options?.errorText || i18n.t("apollo:Error"),
        description: options?.errorDescription || e.message,
      });

      if (options?.onError) {
        options.onError(e);
      }
    }
  };

  return useMutationApollo<TData, TVariables>(mutation, opts);
};
