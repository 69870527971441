import { Button, Form, Popconfirm, Spin } from "antd";
import { FormInstance } from "antd/lib/form";
import React, { Fragment } from "react";
import i18n from "../common/i18n";

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

interface EditFormProps<T> {
  name: string;
  deleteTitle?: string;
  loading: boolean;
  form: FormInstance<T>;
  initialValues?: T,
  onSave: (values: T) => void;
  onDelete?: () => void;
}

export const EditForm = <T,>(props: React.PropsWithChildren<EditFormProps<T>>) => {
  if (props.loading) {
    return <Spin />;
  }

  return (
    <Fragment>
      <Form
        {...formLayout}
        form={props.form}
        name={props.name}
        initialValues={props.initialValues}
        onFinish={props.onSave}
      >
        {props.children}
        <Form.Item {...tailLayout}>
          {props.onDelete &&
            < Popconfirm
              title={props.deleteTitle || i18n.t("edit:Delete?")}
              okText={i18n.t("edit:Yes")}
              cancelText={i18n.t("edit:No")}
              onConfirm={props.onDelete}
            >
              <Button>{i18n.t("edit:Delete")}</Button>
            </Popconfirm>
          }
          <Button
            htmlType="submit"
            type="primary"
            style={props.onDelete && { margin: "0 8px" }}
          >
            {i18n.t("edit:Save")}
          </Button>
        </Form.Item>
      </Form>
    </Fragment >
  );
};
