import { Avatar, Layout, Menu } from "antd";
import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { authenticationService } from "../../common/auth";
import i18n from "../../common/i18n";
import styles from "./styles.module.less";

const { SubMenu } = Menu;

export const Header = () => {
  const history = useHistory();

  return (
    <Layout.Header className={styles.header} style={{ padding: 0 }}>
      <div className={styles.rightContainer}>
        <Menu key="user" mode="horizontal" onClick={(v) => {
          authenticationService.logout();
          history.push("/login");
        }}>
          <SubMenu
            key="logout"
            title={
              <Fragment>
                <span>{authenticationService.currentUserValue?.login}</span>
                <Avatar style={{ marginLeft: 8 }} />
              </Fragment>
            }
          >
            <Menu.Item key="signOut">
              {i18n.t("header:Sign Out")}
            </Menu.Item>
          </SubMenu>
        </Menu>
      </div>
    </Layout.Header>
  );
};
