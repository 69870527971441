import { Col, Input, Row, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FilterValue, SorterResult, TablePaginationConfig } from "antd/lib/table/interface";
import React, { Fragment } from "react";
import i18n from "../common/i18n";
import { SearchInput } from "../hooks/search";

type objectWithId = object & { id: string }

interface ListContainerProps<T extends objectWithId> {
  create: React.ReactNode;
  search: SearchInput;
  columns: ColumnsType<T>;
  data?: T[];
  total?: number;
  loading: boolean;
  onSearch: (text: string) => void;
  onTableChange: (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<T> | SorterResult<T>[],
  ) => void;
}

export const ListContainer = <T extends objectWithId,>(props: ListContainerProps<T>) => {
  return (
    <Fragment>
      <Row gutter={[24, 16]}>
        <Col span={12}>
          <Input.Search
            allowClear={true}
            defaultValue={props.search.filter}
            placeholder={i18n.t("list:Search...")}
            onSearch={props.onSearch}
          />
        </Col>
        <Col span={2}>
          {props.create}
        </Col>
      </Row>
      <br />
      <Table
        size="middle"
        rowKey={record => record.id}
        loading={props.loading}
        columns={props.columns}
        dataSource={props.data}
        pagination={{
          showSizeChanger: true,
          current: props.search.page,
          pageSize: props.search.pageSize,
          total: props.total,
        }}
        onChange={props.onTableChange}
      />
    </Fragment>
  );
};
