import { Checkbox, Descriptions, Spin } from "antd";
import gql from "graphql-tag";
import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "../hooks";
import * as g from "./__generated__/IPInfo";

const GET_IP_INFO = gql`
  query IPInfo($ip: String!) {
    ipInfo(ip: $ip) {
      ip
      hostname
      continentCode
      continentName
      countryCode2
      countryCode3
      countryName
      countryCapital
      stateProv
      district
      city
      zipcode
      latitude
      longitude
      isEu
      callingCode
      countryTld
      languages
      geonameId
      isp
      connectionType
      organization
      asn
      currency {
        code
        name
        symbol
      }
      timezone {
        name
        offset
        isDst
        dstSavings
      }
      security {
        threatScore
        isTor
        isProxy
        proxyType
        isAnonymous
        isKnownAttacker
        isCloudProvider
      }
    }
  }
`;

export const IPInfo = () => {
  const { ip } = useParams<{ ip: string }>();

  const { loading, data } = useQuery<g.IPInfo, g.IPInfoVariables>(GET_IP_INFO, {
    variables: { ip },
  });

  if (loading) {
    return <Spin />;
  }

  return (
    <Descriptions
      title={ip}
      column={1}
      bordered={true}
      size="small"
    >
      <Descriptions.Item label="Hostname">{data?.ipInfo.hostname}</Descriptions.Item>
      <Descriptions.Item label="ISP">{data?.ipInfo.isp}</Descriptions.Item>
      <Descriptions.Item label="Connection Type">{data?.ipInfo.connectionType}</Descriptions.Item>
      <Descriptions.Item label="Organization">{data?.ipInfo.organization}</Descriptions.Item>
      <Descriptions.Item label="ASN">{data?.ipInfo.asn}</Descriptions.Item>
      <Descriptions.Item label="Continent Code">{data?.ipInfo.continentCode}</Descriptions.Item>
      <Descriptions.Item label="Continent Name">{data?.ipInfo.continentName}</Descriptions.Item>
      <Descriptions.Item label="Country Code 2">{data?.ipInfo.countryCode2}</Descriptions.Item>
      <Descriptions.Item label="Country Code 3">{data?.ipInfo.countryCode3}</Descriptions.Item>
      <Descriptions.Item label="Country Name">{data?.ipInfo.countryName}</Descriptions.Item>
      <Descriptions.Item label="Country Capital">{data?.ipInfo.countryCapital}</Descriptions.Item>
      <Descriptions.Item label="State/Province">{data?.ipInfo.stateProv}</Descriptions.Item>
      <Descriptions.Item label="District/County">{data?.ipInfo.district}</Descriptions.Item>
      <Descriptions.Item label="City">{data?.ipInfo.city}</Descriptions.Item>
      <Descriptions.Item label="Zip Code">{data?.ipInfo.zipcode}</Descriptions.Item>
      <Descriptions.Item label="Latitude">{data?.ipInfo.latitude}</Descriptions.Item>
      <Descriptions.Item label="Longitude">{data?.ipInfo.longitude}</Descriptions.Item>
      <Descriptions.Item label="Is EU?"><Checkbox disabled={true} checked={data?.ipInfo.isEu} /></Descriptions.Item>
      <Descriptions.Item label="Calling Code">{data?.ipInfo.callingCode}</Descriptions.Item>
      <Descriptions.Item label="Country TLD">{data?.ipInfo.countryTld}</Descriptions.Item>
      <Descriptions.Item label="Languages">{data?.ipInfo.languages}</Descriptions.Item>
      <Descriptions.Item label="Geoname ID">{data?.ipInfo.geonameId}</Descriptions.Item>
      <Descriptions.Item label="Currency Code">{data?.ipInfo.currency.code}</Descriptions.Item>
      <Descriptions.Item label="Currency Name">{data?.ipInfo.currency.name}</Descriptions.Item>
      <Descriptions.Item label="Currency Symbol">{data?.ipInfo.currency.symbol}</Descriptions.Item>
      <Descriptions.Item label="Timezone Name">{data?.ipInfo.timezone.name}</Descriptions.Item>
      <Descriptions.Item label="Timezone Offset">{data?.ipInfo.timezone.offset}</Descriptions.Item>
      <Descriptions.Item label="Is DST?"><Checkbox disabled={true} checked={data?.ipInfo.timezone.isDst} /></Descriptions.Item>
      <Descriptions.Item label="Threat Score">{data?.ipInfo.security.threatScore}</Descriptions.Item>
      <Descriptions.Item label="Is Tor?"><Checkbox disabled={true} checked={data?.ipInfo.security.isTor} /></Descriptions.Item>
      <Descriptions.Item label="Is Proxy?"><Checkbox disabled={true} checked={data?.ipInfo.security.isProxy} /></Descriptions.Item>
      <Descriptions.Item label="Proxy Type">{data?.ipInfo.security.proxyType}</Descriptions.Item>
      <Descriptions.Item label="Is Anonymous?"><Checkbox disabled={true} checked={data?.ipInfo.security.isAnonymous} /></Descriptions.Item>
      <Descriptions.Item label="Is Known Attacker?"><Checkbox disabled={true} checked={data?.ipInfo.security.isKnownAttacker} /></Descriptions.Item>
      <Descriptions.Item label="Is Cloud Provider?"><Checkbox disabled={true} checked={data?.ipInfo.security.isCloudProvider} /></Descriptions.Item>
    </Descriptions>
  );
};
