import { Card, Col, DatePicker, Radio, Row, Table, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import gql from "graphql-tag";
import moment from "moment";
import React, { useReducer } from "react";
import { Link } from "react-router-dom";
import { Date } from "../../components/Date";
import { useQuery } from "../../hooks";
import * as g from "./__generated__/SearchPromoEvent";


const SEARCH_EVENTS = gql`
  query SearchPromoEvent($id: ID!, $input: SearchPromoEventInput!) {
    searchPromoEvent(id: $id, input: $input) {
      data {
        id
        user {
          id
          login
        }
        kind
        referrer
        createdAt
      }
      page
      pageSize
      total
    }
  }
`;

interface PromoEventsProps {
  id: string;
}

interface PromoEventsState {
  from: Date
  to: Date
  page: number
  pageSize: number
  kind: number
}

const initialState: PromoEventsState = {
  from: moment().startOf("month").toDate(),
  to: moment().endOf("month").toDate(),
  page: 1,
  pageSize: 20,
  kind: 1,
};

const promoEventsReducer = (
  state: PromoEventsState,
  action: { type: string, data: any }
) => {
  switch (action.type) {
    case "KIND":
      return { ...state, kind: action.data };
    case "DATES":
      return { ...state, from: action.data[0], to: action.data[1] };
    case "PAGINATE":
      return { ...state, page: action.data.page, pageSize: action.data.pageSize };
    default:
      throw new Error(`undefined action ${action.type}`);
  }
};

const columns: ColumnsType<g.SearchPromoEvent_searchPromoEvent_data> = [
  {
    title: "User",
    dataIndex: ["user", "login"],
    render: (text, record) => <Link to={`/users/${record.user.id}`}>{text}</Link>
  },
  {
    title: "Event",
    dataIndex: "kind",
    render: (_text, record) => <Tag>{record.kind === 0 ? "view" : "registration"}</Tag>,
  },
  {
    title: "Referrer",
    dataIndex: "referrer",
    key: "referrer",
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
    render: text => <Date date={text} />,
  },
];

const { RangePicker } = DatePicker;

export const PromoEvents = (props: PromoEventsProps) => {
  const [state, dispatch] = useReducer(promoEventsReducer, initialState);

  const result = useQuery<g.SearchPromoEvent, g.SearchPromoEventVariables>(SEARCH_EVENTS, {
    fetchPolicy: "no-cache",
    variables: {
      id: props.id,
      input: {
        from: state.from,
        to: state.to,
        page: state.page,
        pageSize: state.pageSize,
        kind: state.kind,
      },
    }
  });

  return (
    <Card title="Events">
      <Row gutter={24}>
        <Col span={4}>
          <Radio.Group onChange={(e) => dispatch({ type: "KIND", data: e.target.value })} value={state.kind}>
            <Radio.Button value={0}>View</Radio.Button>
            <Radio.Button value={1}>Registration</Radio.Button>
          </Radio.Group>
        </Col>
        <Col span={18}>
          <RangePicker
            allowClear={false}
            showTime={true}
            value={[moment(state.from), moment(state.to)]}
            onChange={(dates) => dispatch({ type: "DATES", data: dates })}
          />
        </Col>
      </Row>
      <br />
      <Table
        size="middle"
        rowKey={record => record.id}
        loading={result.loading}
        columns={columns}
        dataSource={result.data?.searchPromoEvent.data}
        pagination={{
          showSizeChanger: true,
          current: state.page,
          pageSize: state.pageSize,
          total: result.data?.searchPromoEvent.total,
        }}
        onChange={(pagination) => dispatch({ type: "PAGINATE", data: { page: pagination.current, pageSize: pagination.pageSize } })}
      />
    </Card>
  );
};
