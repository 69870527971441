import { Form, Input } from "antd";
import gql from "graphql-tag";
import React, { useState } from "react";
import i18n from "../../common/i18n";
import { CreateForm } from "../../containers/CreateForm";
import { useMutation } from "../../hooks";
import * as g from "./__generated__/CreateAdmin";

const CREATE_ADMIN = gql`
  mutation CreateAdmin($login: String!, $password: String!) {
    createAdmin(login: $login, password: $password) {
      id
      login
      routerAccess
      createdAt
      updatedAt
    }
  }
`;

export const AdminCreate = () => {
  const [form] = Form.useForm<g.CreateAdminVariables>();
  const [isOpen, toggle] = useState(false);

  const [createAdmin] = useMutation<{
    createAdmin: g.CreateAdmin,
    admin: g.CreateAdmin_createAdmin,
  }>(CREATE_ADMIN, {
    okText: i18n.t("admin:Admin created"),
    refetchQueries: ["SearchAdmin"],
    onCompleted: (_) => {
      form.resetFields();
      toggle(false);
    },
  });

  return (
    <CreateForm
      name="create-admin"
      title={i18n.t("admin:Create admin")}
      form={form}
      isOpen={isOpen}
      toggleOpen={() => toggle(!isOpen)}
      onCreate={(values) => createAdmin({ variables: values })}
    >
      <Form.Item
        name="login"
        label={i18n.t("admin:Login")}
        rules={[{ required: true }]}
      >
        <Input placeholder="admin" />
      </Form.Item>
      <Form.Item
        name="password"
        label={i18n.t("admin:Password")}
        rules={[{ required: true }]}
      >
        <Input placeholder="password" />
      </Form.Item>
    </CreateForm>
  );
};
