import { Button, Popconfirm } from "antd";
import { ColumnsType } from "antd/lib/table";
import { SortOrder } from "antd/lib/table/interface";
import gql from "graphql-tag";
import React from "react";
import i18n from "../../common/i18n";
import { Date } from "../../components/Date";
import { ListContainer } from "../../containers/ListContainer";
import { useMutation, useSearch } from "../../hooks";
import { RouterCreate } from "./Create";
import * as gd from "./__generated__/DeleteRouterRestriction";
import * as g from "./__generated__/SearchRouterRestriction";

const SEARCH_ROUTER = gql`
  query SearchRouterRestriction($input: SearchInput!) {
    searchRouterRestriction(input: $input) {
      data {
        id
        filter
        updatedAt
      }
      page
      pageSize
      total
    }
  }
`;

const DELETE_ROUTER = gql`
  mutation DeleteRouterRestriction($id: ID!) {
    deleteRouterRestriction(id: $id)
  }
`;

export const RouterRestrictions = () => {
  const { result, search, updateFilter, updateTable } = useSearch<g.SearchRouterRestriction>(SEARCH_ROUTER);

  const [deleteRouterRestriction] = useMutation<gd.DeleteRouterRestriction, gd.DeleteRouterRestrictionVariables>(DELETE_ROUTER, {
    okText: i18n.t("router:Router restriction deleted"),
    refetchQueries: ["SearchRouterRestriction"],
  });

  const columns: ColumnsType<g.SearchRouterRestriction_searchRouterRestriction_data> = [
    {
      dataIndex: "filter",
      title: i18n.t("router:Filter"),
      sorter: true,
    },
    {
      dataIndex: "updatedAt",
      title: i18n.t("router:Updated At"),
      sorter: true,
      render: text => <Date date={text} />,
      defaultSortOrder: "descend" as SortOrder,
    },
    {
      key: "Delete",
      title: i18n.t("signature:Delete"),
      render: (_, record) => {
        return (
          <Popconfirm
            title={i18n.t("router:Delete router restriction?")}
            okText={i18n.t("router:Yes")}
            cancelText={i18n.t("router:No")}
            onConfirm={() => deleteRouterRestriction({ variables: { id: record.id } })}
          >
            <Button htmlType="button">
              {i18n.t("router:Delete")}
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <ListContainer
      create={<RouterCreate />}
      search={search}
      columns={columns}
      data={result.data?.searchRouterRestriction.data}
      total={result.data?.searchRouterRestriction.total}
      loading={result.loading}
      onSearch={updateFilter}
      onTableChange={updateTable}
    />
  );
};
