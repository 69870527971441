import { Form, Input } from "antd";
import gql from "graphql-tag";
import React, { useState } from "react";
import i18n from "../../common/i18n";
import { CreateForm } from "../../containers/CreateForm";
import { useMutation } from "../../hooks";
import * as g from "./__generated__/CreateRouterRestriction";

const CREATE_ROUTER = gql`
  mutation CreateRouterRestriction($filter: String!) {
    createRouterRestriction(filter: $filter) {
      id
      filter
      updatedAt
    }
  }
`;

export const RouterCreate = () => {
  const [form] = Form.useForm<g.CreateRouterRestrictionVariables>();
  const [isOpen, toggle] = useState(false);

  const [createRouterRestriction] = useMutation<{
    createRouter: g.CreateRouterRestriction,
    admin: g.CreateRouterRestriction_createRouterRestriction,
  }>(CREATE_ROUTER, {
    okText: i18n.t("router:Router restriction created"),
    refetchQueries: ["SearchRouterRestriction"],
    onCompleted: (_) => {
      form.resetFields();
      toggle(false);
    },
  });

  return (
    <CreateForm
      name="create-router"
      title={i18n.t("router:Create router restriction")}
      form={form}
      isOpen={isOpen}
      toggleOpen={() => toggle(!isOpen)}
      onCreate={(values) => createRouterRestriction({ variables: values })}
    >
      <Form.Item
        name="filter"
        label={i18n.t("router:Filter")}
        rules={[{ required: true }]}
      >
        <Input placeholder="192.168.1.1/16" />
      </Form.Item>
    </CreateForm>
  );
};
