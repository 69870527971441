import { Form, Input, InputNumber, Radio } from "antd";
import gql from "graphql-tag";
import React, { useState } from "react";
import i18n from "../../common/i18n";
import { CreateForm } from "../../containers/CreateForm";
import { useMutation } from "../../hooks";
import * as g from "./__generated__/CreateNode";

const CREATE_NODE = gql`
  mutation CreateNode($id: String!, $ipLastOctet: Int!, $kind: String) {
    createNode(id: $id, ipLastOctet: $ipLastOctet, kind: $kind) {
      id
      ipLastOctet
      heartbeat {
        spoofer
        exporter
      }
      iProxy
      createdAt
      updatedAt
    }
  }
`;

export const NodeCreate = () => {
  const [form] = Form.useForm<g.CreateNodeVariables>();
  const [isOpen, toggle] = useState(false);

  const [createNode] = useMutation<{
    createNode: g.CreateNode,
    node: g.CreateNode_createNode,
  }>(CREATE_NODE, {
    okText: i18n.t("node:Node created"),
    refetchQueries: ["SearchNode"],
    onCompleted: (_) => {
      form.resetFields();
      toggle(false);
    },
  });

  return (
    <CreateForm
      name="create-node"
      title={i18n.t("node:Create node")}
      form={form}
      isOpen={isOpen}
      toggleOpen={() => toggle(!isOpen)}
      onCreate={(values) => createNode({ variables: values })}
    >
      <Form.Item
        name="id"
        label={i18n.t("node:ID")}
        rules={[{ required: true }]}
      >
        <Input placeholder="UK-VPNCL01" />
      </Form.Item>
      <Form.Item
        name="ipLastOctet"
        label={i18n.t("node:IP Last Octet")}
        rules={[{ required: true }]}
      >
        <InputNumber placeholder="101" min={0} max={255} />
      </Form.Item>
      <Form.Item
        name="kind"
        label="Type"
      >
        <Radio.Group defaultValue="regular" buttonStyle="solid">
          <Radio.Button value="regular">Regular</Radio.Button>
          <Radio.Button value="iProxy">iProxy</Radio.Button>
        </Radio.Group>
      </Form.Item>
    </CreateForm>
  );
};
