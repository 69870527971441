import { Checkbox } from "antd";
import { ColumnsType } from "antd/lib/table";
import { SortOrder } from "antd/lib/table/interface";
import gql from "graphql-tag";
import React from "react";
import { Link } from "react-router-dom";
import i18n from "../../common/i18n";
import { Date } from "../../components/Date";
import { ListContainer } from "../../containers/ListContainer";
import { useSearch } from "../../hooks";
import { AdminCreate } from "./Create";
import * as g from "./__generated__/SearchAdmin";

const SEARCH_ADMIN = gql`
  query SearchAdmin($input: SearchInput!) {
    searchAdmin(input: $input) {
      data {
        id
        login
        role
        routerAccess
        createdAt
        updatedAt
      }
      page
      pageSize
      total
    }
  }
`;

const columns: ColumnsType<g.SearchAdmin_searchAdmin_data> = [
  {
    dataIndex: "login",
    title: i18n.t("admin:Login"),
    sorter: true,
    render: (text, record) => <Link to={`/admins/${record.id}`}>{text}</Link>,
  },
  {
    dataIndex: "role",
    title: i18n.t("admin:Role"),
  },
  {
    key: "routerAccess",
    title: i18n.t("admin:Router access"),
    sorter: true,
    render: (_, record) => <Checkbox defaultChecked={record.routerAccess} disabled />,
  },
  {
    dataIndex: "createdAt",
    title: i18n.t("admin:Created At"),
    sorter: true,
    render: text => <Date date={text} />,
  },
  {
    dataIndex: "updatedAt",
    title: i18n.t("admin:Updated At"),
    sorter: true,
    render: text => <Date date={text} />,
    defaultSortOrder: "descend" as SortOrder,
  },
];

export const AdminList = () => {
  const { result, search, updateFilter, updateTable } = useSearch<g.SearchAdmin>(SEARCH_ADMIN);

  return (
    <ListContainer
      create={<AdminCreate />}
      search={search}
      columns={columns}
      data={result.data?.searchAdmin.data}
      total={result.data?.searchAdmin.total}
      loading={result.loading}
      onSearch={updateFilter}
      onTableChange={updateTable}
    />
  );
};
