import { Button, Form, Select, Slider, Spin } from "antd";
import gql from "graphql-tag";
import React, { Fragment, useReducer } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "../../hooks";
import * as gg from "../../__generated__/globalTypes";
import * as g from "./__generated__/PlanTarget";
import * as gt from "./__generated__/SearchPoolTarget";
import * as gu from "./__generated__/UpdatePlanTarget";

const GET_PLAN_TARGET = gql`
  query PlanTarget($id: ID!) {
    plan(id: $id) {
      id
      pool
      reservation
    }
  }
`;

const UPDATE_PLAN_TARGET = gql`
  mutation UpdatePlanTarget($id: ID!, $input: PlanUpdateInput!) {
    updatePlan(id: $id, input: $input) {
      id
      pool
      reservation
    }
  }
`;

const SEARCH_POOL = gql`
  query SearchPoolTarget($kind: PortTargetKind!, $filter: String) {
    searchPortTarget(kind: $kind, filter: $filter) {
      kind
      pool
      poolName
      modem
    }
  }
`;

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const sliderMarks = {
  "50": "50%",
  "100": "100%",
  "150": "150%",
  "200": "200%",
};

interface EditTargetState {
  all: string[];
  pool: string;
  filter: string;
  reservation: number;
}

const defaultState = {
  all: [],
  pool: "",
  filter: "",
  reservation: 0,
};

const editTargetReducer = (
  state: EditTargetState,
  action: { type: string, data: any }
) => {
  switch (action.type) {
    case "INIT":
      return { ...state, pool: action.data.pool, reservation: action.data.reservation };
    case "SET_POOL":
      return { ...state, pool: action.data };
    case "SET_FILTER":
      return { ...state, filter: action.data };
    case "SET_RESERVATION":
      return { ...state, reservation: action.data as number };
    default:
      throw new Error(`undefined action ${action.type}`);
  }
};

export const EditTarget = () => {
  const { id } = useParams<{ id: string }>();
  const [state, dispatch] = useReducer(editTargetReducer, defaultState);

  const { loading, data } = useQuery<g.PlanTarget, g.PlanTargetVariables>(GET_PLAN_TARGET, {
    variables: { id },
    onCompleted: (data) => {
      dispatch({ type: "INIT", data: data.plan });
    }
  });

  const [updatePlan] = useMutation<gu.UpdatePlanTarget, gu.UpdatePlanTargetVariables>(UPDATE_PLAN_TARGET, {
    okText: "Plan updated",
    refetchQueries: ["Plan"]
  });

  const { "data": targetData } = useQuery<gt.SearchPoolTarget, gt.SearchPoolTargetVariables>(SEARCH_POOL, {
    fetchPolicy: "no-cache",
    variables: {
      kind: "pool" as gg.PortTargetKind,
      filter: state.filter,
    }
  });

  if (loading) {
    return <Spin />;
  }

  return (
    <Fragment>
      <h3>Target</h3><br />
      <Form {...formLayout} initialValues={data?.plan}>
        <Form.Item
          name="pool"
          label="Pool"
        >
          <Select
            style={{ width: "100%" }}
            placeholder="Select pool..."
            filterOption={false}
            showSearch={true}
            onSearch={(val) => dispatch({ type: "SET_FILTER", data: val })}
            value={state.pool}
            onSelect={(val: string) => dispatch({ type: "SET_POOL", data: val })}
          >
            {targetData?.searchPortTarget.map(d =>
              <Select.Option key={d.poolName!} value={d.poolName!}>{d.poolName}</Select.Option>
            )}
          </Select>
        </Form.Item>

        <Form.Item
          name="reservation"
          label="Reservation"
        >
          <Slider
            min={50}
            max={200}
            marks={sliderMarks}
            value={state.reservation}
            onChange={(val: number) => dispatch({ type: "SET_RESERVATION", data: val })}
          />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button
            htmlType="submit"
            type="primary"
            onClick={() => updatePlan({ variables: { id, input: { pool: state.pool, reservation: state.reservation } } })}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </Fragment>
  );
};
