import { Button, Card, Col, DatePicker, Empty, Form, Row, Select, Space, Spin, Statistic, Switch } from "antd";
import gql from "graphql-tag";
import moment from "moment";
import React, { Fragment, useReducer } from "react";
import { useParams } from "react-router-dom";
import { formatCurrency, formatDate } from "../../common/formatter";
import { useMutation, useQuery } from "../../hooks";
import { InputDataAmount } from "../Inputs";
import { LogListControlled } from "../Log";
import * as g from "./__generated__/PortPlan";
import * as gs from "./__generated__/SearchPortPlan";
import * as gu from "./__generated__/UpdatePortPlan";
import * as gc from "./__generated__/UpdatePortPlanCurrent";

const GET_PORT_PLAN = gql`
  query PortPlan($id: ID!) {
    port(id: $id) {
      id
      plan {
        enabled
        id
        name
        autoRenew
        vpnAccess
        tarification {
          time
          traffic
          price
        }
        activatedAt
        expiresAt
        trafficRemains
      }
    }
  }
`;

const SEARCH_PORT_PLAN = gql`
  query SearchPortPlan($input: SearchInput!) {
    searchPlan(input: $input) {
      data {
        id
        name
        tarifications {
          time
          traffic
          price
        }
        partnerTarifications {
          time
          traffic
          price
        }
        partnerTarifications2 {
          time
          traffic
          price
        }
        partnerTarifications3 {
          time
          traffic
          price
        }
        partnerTarifications4 {
          time
          traffic
          price
        }
        partnerTarifications5 {
          time
          traffic
          price
        }
      }
    }
  }
`;

const UPDATE_PORT_PLAN = gql`
  mutation UpdatePortPlan($id: ID!, $input: PortPlanInput!) {
    updatePortPlan(id: $id, input: $input) {
      id
    }
  }
`;

const UPDATE_PORT_PLAN_CURRENT = gql`
  mutation UpdatePortPlanCurrent($id: ID!, $input: PortPlanCurrentInput!) {
    updatePortPlanCurrent(id: $id, input: $input) {
      id
      plan {
        enabled
        id
        name
        autoRenew
        vpnAccess
        tarification {
          time
          traffic
          price
        }
        activatedAt
        expiresAt
        trafficRemains
      }
    }
  }
`;

const formLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 3, span: 16 },
};

const formatOptTitle = (opt: any) => {
  if (opt.time === undefined) {
    return undefined;
  }

  return `${opt.time / 86400} Days / ${opt.traffic <= 0 ? "∞" : opt.traffic / 1024} GB / ${formatCurrency(opt.price / 100)}`;
};

interface IPortPlanState {
  filter: string;
  recharge: boolean;
  updateTarget: boolean;
  updateExpiration: boolean;
  plan: string | undefined,
  selectedOpt: any;
  availableOpts: any[],
  autoRenew: boolean;
  expiresAt: moment.Moment;
  trafficRemains: number;
}

const initialState: IPortPlanState = {
  recharge: false,
  updateTarget: true,
  updateExpiration: true,
  filter: "",
  plan: undefined,
  selectedOpt: { price: 0 },
  availableOpts: [],
  autoRenew: false,
  expiresAt: moment(),
  trafficRemains: -1,
};

const portPlanReducer = (
  state: IPortPlanState,
  action: { type: string, data: any },
) => {
  switch (action.type) {
    case "INIT":
      return { ...state };
    case "SEARCH":
      return { ...state, filter: action.data };
    case "SELECT":
      return {
        ...state,
        plan: action.data.name,
        selectedOpt: { price: 0 },
        availableOpts: [
          ...action.data.tarifications.map((x: any) => {
            return {
              id: `base-${x.time}-${x.traffic}-${x.price}`,
              kind: "base",
              ...x,
            };
          }),
          ...action.data.partnerTarifications.map((x: any) => {
            return {
              id: `partner1-${x.time}-${x.traffic}-${x.price}`,
              kind: "partner1",
              ...x,
            };
          }),
          ...action.data.partnerTarifications2.map((x: any) => {
            return {
              id: `partner2-${x.time}-${x.traffic}-${x.price}`,
              kind: "partner2",
              ...x,
            };
          }),
          ...action.data.partnerTarifications3.map((x: any) => {
            return {
              id: `partner3-${x.time}-${x.traffic}-${x.price}`,
              kind: "partner3",
              ...x,
            };
          }),
          ...action.data.partnerTarifications4.map((x: any) => {
            return {
              id: `partner4-${x.time}-${x.traffic}-${x.price}`,
              kind: "partner4",
              ...x,
            };
          }),
          ...action.data.partnerTarifications5.map((x: any) => {
            return {
              id: `partner5-${x.time}-${x.traffic}-${x.price}`,
              kind: "partner5",
              ...x,
            };
          }),
        ]
      };
    case "SELECT_OPT":
      return {
        ...state,
        selectedOpt: action.data,
      };
    case "TOGGLE_RECHARGE":
      return {
        ...state,
        recharge: !state.recharge,
      };
    case "TOGGLE_UPDATE_TARGET":
      return {
        ...state,
        updateTarget: !state.updateTarget,
      };
    case "TOGGLE_UPDATE_EXPIRATION":
      return {
        ...state,
        updateExpiration: !state.updateExpiration,
      };
    case "INIT_CURRENT":
      return {
        ...state,
        autoRenew: action.data.autoRenew,
        expiresAt: moment(action.data.expiresAt),
        trafficRemains: action.data.trafficRemains,
      };
    case "TOGGLE_AUTO_RENEW":
      return { ...state, autoRenew: !state.autoRenew };
    case "UPDATE_EXPIRES_AT":
      return { ...state, expiresAt: action.data };
    case "UPDATE_TRAFFIC_REMAINS":
      return { ...state, trafficRemains: action.data };
    default:
      throw new Error(`undefined action ${action.type}`);
  }
};

export const PortPlan = () => {
  const { id } = useParams<{ id: string }>();
  const [state, dispatch] = useReducer(portPlanReducer, initialState);
  const [form] = Form.useForm();

  const { data, loading } = useQuery<g.PortPlan, g.PortPlanVariables>(GET_PORT_PLAN, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    variables: { id },
    onCompleted: (data) => {
      dispatch({ type: "INIT_CURRENT", data: data.port.plan });
    }
  });

  const { "data": searchPlanData } = useQuery<gs.SearchPortPlan, gs.SearchPortPlanVariables>(SEARCH_PORT_PLAN, {
    fetchPolicy: "no-cache",
    variables: {
      input: {
        filter: state.filter,
        sorter: "name_ascend",
        page: 1,
        pageSize: 0,
      }
    }
  });

  const [updatePlan] = useMutation<gu.UpdatePortPlan, gu.UpdatePortPlanVariables>(UPDATE_PORT_PLAN, {
    okText: "Port updated",
    refetchQueries: ["PortPlan"],
  });

  const [updatePlanCurrent] = useMutation<gc.UpdatePortPlanCurrent, gc.UpdatePortPlanCurrentVariables>(UPDATE_PORT_PLAN_CURRENT, {
    okText: "Port updated",
  });

  if (loading) {
    return <Spin />;
  }

  let currentSection = <Empty />;
  if (data && data.port.plan.enabled) {
    currentSection = (
      <Space direction="vertical" style={{ width: "100%" }}>
        <Row gutter={24}>
          <Col span={8}>
            <Statistic title="Plan" value={data.port.plan.name} />
          </Col>
          <Col span={8}>
            <Statistic title="Option" value={formatOptTitle(data.port.plan.tarification)} />
          </Col>
          <Col span={8}>
            <Statistic title="Activated At" value={formatDate(data.port.plan.activatedAt)} />
          </Col>
        </Row>

        <br /><br />

        <Form
          {...formLayout}
          form={form}
          name="edit-current"
        >
          <Form.Item
            label="Auto Renew"
          >
            <Switch
              checked={state.autoRenew}
              onChange={_ => dispatch({ type: "TOGGLE_AUTO_RENEW", data: {} })}
            />
          </Form.Item>
          <Form.Item
            label="Expires At"
          >
            <DatePicker
              showTime={true}
              value={state.expiresAt}
              onChange={val => dispatch({ type: "UPDATE_EXPIRES_AT", data: val })}
            />
          </Form.Item>
          <Form.Item
            label="Traffic Remains"
          >
            <InputDataAmount
              value={state.trafficRemains}
              defaultUnit="mb"
              onChange={val => dispatch({ type: "UPDATE_TRAFFIC_REMAINS", data: val })}
              onSetInfinite={() => dispatch({ type: "UPDATE_TRAFFIC_REMAINS", data: -1 })}
            />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button
              htmlType="submit"
              type="primary"
              onClick={() => updatePlanCurrent({ variables: { id, input: { autoRenew: state.autoRenew, expiresAt: state.expiresAt, trafficRemains: state.trafficRemains } } })}
            >
              Update
            </Button>
          </Form.Item>
        </Form>
      </Space >
    );
  }

  return (
    <Fragment>
      <Card title="Current">
        {currentSection}
      </Card>

      <br />

      <Card title="Change">
        <Space direction="vertical">
          <Select
            value={state.plan}
            placeholder="Select plan"
            filterOption={false}
            showSearch={true}
            onSearch={(val) => dispatch({ type: "SEARCH", data: val })}
            onChange={(val) => dispatch({ type: "SELECT", data: searchPlanData!.searchPlan.data.find(x => x.id === val) })}
            style={{ width: "640px" }}
          >
            {searchPlanData?.searchPlan.data.map(d =>
              <Select.Option key={d.id} value={d.id}>{d.name}</Select.Option>
            )}
          </Select>

          <Select
            value={formatOptTitle(state.selectedOpt)}
            placeholder="Select option"
            filterOption={false}
            onChange={(val) => dispatch({ type: "SELECT_OPT", data: state.availableOpts.find((x: any) => x.id === val) })}
            style={{ width: "640px" }}
          >
            {
              state.availableOpts.filter(x => x.kind === "base").length > 0 && <Select.OptGroup label="Options">
                {state.availableOpts.filter(x => x.kind === "base").map((d: any) =>
                  <Select.Option key={d.id} value={d.id}>{formatOptTitle(d)}</Select.Option>
                )}
              </Select.OptGroup>
            }
            {
              state.availableOpts.filter(x => x.kind === "partner1").length > 0 && <Select.OptGroup label="Partner1 Options">
                {state.availableOpts.filter(x => x.kind === "partner1").map((d: any) =>
                  <Select.Option key={d.id} value={d.id}>{formatOptTitle(d)}</Select.Option>
                )}
              </Select.OptGroup>
            }
            {
              state.availableOpts.filter(x => x.kind === "partner2").length > 0 && <Select.OptGroup label="Partner2 Options">
                {state.availableOpts.filter(x => x.kind === "partner2").map((d: any) =>
                  <Select.Option key={d.id} value={d.id}>{formatOptTitle(d)}</Select.Option>
                )}
              </Select.OptGroup>
            }
            {
              state.availableOpts.filter(x => x.kind === "partner3").length > 0 && <Select.OptGroup label="Partner3 Options">
                {state.availableOpts.filter(x => x.kind === "partner3").map((d: any) =>
                  <Select.Option key={d.id} value={d.id}>{formatOptTitle(d)}</Select.Option>
                )}
              </Select.OptGroup>
            }
            {
              state.availableOpts.filter(x => x.kind === "partner4").length > 0 && <Select.OptGroup label="Partner4 Options">
                {state.availableOpts.filter(x => x.kind === "partner4").map((d: any) =>
                  <Select.Option key={d.id} value={d.id}>{formatOptTitle(d)}</Select.Option>
                )}
              </Select.OptGroup>
            }
            {
              state.availableOpts.filter(x => x.kind === "partner5").length > 0 && <Select.OptGroup label="Partner5 Options">
                {state.availableOpts.filter(x => x.kind === "partner5").map((d: any) =>
                  <Select.Option key={d.id} value={d.id}>{formatOptTitle(d)}</Select.Option>
                )}
              </Select.OptGroup>
            }
          </Select>

          <Space>
            <Switch
              checked={state.updateTarget}
              checkedChildren="Update Target"
              unCheckedChildren="Update Target"
              onChange={() => dispatch({ type: "TOGGLE_UPDATE_TARGET", data: {} })}
            />

            <Switch
              checked={state.updateExpiration}
              checkedChildren="Update Expiration"
              unCheckedChildren="Update Expiration"
              onChange={() => dispatch({ type: "TOGGLE_UPDATE_EXPIRATION", data: {} })}
            />

            <Switch
              checked={state.recharge}
              checkedChildren={`Charge ${formatCurrency(state.selectedOpt.price / 100)}`}
              unCheckedChildren={`Charge ${formatCurrency(state.selectedOpt.price / 100)}`}
              onChange={() => dispatch({ type: "TOGGLE_RECHARGE", data: {} })}
            />
          </Space>


          <Button
            type="primary"
            style={{ margin: "8px 0" }}
            disabled={!(state.plan !== "" && state.selectedOpt.time)}
            onClick={() => updatePlan({ variables: { id, input: { plan: state.plan, tarification: state.selectedOpt, recharge: state.recharge, updateTarget: state.updateTarget, updateExpiration: state.updateExpiration } } })}
          >
            Update
          </Button>
        </Space>
      </Card>

      <br />
      <LogListControlled subjects={[data!.port.id]} actions={["updatePlan"]} />
    </Fragment>
  );
};
