import { BehaviorSubject } from "rxjs";

interface User {
  login: string
  token: string
}

const currentUserStorage = localStorage.getItem("currentUser");
const currentUserSubject = new BehaviorSubject(currentUserStorage !== null ? JSON.parse(currentUserStorage) as User : null);

const handleResponse = async (response: Response) => {
  const text = await response.text();
  if (!response.ok) {
    if ([401, 403].indexOf(response.status) !== -1) {
      authenticationService.logout();
    }

    return Promise.reject(response.statusText);
  }

  return text && JSON.parse(text);
};

const login = async (login: string, password: string) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ login, password })
  };

  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/web/admin/login`, requestOptions);
  const user = await handleResponse(response);

  localStorage.setItem("currentUser", JSON.stringify(user));
  currentUserSubject.next(user);

  return user;
};

const logout = () => {
  localStorage.removeItem("currentUser");
  currentUserSubject.next(null);
};

export const authenticationService = {
  login,
  logout,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() { return currentUserSubject.value; }
};

