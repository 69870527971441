import { Column } from "@ant-design/charts";
import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { Col, DatePicker, Form, Row, Spin, Statistic } from "antd";
import { DocumentNode } from "graphql";
import moment from "moment";
import React, { Fragment, useEffect, useReducer } from "react";
import i18n from "../common/i18n";
import { useQuery } from "../hooks/apollo";

interface TrafficResult {
  traffic: {
    __typename: "Traffic";
    rx: number;
    tx: number;
    chart: TrafficChartPoint[];
  }
}

interface TrafficChartPoint {
  __typename: "TrafficChartPoint";
  ts: any;
  kind: string;
  value: number;
}

interface TrafficVariables {
  id: string;
  from: any;
  to: any;
  kind: string;
}

const { RangePicker } = DatePicker;

interface ModemTrafficState {
  from: Date
  to: Date
}

const modemTrafficReducer = (
  state: ModemTrafficState,
  action: { type: string, data: any }
) => {
  switch (action.type) {
    case "DATES":
      return { ...state, from: action.data[0], to: action.data[1] };
    default:
      throw new Error(`undefined action ${action.type}`);
  }
};

const initialState = () => {
  return {
    from: moment().add(-24, "hour").toDate(),
    to: moment().toDate(),
  };
};

const config = {
  isStack: true,
  xField: "ts",
  yField: "value",
  seriesField: "kind",
  meta: {
    "kind": {
      values: ["rx", "tx"],
    },
  },
  color: (point: any) => {
    switch (point.kind) {
      case "rx":
        return "#3dbd7d";
      case "tx":
        return "#f46e65";
      default:
        return "#d9d9d9";
    }
  }
};

const formatBytes = (bytes: number) => {
  if (bytes === 0) return "0 B";

  const k = 1024;
  const dm = 2;
  const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const Traffic = (props: {
  id: string,
  kind: string,
  initialFrom?: Date,
  query: DocumentNode | TypedDocumentNode<TrafficResult, TrafficVariables>
}) => {
  const [state, dispatch] = useReducer(modemTrafficReducer, initialState());
  const result = useQuery<TrafficResult, TrafficVariables>(props.query, {
    fetchPolicy: "no-cache",
    variables: {
      id: props.id,
      from: state.from,
      to: state.to,
      kind: props.kind,
    }
  });

  useEffect(() => {
    if (props.initialFrom) {
      const now = moment();
      if (moment(props.initialFrom).isBefore(now)) {
        dispatch({ type: "DATES", data: [moment(props.initialFrom), now] });
      }
    }
  }, [props.initialFrom]);

  if (result.loading || result.data === undefined) {
    return <Spin />;
  }

  const chartData = result.data!.traffic.chart.map(point => {
    return {
      ts: moment(point.ts).format("MMM D HH:mm"),
      kind: point.kind,
      value: point.value,
    };
  });

  return (
    <Fragment>
      <Form name="control">
        <Row gutter={24}>
          <Col span={16}>
            <RangePicker
              allowClear={false}
              showTime={true}
              value={[moment(state.from), moment(state.to)]}
              onChange={(dates) => dispatch({ type: "DATES", data: dates })}
            />
          </Col>
        </Row>
      </Form>
      <br />
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Statistic
            title={i18n.t("traffic:Rx")}
            value={formatBytes(result.data!.traffic.rx)}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title={i18n.t("traffic:Tx")}
            value={formatBytes(result.data!.traffic.tx)}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title={i18n.t("traffic:Total")}
            value={formatBytes(result.data!.traffic.rx + result.data!.traffic.tx)}
          />
        </Col>
      </Row>
      <br />
      <Column data={chartData} {...config} />
    </Fragment>
  );
};
