import { DownloadOutlined } from "@ant-design/icons";
import { Button, Checkbox, Popover, Space } from "antd";
import { ColumnsType } from "antd/lib/table";
import { SortOrder } from "antd/lib/table/interface";
import gql from "graphql-tag";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import i18n from "../../common/i18n";
import { Date } from "../../components/Date";
import { ListContainer } from "../../containers/ListContainer";
import { useMutation, useQuery, useSearch } from "../../hooks";
import * as gp from "./__generated__/CreateVPNPort";
import * as g from "./__generated__/SearchVPNPort";
import * as gs from "./__generated__/SearchVPNServerCustomPort";
import * as gf from "./__generated__/VPNConfigCustomPort";

const SEARCH_VPN_PORT = gql`
  query SearchVPNPort($input: SearchInput!) {
    searchVPNPort(input: $input) {
      data {
        id
        credentials {
          kind
          ipFilter
          login
        }
        target
        dnsHijack
        dnsServer
        createdAt
        updatedAt
      }
      page
      pageSize
      total
    }
  }
`;

const SEARCH_VPN_SERVER = gql`
  query SearchVPNServerCustomPort($input: SearchInput!) {
    searchVPNServer(input: $input) {
      data {
        id
      }
      page
      pageSize
      total
    }
  }
`;

const VPN_CONFIG = gql`
  query VPNConfigCustomPort($port: String!, $server: String!) {
    vpnConfig(port: $port, server: $server)
  }
`;

const CREATE_VPN_PORT = gql`
  mutation CreateVPNPort {
    createVPNPort {
      id
      credentials {
        kind
        ipFilter
        login
      }
      target
      dnsHijack
      dnsServer
      createdAt
      updatedAt
    }
  }
`;

const subPort = 15000;
const minSubnet = 3232238080; // 192.168.10.0
const maxSubnet = 3232301052; // 192.168.255.252

const renderSubnet = (id: string) => {
  const parsed = parseInt(id, 10);
  if (isNaN(parsed)) {
    return "192.168.10.0/30";
  }

  const subnet = minSubnet + (parsed - subPort) * 4 % (maxSubnet - minSubnet);
  return ((subnet >>> 24) + "." + ((subnet >> 16) & 255) + "." + ((subnet >> 8) & 255) + "." + (subnet & 255)) + "/30";
};

export const PortVPNList = () => {
  const history = useHistory();
  const { result, search, updateFilter, updateTable } = useSearch<g.SearchVPNPort>(SEARCH_VPN_PORT);

  const { "data": serverData } = useQuery<gs.SearchVPNServerCustomPort, gs.SearchVPNServerCustomPortVariables>(SEARCH_VPN_SERVER, {
    fetchPolicy: "no-cache",
    variables: {
      input: {
        filter: "",
        page: 1,
        pageSize: 0,
      }
    }
  });

  const { "refetch": fetchConfig } = useQuery<gf.VPNConfigCustomPort, gf.VPNConfigCustomPortVariables>(VPN_CONFIG, {
    skip: true,
    fetchPolicy: "no-cache",
  });


  const [createVPNPort] = useMutation<gp.CreateVPNPort, {}>(CREATE_VPN_PORT, {
    okText: i18n.t("port:Port created"),
    onCompleted: (data) => {
      history.push(`/vpn/${data.createVPNPort.id}`);
    }
  });

  const columns: ColumnsType<g.SearchVPNPort_searchVPNPort_data> = [
    {
      dataIndex: "id",
      title: i18n.t("port:ID"),
      sorter: true,
      render: (text, record) => <Link to={`/vpn/${record.id}`}>{text}</Link>,
    },
    {
      key: "credentials",
      title: i18n.t("port:Credentials"),
      render: (_, record) => record.credentials.length
    },
    {
      key: "subnet",
      title: i18n.t("port:Subnet"),
      render: (_, record) => renderSubnet(record.id),
    },
    {
      dataIndex: "target",
      title: i18n.t("port:Target"),
      render: text => text === "" ? "empty" : text
    },
    {
      key: "dnsHijack",
      title: i18n.t("port:DNS Hijack"),
      render: (_, record) => <Checkbox checked={record.dnsHijack} disabled={true} />
    },
    {
      dataIndex: "dnsServer",
      title: i18n.t("port:DNS Server"),
      render: text => text === "" ? "empty" : text
    },
    {
      dataIndex: "createdAt",
      title: i18n.t("port:Created At"),
      sorter: true,
      render: text => <Date date={text} />,
    },
    {
      dataIndex: "updatedAt",
      title: i18n.t("port:Updated At"),
      sorter: true,
      render: text => <Date date={text} />,
      defaultSortOrder: "descend" as SortOrder,
    },
    {
      key: "config",
      title: i18n.t("vpn:Config"),
      render: (_, record) => {
        const content = (
          <Space direction="vertical">
            {serverData?.searchVPNServer.data.map(x => {
              return (
                <Button key={`p${record.id}s${x.id}`} onClick={() => fetchConfig({ port: record.id, server: x.id }).then(z => {
                  const hexStr = z.data.vpnConfig;
                  const buf = new ArrayBuffer(hexStr.length / 2);
                  const byteBuf = new Uint8Array(buf);
                  for (let i = 0; i < hexStr.length; i += 2) {
                    byteBuf[i / 2] = parseInt(hexStr.slice(i, i + 2), 16);
                  }

                  const blob = new Blob([byteBuf], { type: "application/zip" });
                  const url = window.URL.createObjectURL(blob);
                  const tempLink = document.createElement("a");
                  tempLink.href = url;
                  tempLink.setAttribute("download", `${x.id}-${record.id}.zip`);
                  tempLink.click();
                })}>{x.id}</Button>
              );
            })}
          </Space>
        );

        return (
          <Popover content={content} title="Select server">
            <Button icon={<DownloadOutlined />}>{i18n.t("vpn:Download")}</Button>
          </Popover>
        );
      },
    },
  ];

  return (
    <ListContainer
      create={<Button onClick={() => createVPNPort()}>Add port</Button>}
      search={search}
      columns={columns}
      data={result.data?.searchVPNPort.data}
      total={result.data?.searchVPNPort.total}
      loading={result.loading}
      onSearch={updateFilter}
      onTableChange={updateTable}
    />
  );
};
