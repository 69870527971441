import { Button, Card, Radio, Select, Space, Spin } from "antd";
import gql from "graphql-tag";
import React, { useReducer } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "../../../hooks";
import * as gg from "../../../__generated__/globalTypes";
import * as g from "./__generated__/PortTarget";
import * as gt from "./__generated__/SearchPortTarget";
import * as gu from "./__generated__/UpdatePortTarget";

const GET_PORT_TARGET = gql`
  query PortTarget($id: ID!) {
    port(id: $id) {
      id
      target {
        kind
        pool
        poolName
        modem
      }
    }
  }
`;

const SEARCH_PORT_TARGET = gql`
  query SearchPortTarget($kind: PortTargetKind!, $filter: String) {
    searchPortTarget(kind: $kind, filter: $filter) {
      kind
      pool
      poolName
      modem
    }
  }
`;

const UPDATE_PORT_TARGET = gql`
  mutation UpdatePortTarget($id: ID!, $input: PortTargetInput!) {
    updatePortTarget(id: $id, input: $input) {
      id
      target {
        kind
        pool
        poolName
        modem
      }
    }
  }
`;

interface IPortTargetState {
  kind: string,
  name: string,
  filter: string,
}

const initialState: IPortTargetState = {
  kind: gg.PortTargetKind.modem,
  name: gg.PortTargetKind.empty,
  filter: "",
};

const targetName = (target: g.PortTarget_port_target) => {
  switch (target.kind) {
    case gg.PortTargetKind.modem:
      return target.modem!;
    case gg.PortTargetKind.pool:
      return target.poolName!;
    default:
      return gg.PortTargetKind.empty;
  }
};

const portTargetReducer = (
  state: IPortTargetState,
  action: { type: string, data: any }
) => {
  switch (action.type) {
    case "INIT":
      return {
        ...state,
        kind: action.data.target.kind,
        name: targetName(action.data.target),
      };
    case "SEARCH":
      return { ...state, filter: action.data };
    case "SELECT":
      return { ...state, name: action.data };
    case "CHANGE_KIND":
      return { ...state, kind: action.data };
    default:
      throw new Error(`undefined action ${action.type}`);
  }
};

export const PortTarget = () => {
  const { id } = useParams<{ id: string }>();
  const [state, dispatch] = useReducer(portTargetReducer, initialState);

  const { loading } = useQuery<g.PortTarget, g.PortTargetVariables>(GET_PORT_TARGET, {
    variables: { id },
    onCompleted: (data) => {
      dispatch({ type: "INIT", data: data.port });
    }
  });

  const { "data": targetData } = useQuery<gt.SearchPortTarget, gt.SearchPortTargetVariables>(SEARCH_PORT_TARGET, {
    fetchPolicy: "no-cache",
    variables: {
      kind: state.kind as gg.PortTargetKind,
      filter: state.filter,
    }
  });

  const [updateTarget] = useMutation<gu.UpdatePortTarget, gu.UpdatePortTargetVariables>(UPDATE_PORT_TARGET, {
    okText: "Port updated",
  });

  if (loading) {
    return <Spin />;
  }

  return (
    <Card title="Target">
      <Space direction="vertical" style={{ width: "100%" }}>
        <Radio.Group
          value={state.kind}
          buttonStyle="solid"
          onChange={(e) => dispatch({ type: "CHANGE_KIND", data: e.target.value })}
        >
          <Radio.Button value="modem">Modem</Radio.Button>
          <Radio.Button value="pool">Pool</Radio.Button>
        </Radio.Group>

        <Select
          value={state.name}
          placeholder="Select target"
          filterOption={false}
          showSearch={true}
          onSearch={(val) => dispatch({ type: "SEARCH", data: val })}
          onChange={(val) => dispatch({ type: "SELECT", data: val })}
          style={{ width: "100%" }}
        >
          {state.kind === "modem" && targetData?.searchPortTarget.map(d =>
            <Select.Option key={d.modem || "empty"} value={d.modem || "empty"}>{d.modem}</Select.Option>
          )}

          {state.kind === "pool" && targetData?.searchPortTarget.map(d =>
            <Select.Option key={d.pool || "empty"} value={d.poolName || "empty"}>{d.poolName}</Select.Option>
          )}
        </Select>

        <Button
          type="primary"
          style={{ margin: "8px 0" }}
          onClick={() => updateTarget({ variables: { id, input: { kind: state.kind, modemId: state.name, poolName: state.name } } })}
        >
          Update
        </Button>
      </Space>
    </Card>
  );
};
