import { ApolloProvider } from "@apollo/client";
import { ConfigProvider } from "antd";
import enUS from "antd/es/locale/en_US";
import React from "react";
import ReactDOM from "react-dom";
import { client } from "./common/client";
import { App } from "./containers/App";

ReactDOM.render(
  <ApolloProvider client={client}>
    <ConfigProvider locale={enUS}>
      <App />
    </ConfigProvider>
  </ApolloProvider>,
  document.getElementById("app")
);
