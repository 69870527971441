import { EyeOutlined } from "@ant-design/icons";
import { Card, Popover, Space, Spin } from "antd";
import React from "react";
import * as g from "./__generated__/SearchBanner";

export const BannerPreview = (props: { content?: g.SearchBanner_searchBanner_data_content[] }) => {
  if (!props.content) {
    return <Spin />;
  }

  return (
    <Popover
      title="Preview"
      content={
        <Space direction="vertical">
          {props.content.map(c => {
            return (
              <Card key={c.language} size="small" bordered={false} title={c.language}>
                <div dangerouslySetInnerHTML={{ __html: c.html }} />
              </Card>
            );
          })}
        </Space>
      }
    >
      <EyeOutlined />
    </Popover>
  );
};
