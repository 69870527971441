import { Button, Card, Form, Input, Popconfirm } from "antd";
import { ColumnsType } from "antd/lib/table";
import { SortOrder } from "antd/lib/table/interface";
import gql from "graphql-tag";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Date } from "../../components/Date";
import { CreateForm } from "../../containers/CreateForm";
import { ListContainer } from "../../containers/ListContainer";
import { useMutation, useSearch } from "../../hooks";
import * as gc from "./__generated__/CreateMikrotikCommand";
import * as gd from "./__generated__/DeleteMikrotikCommand";
import * as g from "./__generated__/SearchMikrotikCommand";

const SEARCH_COMMAND = gql`
  query SearchMikrotikCommand($input: SearchInput!) {
    searchMikrotikCommand(input: $input) {
      data {
        id
        name
        command
        updatedAt
      }
      page
      pageSize
      total
    }
  }
`;

const CREATE_COMMAND = gql`
  mutation CreateMikrotikCommand($name: String!, $command: String!) {
    createMikrotikCommand(name: $name, command: $command) {
      id
      name
      command
      updatedAt
    }
  }
`;

const DELETE_COMMAND = gql`
  mutation DeleteMikrotikCommand($id: ID!) {
    deleteMikrotikCommand(id: $id)
  }
`;

const CommandCreate = () => {
  const { t } = useTranslation("node");

  const [form] = Form.useForm<gc.CreateMikrotikCommandVariables>();
  const [isOpen, toggle] = useState(false);

  const [createMikrotikCommand] = useMutation<{
    createCommand: gc.CreateMikrotikCommand,
    command: gc.CreateMikrotikCommand_createMikrotikCommand,
  }>(CREATE_COMMAND, {
    okText: t("Command created"),
    refetchQueries: ["SearchMikrotikCommand"],
    onCompleted: (_) => {
      form.resetFields();
      toggle(false);
    },
  });

  return (
    <CreateForm
      name="create-command"
      title={t("router:Create MikroTik command")}
      form={form}
      isOpen={isOpen}
      toggleOpen={() => toggle(!isOpen)}
      onCreate={(values) => createMikrotikCommand({ variables: values })}
    >
      <Form.Item
        name="name"
        label={t("Name")}
        rules={[{ required: true }]}
      >
        <Input placeholder="Name" />
      </Form.Item>

      <Form.Item
        name="command"
        label={t("Command")}
        rules={[{ required: true }]}
      >
        <Input placeholder="/system resource print" />
      </Form.Item>
    </CreateForm>
  );
};

const CommandList = () => {
  const { t } = useTranslation("node");

  const { result, search, updateFilter, updateTable } = useSearch<g.SearchMikrotikCommand>(SEARCH_COMMAND);

  const [deleteMikrotikCommand] = useMutation<gd.DeleteMikrotikCommand, gd.DeleteMikrotikCommandVariables>(DELETE_COMMAND, {
    okText: t("Command deleted"),
    refetchQueries: ["SearchMikrotikCommand"],
  });

  const columns: ColumnsType<g.SearchMikrotikCommand_searchMikrotikCommand_data> = [
    {
      dataIndex: "name",
      title: t("Name"),
      sorter: true,
    },
    {
      dataIndex: "command",
      title: t("Command"),
    },
    {
      dataIndex: "updatedAt",
      title: t("Updated At"),
      sorter: true,
      render: text => <Date date={text} />,
      defaultSortOrder: "descend" as SortOrder,
    },
    {
      key: "delete",
      title: t("Delete"),
      render: (_, record) => {
        return (
          <Popconfirm
            title={t("Delete command?")}
            okText={t("Yes")}
            cancelText={t("No")}
            onConfirm={() => deleteMikrotikCommand({ variables: { id: record.id } })}
          >
            <Button htmlType="button">
              {t("Delete")}
            </Button>
          </Popconfirm>
        );
      },
    }
  ];

  return (
    <ListContainer
      create={<CommandCreate />}
      search={search}
      columns={columns}
      data={result.data?.searchMikrotikCommand.data}
      total={result.data?.searchMikrotikCommand.total}
      loading={result.loading}
      onSearch={updateFilter}
      onTableChange={updateTable}
    />
  );
};

export const NodeMikrotik = () => {
  const { t } = useTranslation("node");

  return (
    <Card title={t("MikroTik commands")} bordered={false} headStyle={{ padding: "0" }} bodyStyle={{ padding: "24px 0" }}>
      <CommandList />
    </Card>
  );
};
