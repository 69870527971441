import { Tooltip } from "antd";
import moment from "moment";
import React from "react";

export const Date = ({ date }: { date: string }) => {
  const ts = moment(date);

  return (
    <Tooltip title={ts.fromNow()}>
      <span>{ts.format("lll")}</span>
    </Tooltip>
  );
};
