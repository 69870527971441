import { Card, Spin } from "antd";
import gql from "graphql-tag";
import React, { Fragment, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "../../hooks";
import { PoolAssignments, PoolLog, PoolSettings, PoolSummary } from "./";
import * as g from "./__generated__/PoolName";

const GET_POOL_NAME = gql`
  query PoolName($id: ID!) {
    pool(id: $id) {
      id
      name
    }
  }
`;

const tabList = [
  {
    key: "summary",
    tab: "Summary",
  },
  {
    key: "assignments",
    tab: "Assignments",
  },
  {
    key: "settings",
    tab: "Settings",
  },
  {
    key: "log",
    tab: "Log"
  },
];


export const PoolEdit = () => {
  const { id } = useParams<{ id: string }>();

  const [selectedTab, selectTab] = useState("summary");

  const { loading, data } = useQuery<g.PoolName, g.PoolNameVariables>(GET_POOL_NAME, {
    variables: { id }
  });

  if (loading) {
    return <Spin />;
  }

  return (
    <Fragment>
      <Card
        title={data?.pool.name}
        bordered={false}
        headStyle={{ padding: "0" }}
        bodyStyle={{ padding: "24px 0" }}
        tabList={tabList}
        activeTabKey={selectedTab}
        onTabChange={(tabKey) => selectTab(tabKey)}
      >
        {selectedTab === "summary" && <PoolSummary />}
        {selectedTab === "assignments" && <PoolAssignments />}
        {selectedTab === "settings" && <PoolSettings />}
        {selectedTab === "log" && <PoolLog />}
      </Card>
    </Fragment>
  );
};
