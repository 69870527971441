import { CopyOutlined } from "@ant-design/icons";
import { message, Space, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import { SortOrder } from "antd/lib/table/interface";
import gql from "graphql-tag";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
import i18n from "../../common/i18n";
import { Date } from "../../components/Date";
import { ListContainer } from "../../containers/ListContainer";
import { useSearch } from "../../hooks";
import * as g from "./__generated__/SearchPort";

const SEARCH_PORT = gql`
  query SearchPort($input: SearchInput!) {
    searchPort(input: $input) {
      data {
        id
        user {
          id
          login
          group
        }
        resetToken
        resetAt
        signature {
          id
          raw
        }
        credentials {
          kind
          ipFilter
          login
        }
        target {
          kind
          pool
          poolName
          modem
        }
        currentModem
        createdAt
        updatedAt
      }
      page
      pageSize
      total
    }
  }
`;

const renderTarget = (_text: string, record: any) => {
  switch (record.target.kind) {
    case "modem":
      return <Link to={`/modems/${record.target.modem}`}><Tag color="blue">{record.target.modem}</Tag></Link>;
    case "pool":
      var modem = <Tag>{i18n.t("port:Empty")}</Tag>;
      if (record.currentModem !== "empty") {
        modem = <Link to={`/modems/${record.currentModem}`}><Tag color="blue">{record.currentModem}</Tag></Link>;
      }

      return (
        <Space>
          <Link to={`/pools/${record.target.pool}`}><Tag color="purple">{record.target.poolName}</Tag></Link>
          {modem}
        </Space>
      );
    default:
      return <Tag>{i18n.t("port:Empty")}</Tag>;
  }
};

const columns: ColumnsType<g.SearchPort_searchPort_data> = [
  {
    dataIndex: "id",
    title: i18n.t("port:ID"),
    sorter: true,
    render: (text, record) => <Link to={`/ports/${record.id}`}>{text}</Link>,
  },
  {
    dataIndex: ["user", "login"],
    title: i18n.t("port:User"),
    render: (text, record) => <Link to={`/users/${record.user.id}`}>{text}</Link>
  },
  {
    key: "credentials",
    title: i18n.t("port:Credentials"),
    render: (_, record) => record.credentials.length
  },
  {
    dataIndex: ["signature", "id"],
    title: i18n.t("port:Signature"),
  },
  {
    key: "target",
    title: i18n.t("port:Target"),
    dataIndex: "",
    render: renderTarget,
  },
  {
    dataIndex: "resetToken",
    title: i18n.t("port:Reset URL"),
    render: text => {
      return (
        <CopyToClipboard
          text={`https://api.socks.expert/v2/port/reset/${text}`}
          onCopy={() => message.info(i18n.t("port:Link copied"))}
        >
          <CopyOutlined />
        </CopyToClipboard>
      );
    }
  },
  {
    dataIndex: "createdAt",
    title: i18n.t("port:Created At"),
    sorter: true,
    render: text => <Date date={text} />,
  },
  {
    dataIndex: "updatedAt",
    title: i18n.t("port:Updated At"),
    sorter: true,
    render: text => <Date date={text} />,
    defaultSortOrder: "descend" as SortOrder,
  },
];

export const PortList = () => {
  const { result, search, updateFilter, updateTable } = useSearch<g.SearchPort>(SEARCH_PORT);

  return (
    <ListContainer
      create={<span></span>}
      search={search}
      columns={columns}
      data={result.data?.searchPort.data}
      total={result.data?.searchPort.total}
      loading={result.loading}
      onSearch={updateFilter}
      onTableChange={updateTable}
    />
  );
};
