import { Button, Space } from "antd";
import gql from "graphql-tag";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "../../hooks";
import * as gg from "../../__generated__/globalTypes";
import * as g from "./__generated__/ResetModem";

const RESET_MODEM = gql`
  mutation ResetModem($id: ID!, $resetType: ModemResetType!) {
    resetModem(id: $id, resetType: $resetType)
  }
`;

export const ResetActions = (props: { id: string, refetch: string }) => {
  const { t } = useTranslation("modem");

  const [resetModemWeb] = useMutation<g.ResetModem, g.ResetModemVariables>(RESET_MODEM, {
    okText: t("Web reset scheduled"),
    refetchQueries: [props.refetch],
    variables: {
      id: props.id,
      resetType: gg.ModemResetType.web,
    }
  });

  const [resetModemNetwork] = useMutation<g.ResetModem, g.ResetModemVariables>(RESET_MODEM, {
    okText: t("Network reset scheduled"),
    refetchQueries: [props.refetch],
    variables: {
      id: props.id,
      resetType: gg.ModemResetType.net,
    }
  });

  const [resetModemPower] = useMutation<g.ResetModem, g.ResetModemVariables>(RESET_MODEM, {
    okText: t("Power reset scheduled"),
    refetchQueries: [props.refetch],
    variables: {
      id: props.id,
      resetType: gg.ModemResetType.power,
    }
  });

  return (
    <Space>
      <Button shape="circle" onClick={() => resetModemWeb()}>W</Button>
      <Button shape="circle" onClick={() => resetModemNetwork()}>N</Button>
      <Button shape="circle" onClick={() => resetModemPower()}>P</Button>
    </Space>
  );
};
