import { SearchOutlined } from "@ant-design/icons";
import { Button, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import { SortOrder } from "antd/lib/table/interface";
import gql from "graphql-tag";
import React from "react";
import { Link } from "react-router-dom";
import { formatCurrency } from "../../common/formatter";
import i18n from "../../common/i18n";
import { Date } from "../../components/Date";
import { ListContainer } from "../../containers/ListContainer";
import { useSearch } from "../../hooks";
import { UserCreate } from "./Create";
import * as g from "./__generated__/SearchUser";

const SEARCH_USER = gql`
  query SearchUser($input: SearchInput!) {
    searchUser(input: $input) {
      data {
        id
        login
        group
        status
        apiToken
        balance
        balanceLimit
        discountPercent
        portsCount
        createdAt
        updatedAt
      }
      page
      pageSize
      total
    }
  }
`;

const renderStatus = (text: string) => {
  switch (text) {
    case "active":
      return <Tag color="green">{text}</Tag>;
    case "disconnected":
      return <Tag color="orange">{text}</Tag>;
    case "blocked":
      return <Tag color="red">{text}</Tag>;
    default:
      return <Tag color="lime">unknown</Tag>;
  }
};

const columns: ColumnsType<g.SearchUser_searchUser_data> = [
  {
    dataIndex: "login",
    title: i18n.t("user:Login"),
    sorter: true,
    render: (text, record) => <Link to={`/users/${record.id}`}>{text}</Link>,
  },
  {
    key: "ports",
    title: i18n.t("user:Ports"),
    render: (_, record) => {
      return (
        <Link to={`/ports/list?filter=${record.login}`}>
          <Button icon={<SearchOutlined />} size="small">
            {` ${record.portsCount}`}
          </Button>
        </Link>
      );
    }
  },
  {
    dataIndex: "status",
    title: i18n.t("user:Status"),
    sorter: true,
    render: renderStatus,
  },
  {
    dataIndex: "balance",
    title: i18n.t("user:Balance"),
    sorter: true,
    render: (text) => formatCurrency(text / 100)
  },
  {
    dataIndex: "balanceLimit",
    title: i18n.t("user:Overdraft"),
    sorter: true,
    render: (text) => formatCurrency(text / 100)
  },
  {
    dataIndex: "discountPercent",
    title: i18n.t("user:Discount"),
    sorter: true,
    render: (text) => `${text}%`
  },
  {
    dataIndex: "createdAt",
    title: i18n.t("user:Created At"),
    sorter: true,
    render: text => <Date date={text} />,
  },
  {
    dataIndex: "updatedAt",
    title: i18n.t("user:Updated At"),
    sorter: true,
    render: text => <Date date={text} />,
    defaultSortOrder: "descend" as SortOrder,
  },
];

export const UserList = () => {
  const { result, search, updateFilter, updateTable } = useSearch<g.SearchUser>(SEARCH_USER);

  return (
    <ListContainer
      create={<UserCreate />}
      search={search}
      columns={columns}
      data={result.data?.searchUser.data}
      total={result.data?.searchUser.total}
      loading={result.loading}
      onSearch={updateFilter}
      onTableChange={updateTable}
    />
  );
};
