import { Button, Modal, Popconfirm, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { SortOrder } from "antd/lib/table/interface";
import gql from "graphql-tag";
import moment from "moment";
import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { formatCurrency } from "../../common/formatter";
import i18n from "../../common/i18n";
import { Date } from "../../components/Date";
import { useMutation, useQuery } from "../../hooks/apollo";
import * as gd from "./__generated__/DisablePortAutoRenew";
import * as g from "./__generated__/SearchPortAutoRenew";


const SEARCH_PORT_PLAN = gql`
  query SearchPortAutoRenew($name: String!, $input: SearchInput!) {
    searchPortAutoRenew(name: $name, input: $input) {
      data {
        id
        user {
          id
          login
        }
        plan {
          id
          tarification {
            time
            traffic
            price
          }
          expiresAt
        }
        createdAt
        updatedAt
      }
      page
      pageSize
      total
    }
  }
`;

const DISABLE_PORT_AUTO_RENEW = gql`
  mutation DisablePortAutoRenew($name: String!) {
    disablePortAutoRenew(name: $name)
  }
`;

const formatOptTitle = (opt: any) => {
  if (opt.time === undefined) {
    return undefined;
  }

  return `${opt.time / 86400} Days / ${opt.traffic <= 0 ? "∞" : opt.traffic / 1024} GB / ${formatCurrency(opt.price / 100)}`;
};

const columns: ColumnsType<g.SearchPortAutoRenew_searchPortAutoRenew_data> = [
  {
    dataIndex: "id",
    title: i18n.t("port:ID"),
    sorter: true,
    render: (text, record) => <Link to={`/ports/${record.id}`}>{text}</Link>,
  },
  {
    dataIndex: ["user", "login"],
    title: i18n.t("port:User"),
    render: (text, record) => <Link to={`/users/${record.user.id}`}>{text}</Link>
  },
  {
    dataIndex: ["plan", "tarification"],
    title: i18n.t("port:Option"),
    render: (text) => formatOptTitle(text),
  },
  {
    key: "left",
    title: i18n.t("port:Days left"),
    render: (_, record) => moment(record.plan.expiresAt).diff(moment(), "days"),
  },
  {
    dataIndex: ["plan", "expiresAt"],
    title: i18n.t("port:Expires At"),
    sorter: true,
    render: text => <Date date={text} />,
  },
  {
    dataIndex: "updatedAt",
    title: i18n.t("port:Updated At"),
    sorter: true,
    render: text => <Date date={text} />,
    defaultSortOrder: "descend" as SortOrder,
  },
];

interface DisableAutoRenewProps {
  name?: string;
}

interface DisableAutoRenewState {
  sorter: string;
  page: number;
  pageSize: number;
}

export const DisableAutoRenew = (props: DisableAutoRenewProps) => {
  const [isOpen, toggle] = useState(false);
  const [state, setState] = useState<DisableAutoRenewState>({
    sorter: "updatedAt_descend",
    page: 1,
    pageSize: 10,
  });

  const result = useQuery<g.SearchPortAutoRenew, g.SearchPortAutoRenewVariables>(SEARCH_PORT_PLAN, {
    skip: !isOpen || !props.name,
    fetchPolicy: "no-cache",
    variables: {
      name: props.name || "",
      input: {
        filter: "",
        sorter: state.sorter,
        page: state.page,
        pageSize: state.pageSize,
      }
    },
  });

  const [disableAutoRenew] = useMutation<gd.DisablePortAutoRenew, gd.DisablePortAutoRenewVariables>(DISABLE_PORT_AUTO_RENEW, {
    okText: "Auto Renew disabled",
    onCompleted: () => toggle(!isOpen),
  });

  const footer = (
    <Fragment>
      <Button onClick={() => toggle(!isOpen)}>
        {i18n.t("plan:Cancel")}
      </Button>
      <Popconfirm
        title="Disable auto renew for all ports?"
        onConfirm={() => props.name && disableAutoRenew({ variables: { name: props.name } })}
      >
        <Button type="primary" danger={true} >
          {i18n.t("plan:Disable For All")}
        </Button>
      </Popconfirm>

    </Fragment>
  );

  return (
    <Fragment>
      <Button onClick={() => toggle(!isOpen)}>
        {i18n.t("plan:Disable auto renew for active ports")}
      </Button>
      <Modal
        title={`${i18n.t("plan:Disable Auto Renew")} (${result.data?.searchPortAutoRenew.total})`}
        visible={isOpen}
        centered={true}
        footer={footer}
        width="80%"
        onCancel={() => toggle(!isOpen)}
      >
        <Table
          size="middle"
          rowKey={record => record.id}
          loading={result.loading}
          columns={columns}
          dataSource={result.data?.searchPortAutoRenew.data}
          pagination={{
            showSizeChanger: true,
            current: result.data?.searchPortAutoRenew.page,
            pageSize: result.data?.searchPortAutoRenew.pageSize,
            total: result.data?.searchPortAutoRenew.total,
          }}
          onChange={(pagination, _filters, sorter) => {
            const newState = {
              sorter: "updatedAt_descend",
              page: 1,
              pageSize: 10,
            };

            const fs = Array.isArray(sorter) ? sorter[0] : sorter;
            if (fs.order) {
              newState.sorter = `${fs.field}_${fs.order}`;
            }
            newState.page = pagination.current || 1;
            newState.pageSize = pagination.pageSize || 10;

            setState(newState);
          }}
        />
      </Modal>
    </Fragment >
  );
};
