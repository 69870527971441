import { CopyOutlined, RetweetOutlined } from "@ant-design/icons";
import { Card, Checkbox, Form, Input, message, Popconfirm, Spin } from "antd";
import gql from "graphql-tag";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useHistory, useParams } from "react-router-dom";
import { EditForm } from "../../../containers/EditForm";
import { useMutation, useQuery } from "../../../hooks";
import { InputAutoResetInterval, InputBandwidth } from "../../Inputs";
import * as gd from "./__generated__/DeletePort";
import * as g from "./__generated__/PortCommon";
import * as gu from "./__generated__/UpdatePortC";

const GET_PORT_COMMON = gql`
  query PortCommon($id: ID!) {
    port(id: $id) {
      id
      resetToken
      vpnAccess
      speedLimit
      skipTimeoutRelease
      autoResetOffline
      autoResetInterval
    }
  }
`;

const UPDATE_PORT_COMMON = gql`
  mutation UpdatePortC($id: ID!, $input: PortUpdateInput!) {
    updatePortC(id: $id, input: $input) {
      id
      resetToken
      vpnAccess
      speedLimit
      skipTimeoutRelease
      autoResetOffline
      autoResetInterval
    }
  }
`;

const DELETE_PORT = gql`
  mutation DeletePort($id: ID!) {
    deletePort(id: $id)
  }
`;

export const PortCommon = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();

  const { loading, data } = useQuery<g.PortCommon, g.PortCommonVariables>(GET_PORT_COMMON, {
    variables: { id },
  });

  const [updateCommon] = useMutation<gu.UpdatePortC, gu.UpdatePortCVariables>(UPDATE_PORT_COMMON, {
    okText: "Port updated",
    refetchQueries: ["PortCommon"],
  });

  const [deletePort] = useMutation<gd.DeletePort, gd.DeletePortVariables>(DELETE_PORT, {
    okText: "Port deleted",
    onCompleted: (_) => {
      history.push("/ports");
    }
  });

  if (loading) {
    return <Spin />;
  }

  return (
    <Card title="Common">
      <EditForm
        name="edit-port"
        deleteTitle="Delete port?"
        loading={loading}
        form={form}
        initialValues={data?.port}
        onSave={(values) => updateCommon({ variables: { id, input: values } })}
        onDelete={() => deletePort({ variables: { id } })}
      >
        <Form.Item
          name="vpnAccess"
          label="VPN Access"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name="skipTimeoutRelease"
          label="Disable release by inactivity"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name="autoResetOffline"
          label="Auto reset in case of offline"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name="autoResetInterval"
          label="Auto Reset Interval"
        >
          <InputAutoResetInterval />
        </Form.Item>
        <Form.Item
          name="speedLimit"
          label="Speed Limit"
        >
          <InputBandwidth />
        </Form.Item>
        <Form.Item
          name="resetToken"
          label="Reset Token"
        >
          <Input
            disabled={true}
            addonAfter={
              <Popconfirm
                title="Refresh reset token?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => updateCommon({ variables: { id, input: { refreshApiToken: true } } })}
              >
                <RetweetOutlined />
              </Popconfirm>
            }
            addonBefore={
              <CopyToClipboard
                text={data?.port.resetToken || ""}
                onCopy={() => message.info("Token copied")}
              >
                <CopyOutlined />
              </CopyToClipboard>
            }
          />
        </Form.Item>
      </EditForm>
    </Card>
  );
};
