import { Button, Card, Checkbox, Spin, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import gql from "graphql-tag";
import React, { useReducer } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "../../hooks";
import * as g from "./__generated__/Role";
import * as gu from "./__generated__/UpdateRole";

const GET_ROLE = gql`
  query Role($id: ID!) {
    role(id: $id) {
      id
      description
      permissions {
        id
        description
        read
        write
      }
    }
  }
`;

const UPDATE_ROLE = gql`
  mutation UpdateRole($id: ID!, $permissions: [RolePermissionInput!]!) {
    updateRole(id: $id, permissions: $permissions) {
      id
      description
      permissions {
        id
        description
        read
        write
      }
    }
  }
`;

interface RoleState {
  permissions: g.Role_role_permissions[]
}

const roleReducer = (
  state: RoleState,
  action: { type: string, data: any }
) => {
  switch (action.type) {
    case "INIT":
      return { ...state, permissions: action.data };
    case "TOGGLE_READ":
      return {
        ...state,
        permissions: state.permissions.map(x => x.id === action.data ? { ...x, read: !x.read } : x)
      };
    case "TOGGLE_WRITE":
      return {
        ...state,
        permissions: state.permissions.map(x => x.id === action.data ? { ...x, write: !x.write } : x)
      };
    default:
      throw new Error(`undefined action ${action.type}`);
  }
};

export const RoleEdit = () => {
  const { id } = useParams<{ id: string }>();
  const [state, dispatch] = useReducer(roleReducer, { permissions: [] });

  const { loading, data } = useQuery<g.Role, g.RoleVariables>(GET_ROLE, {
    variables: { id },
    onCompleted: (data) => {
      dispatch({ type: "INIT", data: data.role.permissions });
    }
  });

  const [updateRole] = useMutation<gu.UpdateRole, gu.UpdateRoleVariables>(UPDATE_ROLE, {
    okText: "Role updated",
    refetchQueries: ["Role"]
  });

  if (loading) {
    return <Spin />;
  }

  const resourceColumns: ColumnsType<g.Role_role_permissions> = [
    {
      title: "Resource",
      dataIndex: "description",
    },
    {
      title: "Read",
      key: "read",
      render: (record) => <Checkbox checked={record.read} onClick={(_) => dispatch({ type: "TOGGLE_READ", data: record.id })} />,
    },
    {
      title: "Write",
      key: "write",
      render: (record) => <Checkbox checked={record.write} onClick={(_) => dispatch({ type: "TOGGLE_WRITE", data: record.id })} />,
    },
  ];

  return (
    <Card
      title={data?.role.id}
      bordered={false}
      headStyle={{ padding: "0" }}
      bodyStyle={{ padding: "24px 0" }}
    >
      <Table
        dataSource={state.permissions}
        rowKey="id"
        columns={resourceColumns}
        pagination={false}
        size="small"
      />
      <br />
      <Button type="primary" onClick={() => { console.log(state.permissions); updateRole({ variables: { id, permissions: state.permissions } }); }}>Save</Button>
    </Card >
  );
};
