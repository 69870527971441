import gql from "graphql-tag";
import * as g from "./__generated__/HasAccess";
import { useQuery } from "./apollo";

const HAS_ACCESS = gql`
  query HasAccess($resources: [String!]!) {
    hasAccess(resources: $resources)
  }
`;

export const useAccess = (...resources: string[]) => {
  const { data, loading } = useQuery<g.HasAccess, g.HasAccessVariables>(HAS_ACCESS, {
    fetchPolicy: "no-cache",
    variables: { resources: resources }
  });

  const processFn = (data?: g.HasAccess) => {
    return (data?.hasAccess || []).reduce((acc, x, i) => {
      acc[resources[i]] = x;
      return acc;
    }, {} as any);
  };

  const access = processFn(data);

  return { loading, access };
};
