import { QueryHookOptions } from "@apollo/client";
import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { FilterValue, SorterResult, TablePaginationConfig } from "antd/lib/table/interface";
import { DocumentNode } from "graphql";
import qs from "query-string";
import { useHistory } from "react-router-dom";
import { useQuery } from "./apollo";

export interface SearchInput {
  filter: string;
  sorter: string;
  page: number;
  pageSize: number;
}

interface SearchVariables {
  input: SearchInput;
}

export const useSearch = <TData = any>(
  query: DocumentNode | TypedDocumentNode<TData, SearchVariables>,
  options?: QueryHookOptions<TData, SearchVariables>,
) => {
  const history = useHistory();
  const search = inputFromQs(history.location.search);
  const result = useQuery<TData, SearchVariables>(query, {
    ...options,
    fetchPolicy: "no-cache",
    variables: { input: search },
  });

  const updateFilter = (filter: string) => {
    const input = inputFilter(search, filter);
    history.replace({ search: qs.stringify(input, qsStringifyOptions) });
  };

  const updateTable = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[],
  ) => {
    const input = inputTable(search, pagination, filters, sorter);
    history.replace({ search: qs.stringify(input, qsStringifyOptions) });
  };

  return { result, search, updateFilter, updateTable };
};

const inputFromQs = (queryString: string): SearchInput => {
  const input = qs.parse(queryString);

  let page = defaultSearhInput.page;
  if (!Number.isNaN(Number(input.page))) {
    page = Number(input.page);
  }

  let pageSize = defaultSearhInput.pageSize;
  if (!Number.isNaN(Number(input.pageSize))) {
    pageSize = Number(input.pageSize);
  }

  return {
    ...defaultSearhInput,
    ...input,
    page,
    pageSize,
  };
};

const inputFilter = (prev: SearchInput, filter: string) => {
  return { ...prev, filter, page: 1 };
};

const inputTable = (
  prev: SearchInput,
  pagination: TablePaginationConfig,
  filters: Record<string, FilterValue | null>,
  sorter: SorterResult<any> | SorterResult<any>[],
) => {
  const input = { ...prev };

  const fs = Array.isArray(sorter) ? sorter[0] : sorter;
  if (fs.order) {
    input.sorter = `${fs.field}_${fs.order}`;
  }

  input.page = pagination.current || prev.page;
  input.pageSize = pagination.pageSize || prev.pageSize;

  if (prev.sorter !== input.sorter) {
    input.page = 1;
  }

  return input;
};

const defaultSearhInput = {
  filter: "",
  sorter: "updatedAt_descend",
  page: 1,
  pageSize: 20,
};

const qsStringifyOptions = {
  skipNull: true,
  skipEmptyString: true,
};
