import { Button, Card, Select, Space, Spin } from "antd";
import gql from "graphql-tag";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "../../../hooks";
import * as g from "./__generated__/PortTags";
import * as gu from "./__generated__/UpdatePortTags";

const GET_PORT_TAGS = gql`
  query PortTags($id: ID!) {
    port(id: $id) {
      id
      tags
    }
  }
`;

const UPDATE_PORT_TAGS = gql`
  mutation UpdatePortTags($id: ID!, $tags: [String!]!) {
    updatePortTags(id: $id, tags: $tags) {
      id
      tags
    }
  }
`;

export const PortTags = () => {
  const { id } = useParams<{ id: string }>();
  const [tags, setTags] = useState([] as string[]);

  const { loading } = useQuery<g.PortTags, g.PortTagsVariables>(GET_PORT_TAGS, {
    variables: { id },
    onCompleted: (data) => {
      setTags(data.port.tags);
    }
  });

  const [updateTags] = useMutation<gu.UpdatePortTags, gu.UpdatePortTagsVariables>(UPDATE_PORT_TAGS, {
    okText: "Port updated",
    refetchQueries: ["PortTags"],
  });

  if (loading) {
    return <Spin />;
  }

  return (
    <Card title="Tags">
      <Space direction="vertical" style={{ width: "100%" }}>
        <Select
          style={{ width: "100%" }}
          mode="tags"
          placeholder="Tags"
          value={tags}
          onChange={(val) => setTags(val as string[])}
        />

        <Button
          type="primary"
          style={{ margin: "8px 0" }}
          onClick={() => updateTags({ variables: { id, tags } })}
        >
          Update
        </Button>
      </Space>
    </Card>
  );
};
