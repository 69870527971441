import { QuestionCircleFilled } from "@ant-design/icons";
import { useApolloClient } from "@apollo/client";
import { Checkbox, Col, DatePicker, Form, Popover, Row, Select, Space, Table, Tag } from "antd";
import { LabeledValue } from "antd/lib/select";
import { ColumnsType } from "antd/lib/table";
import { CustomTagProps } from "antd/node_modules/rc-select/lib/BaseSelect";
import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import moment from "moment";
import React, { Fragment, useReducer, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { formatCurrency } from "../../common/formatter";
import i18n from "../../common/i18n";
import { Date } from "../../components/Date";
import { useQuery } from "../../hooks/apollo";
import * as g from "./__generated__/SearchLog";

interface LogListControlledProps {
  actions?: string[]
  causes?: string[]
  subjects?: string[]
}

interface LogListState {
  from: Date
  to: Date
  page: number
  pageSize: number
  actions: string[]
  facilities: string[]
  sources: string[]
  causes: LabeledValue[]
  subjects: LabeledValue[]

  causeKind: string
  subjectKind: string
}

const allActions = [
  "create",
  "update",
  "delete",
  "loginSuccess",
  "loginFailed",
  "updatePassword",
  "updateStatus",
  "refreshToken",
  "reset",
  "changeTarget",
  "assign",
  "release",
  "changeSignature",
  "updateCredentials",
  "updateTags",
  "updateAutoRenew",
  "updateAutoReset",
  "updatePlan",
  "updatePool"
];

const allSources = [
  "adminDashboard",
  "userDashboard",
  "userApi",
  "system",
];

const allFacilities = [
  "admin",
  "modem",
  "node",
  "pool",
  "port",
  "router",
  "signature",
  "user",
  "banner",
  "promo",
];

const allCauseKinds = [
  "user",
  "admin",
  "system",
];

const allSubjectKinds = [
  "admin",
  "modem",
  "node",
  "pool",
  "port",
  "user",
  "vpnPort",
  "vpnServer",
  "banner",
  "promo",
];

const initialState: LogListState = {
  from: moment().startOf("day").toDate(),
  to: moment().endOf("day").toDate(),
  page: 1,
  pageSize: 20,
  actions: allActions,
  facilities: allFacilities,
  sources: allSources,
  causes: [],
  subjects: [],

  causeKind: "user",
  subjectKind: "user",
};

const logListReducer = (
  state: LogListState,
  action: { type: string, data: any }
) => {
  switch (action.type) {
    case "DATES":
      return { ...state, from: action.data[0], to: action.data[1] };
    case "PAGINATE":
      return { ...state, page: action.data.page, pageSize: action.data.pageSize };
    case "ACTIONS":
      return { ...state, actions: action.data };
    case "SOURCES":
      return { ...state, sources: action.data };
    case "FACILITIES":
      return { ...state, facilities: action.data };
    case "CAUSE_KIND":
      if (!allCauseKinds.includes(action.data)) {
        return state;
      }

      return { ...state, causeKind: action.data };
    case "CAUSE_SELECT":
      return { ...state, causes: action.data };
    case "SUBJECT_KIND":
      if (!allSubjectKinds.includes(action.data)) {
        return state;
      }

      return { ...state, subjectKind: action.data };
    case "SUBJECT_SELECT":
      return { ...state, subjects: action.data };
    default:
      throw new Error(`undefined action ${action.type}`);
  }
};

const { Option } = Select;
const { RangePicker } = DatePicker;

const ColumnCheckbox = styled(Checkbox.Group)`
.ant-checkbox-group-item {
  display: block;
  margin-right: 0;
}
`;

const SEARCH_USER = gql`
  query LogSearchUser($input: SearchInput!) {
    searchUser(input: $input) {
      data {
        id
        login
      }
    }
  }
`;

const SEARCH_ADMIN = gql`
  query LogSearchAdmin($input: SearchInput!) {
    searchAdmin(input: $input) {
      data {
        id
        login
      }
    }
  }
`;

const SEARCH_MODEM = gql`
  query LogSearchModem($input: SearchInput!) {
    searchModem(input: $input) {
      data {
        id
      }
    }
  }
`;

const SEARCH_NODE = gql`
  query LogSearchNode($input: SearchInput!) {
    searchNode(input: $input) {
      data {
        id
      }
    }
  }
`;

const SEARCH_POOL = gql`
  query LogSearchPool($input: SearchInput!) {
    searchPool(input: $input) {
      data {
        id
        name
      }
    }
  }
`;

const SEARCH_PORT = gql`
  query LogSearchPort($input: SearchInput!) {
    searchPort(input: $input) {
      data {
        id
      }
    }
  }
`;

const SEARCH_VPN_PORT = gql`
  query LogSearchVPNPort($input: SearchInput!) {
    searchVPNPort(input: $input) {
      data {
        id
      }
    }
  }
`;

const SEARCH_VPN_SERVER = gql`
  query LogSearchVPNServer($input: SearchInput!) {
    searchVPNServer(input: $input) {
      data {
        id
      }
    }
  }
`;

const useEntitySearch = () => {
  const client = useApolloClient();
  const [options, setOptions] = useState<LabeledValue[]>([]);

  const runQuery = async (kind: string, filter: string) => {
    if (kind === "system") {
      setOptions([{ label: "system", value: "system:system:system" }]);
      return;
    }

    const vars = {
      input: {
        filter: filter,
        page: 1,
        pageSize: 10,
      }
    };

    var query: DocumentNode;
    var dataProp: string;
    var nameProp: string;

    switch (kind) {
      case "user":
        query = SEARCH_USER;
        dataProp = "searchUser";
        nameProp = "login";
        break;
      case "admin":
        query = SEARCH_ADMIN;
        dataProp = "searchAdmin";
        nameProp = "login";
        break;
      case "modem":
        query = SEARCH_MODEM;
        dataProp = "searchModem";
        nameProp = "id";
        break;
      case "node":
        query = SEARCH_NODE;
        dataProp = "searchNode";
        nameProp = "id";
        break;
      case "pool":
        query = SEARCH_POOL;
        dataProp = "searchPool";
        nameProp = "name";
        break;
      case "port":
        query = SEARCH_PORT;
        dataProp = "searchPort";
        nameProp = "id";
        break;
      case "vpnPort":
        query = SEARCH_VPN_PORT;
        dataProp = "searchVPNPort";
        nameProp = "id";
        break;
      case "vpnServer":
        query = SEARCH_VPN_SERVER;
        dataProp = "searchVPNServer";
        nameProp = "id";
        break;
      default:
        setOptions([]);
        return;
    }

    const result = await client.query({ query: query!, fetchPolicy: "no-cache", variables: vars });
    const opts = result.data[dataProp!].data.map((e: any) => { return { label: e[nameProp!], value: `${kind}:${e.id}:${e[nameProp!]}` }; });
    setOptions(opts);
  };

  return { options, runQuery };
};

const entityRender = ({ label, value, closable, onClose }: CustomTagProps) => {
  const kind = (value as string).split(":")[0];
  return (
    <Tag closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
      {kind !== "system" && `${kind}:`}{label}
    </Tag>
  );
};

const SEARCH_LOG = gql`
  query SearchLog($input: SearchLogInput!) {
    searchLog(input: $input) {
      data {
        __typename
        id
        ip
        action
        facility
        source
        cause {
          id
          name
        }
        subject {
          id
          name
        }
        extra {
          ... on LogUpdateStatusExtra {
            status
          }
          ... on LogChangeTargetExtra {
            target
            reason
          }
          ... on LogChangeSignatureExtra {
            signature
          }
          ... on LogPoolAssignmentExtra {
            port
            modem
            ip
          }
          ... on LogPortResetExtra {
            ip
            modem
          }
          ... on LogUpdatePlanExtra {
            plan {
              enabled
              name
              autoRenew
              tarification {
                time
                traffic
                price
              }
              activatedAt
              expiresAt
              trafficRemains
            }
          }
          ... on LogUpdatePoolExtra {
            added
            removed
          }
        }
        createdAt
      }
      page
      pageSize
      total
    }
  }
`;

export const LogListControlled = (props: LogListControlledProps) => {
  const [state, dispatch] = useReducer(logListReducer, initialState);
  const result = useQuery<g.SearchLog, g.SearchLogVariables>(SEARCH_LOG, {
    fetchPolicy: "no-cache",
    variables: {
      input: {
        from: state.from,
        to: state.to,
        page: state.page,
        pageSize: state.pageSize,
        actions: (props.actions || allActions) as any,
        facilities: allFacilities as any,
        sources: allSources as any,
        causes: (props.causes || []) as any,
        subjects: (props.subjects || []) as any,
      },
    }
  });

  return (
    <Fragment>
      <Row gutter={24}>
        <Col span={12}>
          <RangePicker
            allowClear={false}
            showTime={true}
            value={[moment(state.from), moment(state.to)]}
            onChange={(dates) => dispatch({ type: "DATES", data: dates })}
          />
        </Col>
      </Row>
      <br />
      <Table
        size="middle"
        rowKey={record => record.id}
        loading={result.loading}
        columns={columns}
        dataSource={result.data?.searchLog.data}
        pagination={{
          showSizeChanger: true,
          current: state.page,
          pageSize: state.pageSize,
          total: result.data?.searchLog.total,
        }}
        onChange={(pagination) => dispatch({ type: "PAGINATE", data: { page: pagination.current, pageSize: pagination.pageSize } })}
      />
    </Fragment>
  );
};

export const LogList = () => {
  const [state, dispatch] = useReducer(logListReducer, initialState);
  const { "options": causeOptions, "runQuery": causeQuery } = useEntitySearch();
  const { "options": subjectOptions, "runQuery": subjectQuery } = useEntitySearch();

  const result = useQuery<g.SearchLog, g.SearchLogVariables>(SEARCH_LOG, {
    fetchPolicy: "no-cache",
    variables: {
      input: {
        from: state.from,
        to: state.to,
        page: state.page,
        pageSize: state.pageSize,
        actions: state.actions,
        facilities: state.facilities,
        sources: state.sources,
        causes: state.causes.map((e: any) => e.split(":")[1]),
        subjects: state.subjects.map((e: any) => e.split(":")[1]),
      },
    }
  });

  return (
    <Fragment>
      <Form name="control">
        <Row gutter={24}>
          <Col span={2}>Causes</Col>
          <Col span={22}>
            <Select value={state.causeKind} onChange={(val) => { causeQuery(val, ""); dispatch({ type: "CAUSE_KIND", data: val }); }} style={{ width: "10%" }}>
              {allCauseKinds.map(c => <Option key={c} value={c}>{c}</Option>)}
            </Select>
            <Select
              placeholder="Select cause"
              filterOption={false}
              showSearch={true}
              mode="multiple"
              value={state.causes}
              options={causeOptions}
              tagRender={entityRender}
              onSearch={(val) => causeQuery(state.causeKind, val)}
              onChange={(val) => dispatch({ type: "CAUSE_SELECT", data: val })}
              style={{ width: "90%" }}
            />
          </Col>
        </Row>
        <br />
        <Row gutter={24}>
          <Col span={2}>Subjects</Col>
          <Col span={22}>
            <Select value={state.subjectKind} onChange={(val) => { subjectQuery(val, ""); dispatch({ type: "SUBJECT_KIND", data: val }); }} style={{ width: "10%" }}>
              {allSubjectKinds.map(c => <Option key={c} value={c}>{c}</Option>)}
            </Select>
            <Select
              placeholder="Select subject"
              filterOption={false}
              showSearch={true}
              mode="multiple"
              value={state.subjects}
              options={subjectOptions}
              tagRender={entityRender}
              onSearch={(val) => subjectQuery(state.subjectKind, val)}
              onChange={(val) => dispatch({ type: "SUBJECT_SELECT", data: val })}
              style={{ width: "90%" }}
            />
          </Col>
        </Row>
        <br />
        <Row gutter={24}>
          <Col span={2}>Actions</Col>
          <Col span={20}>
            <ColumnCheckbox
              value={state.actions}
              onChange={(list) => dispatch({ type: "ACTIONS", data: list })}
            >
              <Row gutter={24}>
                {chunk(allActions, 5).map(col => col.map(x => <Col key={x} span={6}><Checkbox key={x} value={x}>{x}</Checkbox></Col>))}
              </Row>
            </ColumnCheckbox>
            <br /><br />
            <Checkbox
              checked={state.actions.length === allActions.length}
              indeterminate={state.actions.length !== 0 && state.actions.length !== allActions.length}
              onChange={() => dispatch({ type: "ACTIONS", data: state.actions.length === 0 ? allActions : [] })}
            >
              Check all
            </Checkbox>
          </Col>
        </Row>
        <br />
        <Row gutter={24}>
          <Col span={2}>Facilities</Col>
          <Col span={18}>
            <Checkbox.Group
              options={allFacilities}
              value={state.facilities}
              onChange={(list) => dispatch({ type: "FACILITIES", data: list })}
            />
            <br /><br />
            <Checkbox
              checked={state.facilities.length === allFacilities.length}
              indeterminate={state.facilities.length !== 0 && state.facilities.length !== allFacilities.length}
              onChange={() => dispatch({ type: "FACILITIES", data: state.facilities.length === 0 ? allFacilities : [] })}
            >
              Check all
            </Checkbox>
          </Col>
        </Row>
        <br />
        <Row gutter={24}>
          <Col span={2}>Sources</Col>
          <Col span={18}>
            <Checkbox.Group
              options={allSources}
              value={state.sources}
              onChange={(list) => dispatch({ type: "SOURCES", data: list })}
            />
            <br /><br />
            <Checkbox
              checked={state.sources.length === allSources.length}
              indeterminate={state.sources.length !== 0 && state.sources.length !== allSources.length}
              onChange={() => dispatch({ type: "SOURCES", data: state.sources.length === 0 ? allSources : [] })}
            >
              Check all
            </Checkbox>
          </Col>
        </Row>
        <br />
        <Row gutter={24}>
          <Col span={24}>
            <RangePicker
              allowClear={false}
              showTime={true}
              value={[moment(state.from), moment(state.to)]}
              onChange={(dates) => dispatch({ type: "DATES", data: dates })}
            />
          </Col>
        </Row>
      </Form>
      <br />
      <Table
        size="middle"
        rowKey={record => record.id}
        loading={result.loading}
        columns={columns}
        dataSource={result.data?.searchLog.data}
        pagination={{
          showSizeChanger: true,
          current: state.page,
          pageSize: state.pageSize,
          total: result.data?.searchLog.total,
        }}
        onChange={(pagination) => dispatch({ type: "PAGINATE", data: { page: pagination.current, pageSize: pagination.pageSize } })}
      />
    </Fragment >
  );
};

const renderCause = (_text: string, record: g.SearchLog_searchLog_data) => {
  switch (record.source) {
    case "adminDashboard":
      return <Link to={`/admins/${record.cause.id}`}>{record.cause.name}</Link>;
    case "system":
      return "system";
    default:
      return <Link to={`/users/${record.cause.id}`}>{record.cause.name}</Link>;
  }
};

const renderSubject = (_text: string, record: g.SearchLog_searchLog_data) => {
  if (record.facility === "vpnPort") {
    return <Link to={`/vpn/${record.subject.id}`}>{record.subject.name}</Link>;
  }

  if (record.facility === "vpnServer") {
    return record.subject.name;
  }

  return <Link to={`/${record.facility}s/${record.subject.id}`}>{record.subject.name}</Link>;
};

const renderExtra = (_text: string, record: g.SearchLog_searchLog_data) => {
  switch (record.action) {
    case "updateStatus":
      const updateExtra = record.extra as g.SearchLog_searchLog_data_extra_LogUpdateStatusExtra;
      return updateExtra.status;
    case "changeTarget":
      const targetExtra = record.extra as g.SearchLog_searchLog_data_extra_LogChangeTargetExtra;

      if (targetExtra.reason !== "") {
        return `${targetExtra.target} | ${targetExtra.reason}`;
      }

      return targetExtra.target;
    case "changeSignature":
      const signatureExtra = record.extra as g.SearchLog_searchLog_data_extra_LogChangeSignatureExtra;
      return signatureExtra.signature;
    case "reset":
      const resetExtra = record.extra as g.SearchLog_searchLog_data_extra_LogPortResetExtra;

      if (!resetExtra.ip || !resetExtra.modem) {
        return "";
      }

      return (
        <Space>
          {resetExtra.ip}
          |
          <Link to={`/modems/${resetExtra.modem}`}><Tag color="blue">{resetExtra.modem}</Tag></Link>
        </Space>
      );
    case "updatePlan":
      const planExtra = record.extra as g.SearchLog_searchLog_data_extra_LogUpdatePlanExtra;

      const content = (
        <Space direction="vertical">
          {`Enabled: ${String(planExtra.plan.enabled)}`}
          {`Auto Renew: ${String(planExtra.plan.autoRenew)}`}
          {`Option: ${renderOption(planExtra.plan.tarification)}`}
          <Space>Activated At:<Date date={planExtra.plan.activatedAt} /></Space>
          <Space>Expires At: <Date date={planExtra.plan.expiresAt} /></Space>
          {`Traffic Remains: ${planExtra.plan.trafficRemains <= 0 ? "∞" : planExtra.plan.trafficRemains / 1024} GB`}
        </Space >
      );

      return (
        <Space>
          {planExtra.plan.name}
          <Popover content={content} title={planExtra.plan.name}>
            <QuestionCircleFilled />
          </Popover>
        </Space>
      );
    case "updatePool":
      const poolModemsExtra = record.extra as g.SearchLog_searchLog_data_extra_LogUpdatePoolExtra;

      return (
        <Space>
          <Space>
            <span>Added ({poolModemsExtra.added.length})</span>
            <Popover content={poolModemsExtra.added.map((e: any) => <Link key={e} to={`/modems/${e}`}><Tag color="blue">{e}</Tag></Link>)} title="Added">
              <QuestionCircleFilled />
            </Popover>
          </Space>
          <Space>
            <span>Removed ({poolModemsExtra.removed.length})</span>
            <Popover content={poolModemsExtra.removed.map((e: any) => <Link key={e} to={`/modems/${e}`}><Tag color="red">{e}</Tag></Link>)} title="Removed">
              <QuestionCircleFilled />
            </Popover>
          </Space>
        </Space>
      );
    case "assign":
    case "release":
      const poolExtra = record.extra as g.SearchLog_searchLog_data_extra_LogPoolAssignmentExtra;
      return poolExtra.modem;
  }
};

const renderSource = (text: string) => {
  switch (text) {
    case "adminDashboard":
      return <Tag color="blue">admin</Tag>;
    case "userDashboard":
      return <Tag color="cyan">my</Tag>;
    case "userApi":
      return <Tag color="green">api</Tag>;
    default:
      return <Tag color="lime">system</Tag>;
  }
};

const renderOption = (opt: any) => {
  if (!opt) {
    return "";
  }

  return `${opt.time / 86400} Days / ${opt.traffic <= 0 ? "∞" : opt.traffic / 1024} GB / ${formatCurrency(opt.price / 100)}`;
};


const columns: ColumnsType<g.SearchLog_searchLog_data> = [
  {
    dataIndex: "action",
    title: i18n.t("log:Action"),
  },
  {
    dataIndex: "ip",
    title: i18n.t("log:IP"),
  },
  {
    key: "cause",
    title: i18n.t("log:Cause"),
    render: renderCause,
  },
  {
    key: "subject",
    title: i18n.t("log:Subject"),
    render: renderSubject,
  },
  {
    dataIndex: "source",
    title: i18n.t("log:Source"),
    render: renderSource,
  },
  {
    dataIndex: "extra",
    title: i18n.t("log:Extra"),
    render: renderExtra,
  },
  {
    dataIndex: "createdAt",
    title: i18n.t("log:Created At"),
    render: text => <Date date={text} />,
  },
];

const chunk = (arr: any[], n: number) => {
  return Array.apply(null, Array(n)).map((x, i) => Math.ceil(arr.length / n)).map((x, i) => arr.slice(i * n, i * n + n));
};
