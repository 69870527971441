import { InputNumber, Select, Typography } from "antd";
import React, { useState } from "react";

type Unit = "mb" | "gb"

const unitMultipliers = {
  "mb": 1,
  "gb": 1024,
};

// https://github.com/ant-design/ant-design/issues/31431
const unitPrecision = {
  "mb": 0,
  "gb": 1,
};

const dataAmountParser = (unit: Unit) => (value: string | undefined) => {
  if (value === "∞") {
    return -1;
  }

  const numValue = parseInt(value || "", 10);
  return Number.isNaN(numValue) ? 0 : unitMultipliers[unit] * numValue;
};

const dataAmountFormatter = (unit: Unit) => (value: number | undefined, info: { userTyping: boolean, input: string }) => {
  if (!value) {
    return "0";
  }

  if (value < 0) {
    return "∞";
  }

  return (value / unitMultipliers[unit]).toFixed(0);
};

export interface InputDataAmountProps {
  value?: number;
  defaultUnit?: Unit;
  onChange?: (value: number) => void;
  onSetInfinite?: () => void;
}

export const InputDataAmount = (props: InputDataAmountProps) => {
  const defaultUnit = props.value && props.value < 1024 ? "mb" : "gb";
  const [unit, setUnit] = useState<Unit>(props.defaultUnit || defaultUnit);

  const infiniteSetter = (
    <Typography.Text
      style={{ cursor: "pointer" }}
      onClick={() => props.onSetInfinite && props.onSetInfinite()}
    >
      ∞
    </Typography.Text>
  );

  const unitSelector = (
    <Select
      value={unit}
      onChange={setUnit}
    >
      <Select.Option value="mb">MB</Select.Option>
      <Select.Option value="gb">GB</Select.Option>
    </Select>
  );

  return (
    <InputNumber
      addonAfter={unitSelector}
      addonBefore={infiniteSetter}
      parser={dataAmountParser(unit)}
      formatter={dataAmountFormatter(unit)}
      precision={unitPrecision[unit]}
      step={unitMultipliers[unit]}
      value={props.value}
      onChange={props.onChange}
    />
  );
};
