import { Card, Table, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import gql from "graphql-tag";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import i18n from "../../common/i18n";
import { Date } from "../../components/Date";
import { useQuery } from "../../hooks";
import { ResetActions } from "./ResetActions";
import * as g from "./__generated__/SearchModemStucked";

const SEARCH_MODEM = gql`
  query SearchModemStucked($input: SearchInput!) {
    searchModemStucked(input: $input) {
      data {
        id
        iface
        proxyPort
        webHost
        externalIp
        manufacturer
        status
        wanIp
        pingTs
        comment
        createdAt
        updatedAt
        ports {
          id
        }
        pools {
          id
          name
        }
      }
      page
      pageSize
      total
    }
  }
`;

const renderStatus = (text: string) => {
  switch (text) {
    case "online":
      return <Tag color="green">{text}</Tag>;
    case "offline":
      return <Tag color="orange">{text}</Tag>;
    case "disconnected":
      return <Tag color="red">{text}</Tag>;
    default:
      return <Tag color="blue">{text}</Tag>;
  }
};

const renderTarget = (text: string, record: g.SearchModemStucked_searchModemStucked_data) => {
  return (
    <div>
      {record.ports.map(x => <div key={x.id}><Link to={`/ports/${x.id}`}><Tag color="blue">{x.id}</Tag></Link></div>)}
      {record.pools.map(x => <div key={x.id}><Link to={`/pools/${x.id}`}><Tag color="purple">{x.name}</Tag></Link></div>)}
    </div>
  );
};

const columns: ColumnsType<g.SearchModemStucked_searchModemStucked_data> = [
  {
    dataIndex: "id",
    title: i18n.t("modem:ID"),
    sorter: true,
    render: (text, record) => <Link to={`/modems/${record.id}`}>{text}</Link>,
  },
  {
    dataIndex: "wanIp",
    title: i18n.t("modem:Wan IP"),
    sorter: true,
    render: (text) => <Link to={`/ips/${text}`}>{text}</Link>,
  },
  {
    dataIndex: "status",
    title: i18n.t("modem:Status"),
    sorter: true,
    render: renderStatus
  },
  {
    dataIndex: "proxyPort",
    title: i18n.t("modem:Proxy port"),
    sorter: true,
  },
  {
    key: "links",
    title: i18n.t("modem:Links"),
    render: renderTarget,
  },
  {
    dataIndex: "comment",
    title: i18n.t("modem:Comment"),
  },
  {
    dataIndex: "pingTs",
    title: i18n.t("modem:Ping At"),
    render: text => <Date date={text} />,
  },
  {
    key: "Reset",
    title: i18n.t("modem:Reset"),
    render: (_, record) => <ResetActions id={record.id} refetch="SearchModemStucked" />,
  }
];

export const ModemStuckedList = () => {
  const { t } = useTranslation("modem");

  const result = useQuery<g.SearchModemStucked>(SEARCH_MODEM, {
    fetchPolicy: "no-cache",
    variables: { input: { page: 1, pageSize: 10000 } },
  });

  return (
    <Card title={t("Stuck modems")} bordered={false} headStyle={{ padding: "0" }} bodyStyle={{ padding: "24px 0" }}>
      <Table
        size="middle"
        rowKey={record => record.id}
        loading={result.loading}
        columns={columns}
        pagination={false}
        dataSource={result.data?.searchModemStucked.data}
      />
    </Card>

  );
};
