import { Layout } from "antd";
import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { authenticationService } from "../common/auth";
import { Breadcrumb } from "../components/Breadcrumb";
import { Header } from "../components/Header";
import { Sidebar } from "../components/Sidebar";

const { Content } = Layout;

interface PrivateRouteProps extends RouteProps {
  component: any;
}

export const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, ...rest } = props;

  return (
    <Route {...rest} render={props => {
      const currentUser = authenticationService.currentUserValue;
      if (!currentUser) {
        return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
      }

      return (
        <Layout style={{ minHeight: "100vh" }}>
          <Sidebar />
          <Layout style={{ background: "#ffffff" }}>
            <Header />
            <Content style={{ margin: "0 16px" }}>
              <Breadcrumb />
              <div style={{ background: "#ffffff", padding: 24, minHeight: 360 }}>
                <Component {...props} />
              </div>
            </Content>
          </Layout>
        </Layout >
      );
    }} />
  );
};
