import { SearchOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import { SortOrder } from "antd/lib/table/interface";
import gql from "graphql-tag";
import React from "react";
import { Link } from "react-router-dom";
import i18n from "../../common/i18n";
import { Date } from "../../components/Date";
import { ListContainer } from "../../containers/ListContainer";
import { useSearch } from "../../hooks";
import { PoolCreate } from "./Create";
import * as g from "./__generated__/SearchPool";

const SEARCH_POOL = gql`
  query SearchPool($input: SearchInput!) {
    searchPool(input: $input) {
      data {
        id
        name
        modems
        portsCount
        summary {
          availableCount
          assignedCount
          unavailableCount
        }
        assignments {
          port
          modem
        }
        createdAt
        updatedAt
        deletedAt
      }
      page
      pageSize
      total
    }
  }
`;

const columns: ColumnsType<g.SearchPool_searchPool_data> = [
  {
    dataIndex: "name",
    title: i18n.t("pool:Name"),
    sorter: true,
    render: (text, record) => <Link to={`/pools/${record.id}`}>{text}</Link>,
  },
  {
    key: "ports",
    title: i18n.t("pool:Ports"),
    render: (_, record) => {
      return (
        <Link to={`/ports/list?filter=${record.name}`}>
          <Button icon={<SearchOutlined />} size="small">
            {` ${record.portsCount}`}
          </Button>
        </Link>
      );
    }
  },
  {
    key: "usage",
    title: i18n.t("pool:Usage"),
    render: (_, record) => `${Math.round(record.portsCount * 100 / record.modems.length)}%`
  },
  {
    key: "summary",
    title: <Tooltip title={`${i18n.t("node:Available")} / ${i18n.t("node:Assigned")} / ${i18n.t("node:Unavailable")}`}>
      {i18n.t("pool:Summary")}
    </Tooltip>,
    render: (_, record) => [record.summary.availableCount, record.summary.assignedCount, record.summary.unavailableCount].join(" / ")
  },
  {
    dataIndex: "createdAt",
    title: i18n.t("pool:Created At"),
    sorter: true,
    render: text => <Date date={text} />,
  },
  {
    dataIndex: "updatedAt",
    title: i18n.t("pool:Updated At"),
    sorter: true,
    render: text => <Date date={text} />,
    defaultSortOrder: "descend" as SortOrder,
  },
];

export const PoolList = () => {
  const { result, search, updateFilter, updateTable } = useSearch<g.SearchPool>(SEARCH_POOL);

  return (
    <ListContainer
      create={<PoolCreate />}
      search={search}
      columns={columns}
      data={result.data?.searchPool.data}
      total={result.data?.searchPool.total}
      loading={result.loading}
      onSearch={updateFilter}
      onTableChange={updateTable}
    />
  );
};
