import { CheckCircleTwoTone, ExclamationCircleTwoTone } from "@ant-design/icons";
import { Button, Popconfirm, Popover } from "antd";
import { ColumnsType } from "antd/lib/table";
import { SortOrder } from "antd/lib/table/interface";
import gql from "graphql-tag";
import moment from "moment";
import React, { useState } from "react";
import i18n from "../../common/i18n";
import { Date } from "../../components/Date";
import { ListContainer } from "../../containers/ListContainer";
import { useMutation, useSearch } from "../../hooks";
import { VPNServerCreate } from "./CreateServer";
import * as gd from "./__generated__/DeleteVPNServer";
import * as g from "./__generated__/SearchVPNServer";

const SEARCH_VPN_SERVER = gql`
  query SearchVPNServer($input: SearchInput!) {
    searchVPNServer(input: $input) {
      data {
        id
        heartbeat
        updatedAt
      }
      page
      pageSize
      total
    }
  }
`;

const DELETE_VPN_SERVER = gql`
  mutation DeleteVPNServer($id: ID!) {
    deleteVPNServer(id: $id)
  }
`;

const renderHeartbeat = (now: any) => (_text: string, record: g.SearchVPNServer_searchVPNServer_data) => {
  const [nowTs, heartbeatTs] = [
    moment(now).subtract(1, "minute"),
    moment(record.heartbeat),
  ];

  let icon = <CheckCircleTwoTone twoToneColor="green" />;
  if (heartbeatTs < nowTs) {
    icon = <ExclamationCircleTwoTone twoToneColor="orange" />;
  }

  return (
    <Popover
      title={i18n.t("vpn:Healthcheck")}
      content={<p>{i18n.t("vpn:Synced")} {heartbeatTs.fromNow()}</p>}
    >
      {icon}
    </Popover>
  );
};

export const VPNServerList = () => {
  const [nowTs, setNowTs] = useState(moment());

  const { result, search, updateFilter, updateTable } = useSearch<g.SearchVPNServer>(SEARCH_VPN_SERVER, {
    onCompleted: () => {
      setNowTs(moment());
    }
  });

  const [deleteVPNServer] = useMutation<gd.DeleteVPNServer, gd.DeleteVPNServerVariables>(DELETE_VPN_SERVER, {
    okText: i18n.t("vpn:VPN server deleted"),
    refetchQueries: ["SearchVPNServer"],
  });

  const columns: ColumnsType<g.SearchVPNServer_searchVPNServer_data> = [
    {
      dataIndex: "id",
      title: i18n.t("vpn:ID"),
      sorter: true,
    },
    {
      key: "status",
      title: i18n.t("vpn:Status"),
      render: renderHeartbeat(nowTs),
    },
    {
      dataIndex: "updatedAt",
      title: i18n.t("vpn:Updated At"),
      sorter: true,
      render: text => <Date date={text} />,
      defaultSortOrder: "descend" as SortOrder,
    },
    {
      key: "Delete",
      title: i18n.t("vpn:Delete"),
      render: (_, record) => {
        return (
          <Popconfirm
            title={i18n.t("vpn:Delete VPN server?")}
            okText={i18n.t("vpn:Yes")}
            cancelText={i18n.t("vpn:No")}
            onConfirm={() => deleteVPNServer({ variables: { id: record.id } })}
          >
            <Button htmlType="button">
              {i18n.t("vpn:Delete")}
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <ListContainer
      create={<VPNServerCreate />}
      search={search}
      columns={columns}
      data={result.data?.searchVPNServer.data}
      total={result.data?.searchVPNServer.total}
      loading={result.loading}
      onSearch={updateFilter}
      onTableChange={updateTable}
    />
  );
};
