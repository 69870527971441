import { Form, Input } from "antd";
import gql from "graphql-tag";
import React, { useState } from "react";
import i18n from "../../common/i18n";
import { CreateForm } from "../../containers/CreateForm";
import { useMutation } from "../../hooks";
import * as g from "./__generated__/CreateSignature";

const CREATE_SIGNATURE = gql`
  mutation CreateSignature($id: String!, $raw: String!) {
    createSignature(id: $id, raw: $raw) {
      id
      raw
      updatedAt
    }
  }
`;

export const SignatureCreate = () => {
  const [form] = Form.useForm<g.CreateSignatureVariables>();
  const [isOpen, toggle] = useState(false);

  const [createSignature] = useMutation<{
    createSignature: g.CreateSignature,
    signature: g.CreateSignature_createSignature,
  }>(CREATE_SIGNATURE, {
    okText: i18n.t("signature:Signature created"),
    refetchQueries: ["SearchSignature"],
    onCompleted: (_) => {
      form.resetFields();
      toggle(false);
    },
  });

  return (
    <CreateForm
      name="create-signature"
      title={i18n.t("signature:Create signature")}
      form={form}
      isOpen={isOpen}
      toggleOpen={() => toggle(!isOpen)}
      onCreate={(values) => createSignature({ variables: values })}
    >
      <Form.Item
        name="id"
        label={i18n.t("signature:ID")}
        rules={[{ required: true }]}
      >
        <Input placeholder="NT" />
      </Form.Item>
      <Form.Item
        name="raw"
        label={i18n.t("signature:Raw")}
        rules={[{ required: true }]}
      >
        <Input placeholder="*:128:0:*:*,*:mss,nop,nop,sok:df,id+:0" />
      </Form.Item>
    </CreateForm>
  );
};
