import { Form, Input } from "antd";
import gql from "graphql-tag";
import React, { useState } from "react";
import i18n from "../../common/i18n";
import { CreateForm } from "../../containers/CreateForm";
import { useMutation } from "../../hooks";
import * as g from "./__generated__/CreateVPNServer";

const CREATE_VPN_SERVER = gql`
  mutation CreateVPNServer($id: String!) {
    createVPNServer(id: $id) {
      id
      heartbeat
      updatedAt
    }
  }
`;

export const VPNServerCreate = () => {
  const [form] = Form.useForm<g.CreateVPNServerVariables>();
  const [isOpen, toggle] = useState(false);

  const [createVPNServer] = useMutation<{
    createVPNServer: g.CreateVPNServer,
    admin: g.CreateVPNServer_createVPNServer,
  }>(CREATE_VPN_SERVER, {
    okText: i18n.t("vpn:VPN server created"),
    refetchQueries: ["SearchVPNServer"],
    onCompleted: (_) => {
      form.resetFields();
      toggle(false);
    },
  });

  return (
    <CreateForm
      name="create-vpn-server"
      title={i18n.t("vpn:Create VPN server")}
      form={form}
      isOpen={isOpen}
      toggleOpen={() => toggle(!isOpen)}
      onCreate={(values) => createVPNServer({ variables: values })}
    >
      <Form.Item
        name="id"
        label={i18n.t("vpn:ID")}
        rules={[{ required: true }]}
      >
        <Input placeholder="vpn1.example.com" />
      </Form.Item>
    </CreateForm>
  );
};
