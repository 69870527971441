import { Card, Col, DatePicker, Row, Statistic } from "antd";
import gql from "graphql-tag";
import moment from "moment";
import React, { useReducer } from "react";
import { useQuery } from "../../hooks";
import * as g from "./__generated__/PromoStats";

const PROMO_STATS = gql`
  query PromoStats($id: ID!, $from: Time!, $to: Time!) {
    promoStats(id: $id, from: $from, to: $to) {
      views
      registrations
    }
  }
`;

interface PromoStatsProps {
  id: string;
}

interface PromoStatsState {
  from: Date
  to: Date
}

const initialState: PromoStatsState = {
  from: moment().startOf("month").toDate(),
  to: moment().endOf("month").toDate(),
};

const promoStatsReducer = (
  state: PromoStatsState,
  action: { type: string, data: any }
) => {
  switch (action.type) {
    case "DATES":
      return { ...state, from: action.data[0], to: action.data[1] };
    default:
      throw new Error(`undefined action ${action.type}`);
  }
};

const { RangePicker } = DatePicker;

export const PromoStats = (props: PromoStatsProps) => {
  const [state, dispatch] = useReducer(promoStatsReducer, initialState);

  const result = useQuery<g.PromoStats, g.PromoStatsVariables>(PROMO_STATS, {
    fetchPolicy: "no-cache",
    variables: {
      id: props.id,
      from: state.from,
      to: state.to,
    },
  });

  return (
    <Card title="Stats">
      <RangePicker
        allowClear={false}
        showTime={true}
        value={[moment(state.from), moment(state.to)]}
        onChange={(dates) => dispatch({ type: "DATES", data: dates })}
      />
      <br />
      <br />
      <Row gutter={24}>
        <Col span={8}>
          <Statistic title="Views" value={result.data?.promoStats.views} />
        </Col>
        <Col span={8}>
          <Statistic title="Registrations" value={result.data?.promoStats.registrations} />
        </Col>
      </Row>
    </Card>
  );
};
