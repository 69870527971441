import { Button, Checkbox, Form, Input, Modal, Select, Spin, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import gql from "graphql-tag";
import React, { Fragment, useReducer } from "react";
import { useHistory, useParams } from "react-router-dom";
import i18n from "../../common/i18n";
import { EditForm } from "../../containers/EditForm";
import { useMutation, useQuery } from "../../hooks";
import * as gd from "./__generated__/DeleteVPNPort";
import * as gu from "./__generated__/UpdateVPNPort";
import * as g from "./__generated__/VPNPort";

const GET_VPN_PORT = gql`
query VPNPort($id: ID!) {
  vpnPort(id: $id) {
    id
    credentials {
      kind
      ipFilter
      login
      digest
    }
    target
    dnsHijack
    dnsServer
    createdAt
    updatedAt
  }
}
`;

const UPDATE_VPN_PORT = gql`
  mutation UpdateVPNPort($id: ID!, $input: VPNPortUpdateInput!) {
    updateVPNPort(id: $id, input: $input) {
      id
      credentials {
        kind
        ipFilter
        login
        digest
      }
      target
      dnsHijack
      dnsServer
      createdAt
      updatedAt
    }
  }
`;

const DELETE_VPN_PORT = gql`
  mutation DeleteVPNPort($id: ID!) {
    deleteVPNPort(id: $id)
  }
`;

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

interface IVPNPortEditState {
  target: string,
  isOpen: boolean
  credentials: { kind: "ip" | "password", ipFilter: string, login: string, password: string }[]
  dnsHijack: boolean
  dnsServer: string
}

const initialState: IVPNPortEditState = {
  target: "",
  isOpen: false,
  credentials: [],
  dnsHijack: false,
  dnsServer: "",
};

const vpnPortEditStateReducer = (
  state: IVPNPortEditState,
  action: { type: string, data: any }
) => {
  switch (action.type) {
    case "INIT":
      return {
        ...state,
        target: action.data.target,
        credentials: action.data.credentials,
        dnsHijack: action.data.dnsHijack,
        dnsServer: action.data.dnsServer,
      };
    case "DELETE_CREDENTIALS":
      return {
        ...state,
        credentials: state.credentials.filter((_, i) => i !== action.data),
      };
    case "TOGGLE_ADD_CREDENTIALS":
      return { ...state, isOpen: !state.isOpen };
    case "ADD_CREDENTIALS":
      return { ...state, isOpen: !state.isOpen, credentials: [...state.credentials, action.data] };
    case "TOGGLE_DNS_HIJACK":
      return { ...state, dnsHijack: !state.dnsHijack };
    case "CHANGE_DNS_SERVER":
      return { ...state, dnsServer: action.data };
    case "CHANGE_TARGET":
      return { ...state, target: action.data };
    default:
      throw new Error(`undefined action ${action.type}`);
  };
};

interface VPNPortEditFields {
  id: string;
}

export const VPNPortEdit = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm<VPNPortEditFields>();
  const [addForm] = Form.useForm();

  const [state, dispatch] = useReducer(vpnPortEditStateReducer, initialState);

  const { loading, data } = useQuery<g.VPNPort, g.VPNPortVariables>(GET_VPN_PORT, {
    variables: { id },
    onCompleted: (data) => {
      dispatch({ type: "INIT", data: data.vpnPort });
    }
  });

  const [updatePort] = useMutation<gu.UpdateVPNPort, gu.UpdateVPNPortVariables>(UPDATE_VPN_PORT, {
    okText: i18n.t("port:Port updated"),
    refetchQueries: ["VPNPort"],
  });

  const [deletePort] = useMutation<gd.DeleteVPNPort, gd.DeleteVPNPortVariables>(DELETE_VPN_PORT, {
    okText: i18n.t("port:Port deleted"),
    onCompleted: (_) => {
      history.push("/vpn");
    }
  });

  const credColumns: ColumnsType<g.VPNPort_vpnPort_credentials> = [
    {
      key: "kind",
      title: i18n.t("port:Kind"),
      dataIndex: "kind",
    },
    {
      key: "ipFilter",
      title: i18n.t("port:IP Filter"),
      dataIndex: "ipFilter",
    },
    {
      key: "login",
      title: i18n.t("port:Login"),
      dataIndex: "login",
    },
    {
      key: "delete",
      title: i18n.t("port:Delete"),
      render: (_text, _record, index) => <Button onClick={() => dispatch({ type: "DELETE_CREDENTIALS", data: index })}>{i18n.t("port:Delete")}</Button>
    },
  ];

  const footer = (
    <Fragment>
      <Button onClick={() => dispatch({ type: "TOGGLE_ADD_CREDENTIALS", data: {} })}>
        {i18n.t("port:Cancel")}
      </Button>
      <Button type="primary" onClick={() => addForm.submit()}>
        {i18n.t("port:Create")}
      </Button>
    </Fragment>
  );

  if (loading) {
    return <Spin />;
  }

  return (
    <Fragment>
      <Modal
        title={i18n.t("port:Add credentials")}
        visible={state.isOpen}
        centered={true}
        footer={footer}
        forceRender={true}
        onCancel={() => dispatch({ type: "TOGGLE_ADD_CREDENTIALS", data: {} })}
      >
        <Form
          {...formLayout}
          form={addForm}
          name="add-credentials"
          initialValues={{ kind: "ip" }}
          onFinish={(values) => dispatch({ type: "ADD_CREDENTIALS", data: values })}
        >
          <Form.Item name="kind" label={i18n.t("port:Kind")} rules={[{ required: true }]}>
            <Select style={{ width: 120 }}>
              <Select.Option value="ip">{i18n.t("port:IP")}</Select.Option>
              <Select.Option value="password">{i18n.t("port:Password")}</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              if (getFieldValue("kind") === "ip") {
                return <Form.Item name="ipFilter" label={i18n.t("port:IP Filter")} rules={[{ required: true }]}>
                  <Input />
                </Form.Item>;
              }

              if (getFieldValue("kind") === "password") {
                return <Fragment>
                  <Form.Item name="login" label={i18n.t("port:Login")} rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                  <Form.Item name="password" label={i18n.t("port:Password")} rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                </Fragment>;
              }
            }}
          </Form.Item>
        </Form>
      </Modal>

      <h3>{i18n.t("Port:Edit")}</h3>
      <EditForm
        name="edit-port"
        deleteTitle={i18n.t("port:Delete port?")}
        loading={loading}
        form={form}
        initialValues={data?.vpnPort}
        onSave={(values) => updatePort({ variables: { id, input: { target: state.target, credentials: state.credentials, dnsHijack: state.dnsHijack, dnsServer: state.dnsServer } } })}
        onDelete={() => deletePort({ variables: { id } })}
      >
        <Form.Item
          name="id"
          label={i18n.t("port:ID")}
        >
          <Input disabled={true} />
        </Form.Item>
        <Form.Item
          name={"dnsHijack"}
          label={i18n.t("port:DNS Hijack")}
        >
          <Checkbox checked={state.dnsHijack} onChange={() => dispatch({ type: "TOGGLE_DNS_HIJACK", data: "" })} />
        </Form.Item>
        <Form.Item
          name={"dnsServer"}
          label={i18n.t("port:DNS Server")}
        >
          <Input value={state.dnsServer} onChange={(val) => dispatch({ type: "CHANGE_DNS_SERVER", data: val.currentTarget.value })} />
        </Form.Item>
        <Form.Item
          name={"target"}
          label={i18n.t("port:Target")}
        >
          <Input value={state.target} onChange={(val) => dispatch({ type: "CHANGE_TARGET", data: val.currentTarget.value })} />
        </Form.Item>
        <Form.Item label={i18n.t("port:Credentials")}>
          <Table
            rowKey={(_, i) => `cred-${i}`}
            columns={credColumns}
            dataSource={state.credentials}
            pagination={false}
          />
          <Button style={{ margin: "8px 0" }} onClick={() => dispatch({ type: "TOGGLE_ADD_CREDENTIALS", data: {} })}>{i18n.t("port:Add credentials")}</Button>
        </Form.Item>
      </EditForm>
    </Fragment>
  );
};
