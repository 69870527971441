import { Button, Popconfirm } from "antd";
import { ColumnsType } from "antd/lib/table";
import { SortOrder } from "antd/lib/table/interface";
import gql from "graphql-tag";
import React from "react";
import i18n from "../../common/i18n";
import { Date } from "../../components/Date";
import { ListContainer } from "../../containers/ListContainer";
import { useMutation, useSearch } from "../../hooks";
import { SignatureCreate } from "./Create";
import * as gd from "./__generated__/DeleteSignature";
import * as g from "./__generated__/SearchSignature";

const SEARCH_SIGNATURE = gql`
  query SearchSignature($input: SearchInput!) {
    searchSignature(input: $input) {
      data {
        id
        raw
        updatedAt
      }
      page
      pageSize
      total
    }
  }
`;

const DELETE_SIGNATURE = gql`
  mutation DeleteSignature($id: ID!) {
    deleteSignature(id: $id)
  }
`;

export const SignatureList = () => {
  const { result, search, updateFilter, updateTable } = useSearch<g.SearchSignature>(SEARCH_SIGNATURE);

  const [deleteSignature] = useMutation<gd.DeleteSignature, gd.DeleteSignatureVariables>(DELETE_SIGNATURE, {
    okText: i18n.t("signature:Signature deleted"),
    refetchQueries: ["SearchSignature"],
  });

  const columns: ColumnsType<g.SearchSignature_searchSignature_data> = [
    {
      dataIndex: "id",
      title: i18n.t("signature:ID"),
      sorter: true,
    },
    {
      dataIndex: "raw",
      title: i18n.t("signature:Raw"),
    },
    {
      dataIndex: "updatedAt",
      title: i18n.t("signature:Updated At"),
      sorter: true,
      render: text => <Date date={text} />,
      defaultSortOrder: "descend" as SortOrder,
    },
    {
      key: "Delete",
      title: i18n.t("signature:Delete"),
      render: (_, record) => {
        return (
          <Popconfirm
            title={i18n.t("signature:Delete signature?")}
            okText={i18n.t("signature:Yes")}
            cancelText={i18n.t("signature:No")}
            onConfirm={() => deleteSignature({ variables: { id: record.id } })}
          >
            <Button htmlType="button">
              {i18n.t("signature:Delete")}
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <ListContainer
      create={<SignatureCreate />}
      search={search}
      columns={columns}
      data={result.data?.searchSignature.data}
      total={result.data?.searchSignature.total}
      loading={result.loading}
      onSearch={updateFilter}
      onTableChange={updateTable}
    />
  );
};
