import { Col, DatePicker, Form, Row, Table, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import gql from "graphql-tag";
import moment from "moment";
import React, { Fragment, useReducer } from "react";
import { Link } from "react-router-dom";
import i18n from "../../common/i18n";
import { Date } from "../../components/Date";
import { useQuery } from "../../hooks/apollo";
import * as g from "./__generated__/SearchPortLog";

const SEARCH_PORT_LOG = gql`
  query SearchPortLog($id: ID!, $input: SearchPortLogInput!) {
    searchPortLog(id: $id, input: $input) {
      data {
        id
        action
        source
        cause {
          id
          name
        }
        createdAt
      }
      page
      pageSize
      total
    }
  }
`;


const { RangePicker } = DatePicker;

const renderCause = (_text: string, record: g.SearchPortLog_searchPortLog_data) => {
  switch (record.source) {
    case "adminDashboard":
      return <Link to={`/admins/${record.cause.id}`}>{record.cause.name}</Link>;
    case "system":
      return "system";
    default:
      return <Link to={`/users/${record.cause.id}`}>{record.cause.name}</Link>;
  }
};

const renderSource = (text: string) => {
  switch (text) {
    case "adminDashboard":
      return <Tag color="blue">admin</Tag>;
    case "userDashboard":
      return <Tag color="cyan">my</Tag>;
    case "userApi":
      return <Tag color="green">api</Tag>;
    default:
      return <Tag color="lime">system</Tag>;
  }
};

const columns: ColumnsType<g.SearchPortLog_searchPortLog_data> = [
  {
    dataIndex: "action",
    title: i18n.t("log:Action"),
  },
  {
    key: "cause",
    title: i18n.t("log:Cause"),
    render: renderCause,
  },
  {
    dataIndex: "source",
    title: i18n.t("log:Source"),
    render: renderSource,
  },
  {
    dataIndex: "createdAt",
    title: i18n.t("log:Created At"),
    render: text => <Date date={text} />,
  },
];

interface PortLogState {
  from: Date,
  to: Date,
  page: number
  pageSize: number
}

const initialState: PortLogState = {
  from: moment().startOf("day").toDate(),
  to: moment().endOf("day").toDate(),
  page: 1,
  pageSize: 20,
};

const portLogReducer = (
  state: PortLogState,
  action: { type: string, data: any }
) => {
  switch (action.type) {
    case "DATES":
      return { ...state, from: action.data[0], to: action.data[1] };
    case "PAGINATE":
      return { ...state, page: action.data.page, pageSize: action.data.pageSize };
    default:
      throw new Error(`undefined action ${action.type}`);
  }
};

export const PortLog = (props: { id: string }) => {
  const [state, dispatch] = useReducer(portLogReducer, initialState);
  const result = useQuery<g.SearchPortLog, g.SearchPortLogVariables>(SEARCH_PORT_LOG, {
    fetchPolicy: "no-cache",
    variables: {
      id: props.id,
      input: state,
    }
  });

  return (
    <Fragment>
      <Form name="control">
        <Row gutter={24}>
          <Col span={12}>
            <RangePicker
              allowClear={false}
              showTime={true}
              value={[moment(state.from), moment(state.to)]}
              onChange={(dates) => dispatch({ type: "DATES", data: dates })}
            />
          </Col>
        </Row>
      </Form>
      <br />
      <Table
        size="middle"
        rowKey={record => record.id}
        loading={result.loading}
        columns={columns}
        dataSource={result.data?.searchPortLog.data}
        pagination={{
          showSizeChanger: true,
          current: state.page,
          pageSize: state.pageSize,
          total: result.data?.searchPortLog.total,
        }}
        onChange={(pagination) => dispatch({ type: "PAGINATE", data: { page: pagination.current, pageSize: pagination.pageSize } })}
      />
    </Fragment>
  );
};
