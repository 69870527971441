import { CheckCircleTwoTone, ExclamationCircleTwoTone } from "@ant-design/icons";
import { Popover } from "antd";
import moment from "moment";
import React, { Fragment } from "react";
import i18n from "../../common/i18n";


interface NodeHeartbeatColumnProps {
  now: string;
  spoofer: string;
  exporter: string;
}

export const NodeHeartbeatColumn = (props: NodeHeartbeatColumnProps) => {
  const [nowTs, spooferTs, exporterTs] = [
    moment(props.now).subtract(1, "minute"),
    moment(props.spoofer),
    moment(props.exporter),
  ];

  const content = (
    <Fragment>
      <p>{i18n.t("node:Synced with spoofer")} {spooferTs.fromNow()}</p>
      <p>{i18n.t("node:Synced with exporter")} {exporterTs.fromNow()}</p>
    </Fragment>
  );

  let icon = <CheckCircleTwoTone twoToneColor="green" />;
  if (spooferTs < nowTs || exporterTs < nowTs) {
    icon = <ExclamationCircleTwoTone twoToneColor="orange" />;
  }

  return (
    <Popover
      title={i18n.t("node:Healthcheck")}
      content={content}
    >
      {icon}
    </Popover>
  );
};
