import { Col, DatePicker, Form, Input, Radio, Row, Table, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import gql from "graphql-tag";
import moment from "moment";
import React, { Fragment, useReducer } from "react";
import { Link } from "react-router-dom";
import i18n from "../../common/i18n";
import { Date } from "../../components/Date";
import { useQuery } from "../../hooks/apollo";
import * as g from "./__generated__/SearchPoolAssignmentLog";

const SEARCH_POOL_ASSIGNMENT_LOG = gql`
  query SearchPoolAssignmentLog($id: ID!, $input: SearchPoolAssignmentLogInput!) {
    searchPoolAssignmentLog(id: $id, input: $input) {
      data {
        __typename
        id
        action
        extra {
          ... on LogPoolAssignmentExtra {
            port
            modem
            ip
          }
        }
        createdAt
      }
      page
      pageSize
      total
    }
  }
`;

const { Search } = Input;
const { RangePicker } = DatePicker;

const renderAction = (action: string) => {
  switch (action) {
    case "assign":
      return <Tag color="blue">assign</Tag>;
    case "release":
      return <Tag color="cyan">release</Tag>;
    default:
      return <Tag color="lime">{i18n.t("user:Unknown")}</Tag>;
  }
};

const columns: ColumnsType<g.SearchPoolAssignmentLog_searchPoolAssignmentLog_data> = [
  {
    key: "port",
    title: i18n.t("log:Port"),
    render: (text, record) => <Link to={`/ports/${(record.extra as g.SearchPoolAssignmentLog_searchPoolAssignmentLog_data_extra_LogPoolAssignmentExtra).port}`}>{(record.extra as g.SearchPoolAssignmentLog_searchPoolAssignmentLog_data_extra_LogPoolAssignmentExtra).port}</Link>,
  },
  {
    key: "modem",
    title: i18n.t("log:Modem"),
    render: (text, record) => <Link to={`/modems/${(record.extra as g.SearchPoolAssignmentLog_searchPoolAssignmentLog_data_extra_LogPoolAssignmentExtra).modem}`}>{(record.extra as g.SearchPoolAssignmentLog_searchPoolAssignmentLog_data_extra_LogPoolAssignmentExtra).modem}</Link>,
  },
  {
    dataIndex: ["extra", "ip"],
    title: i18n.t("log:IP"),
    render: (text) => <Link to={`/ips/${text}`}>{text}</Link>,
  },
  {
    dataIndex: "action",
    title: i18n.t("log:Action"),
    render: renderAction,
  },
  {
    dataIndex: "createdAt",
    title: i18n.t("log:Created At"),
    render: text => <Date date={text} />,
  },
];

interface PoolAssignmentLogState {
  from: Date,
  to: Date,
  filter: string
  action: string
  page: number
  pageSize: number
}

const initialState: PoolAssignmentLogState = {
  from: moment().startOf("day").toDate(),
  to: moment().endOf("day").toDate(),
  filter: "",
  action: "all",
  page: 1,
  pageSize: 20,
};

const poolAssignmentLogReducer = (
  state: PoolAssignmentLogState,
  action: { type: string, data: any }
) => {
  switch (action.type) {
    case "SEARCH":
      return { ...state, filter: action.data };
    case "DATES":
      return { ...state, from: action.data[0], to: action.data[1] };
    case "PAGINATE":
      return { ...state, page: action.data.page, pageSize: action.data.pageSize };
    case "ACTION":
      return { ...state, action: action.data };
    default:
      throw new Error(`undefined action ${action.type}`);
  }
};

export const PoolAssignmentLog = (props: { id: string }) => {
  const [state, dispatch] = useReducer(poolAssignmentLogReducer, initialState);
  const result = useQuery<g.SearchPoolAssignmentLog, g.SearchPoolAssignmentLogVariables>(SEARCH_POOL_ASSIGNMENT_LOG, {
    fetchPolicy: "no-cache",
    variables: {
      id: props.id,
      input: state,
    }
  });

  return (
    <Fragment>
      <Form name="control">
        <Row gutter={24}>
          <Col span={9}>
            <Form.Item name="search">
              <Search
                allowClear={true}
                placeholder={i18n.t("user:Search...")}
                onSearch={(text) => dispatch({ type: "SEARCH", data: text })}
              />
            </Form.Item>
          </Col>
          <Col span={9}>
            <RangePicker
              allowClear={false}
              showTime={true}
              value={[moment(state.from), moment(state.to)]}
              onChange={(dates) => dispatch({ type: "DATES", data: dates })}
            />
          </Col>
          <Col span={6}>
            <Radio.Group onChange={(e) => dispatch({ type: "ACTION", data: e.target.value })} value={state.action}>
              <Radio.Button value="all">All</Radio.Button>
              <Radio.Button value="assign">Assign</Radio.Button>
              <Radio.Button value="release">Release</Radio.Button>
            </Radio.Group>
          </Col>
        </Row>
      </Form>
      <Table
        size="middle"
        rowKey={record => record.id}
        loading={result.loading}
        columns={columns}
        dataSource={result.data?.searchPoolAssignmentLog.data}
        pagination={{
          showSizeChanger: true,
          current: state.page,
          pageSize: state.pageSize,
          total: result.data?.searchPoolAssignmentLog.total,
        }}
        onChange={(pagination) => dispatch({ type: "PAGINATE", data: { page: pagination.current, pageSize: pagination.pageSize } })}
      />
    </Fragment>
  );
};
