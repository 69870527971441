import { InputNumber, Select, Typography } from "antd";
import React, { useState } from "react";

type Unit = "h" | "m"

const unitMultipliers = {
  "h": 3600,
  "m": 60,
};

// https://github.com/ant-design/ant-design/issues/31431
const unitPrecision = {
  "h": 0,
  "m": 1,
};

const intervalParser = (unit: Unit) => (value: string | undefined) => {
  if (value === "Disabled") {
    return 0;
  }

  const numValue = parseInt(value || "", 10);
  return Number.isNaN(numValue) ? 0 : unitMultipliers[unit] * numValue;
};

const intervalFormatter = (unit: Unit) => (value: number | undefined, info: { userTyping: boolean, input: string }) => {
  if (!value) {
    return "Disabled";
  }

  if (value <= 0) {
    return "Disabled";
  }

  return (value / unitMultipliers[unit]).toFixed(0);
};

export interface InputAutoResetIntervalProps {
  value?: number;
  onChange?: (value: number) => void;
}

export const InputAutoResetInterval = (props: InputAutoResetIntervalProps) => {
  const [unit, setUnit] = useState<Unit>("m");

  const autoResetDisabler = (
    <Typography.Text
      style={{ cursor: "pointer" }}
      onClick={() => props.onChange && props.onChange(0)}
    >
      x
    </Typography.Text>
  );

  const unitSelector = (
    <Select
      value={unit}
      onChange={setUnit}
    >
      <Select.Option value="m">Min</Select.Option>
    </Select>
  );

  return (
    <InputNumber
      addonAfter={unitSelector}
      addonBefore={autoResetDisabler}
      parser={intervalParser(unit)}
      formatter={intervalFormatter(unit)}
      precision={unitPrecision[unit]}
      step={unitMultipliers[unit]}
      value={props.value}
      onChange={props.onChange}
      min={0}
      max={86400}
    />
  );
};
