import { Card, Col, Collapse, Row, Spin, Statistic } from "antd";
import gql from "graphql-tag";
import React, { Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "../../hooks";
import { PoolModemsUsage } from "./ModemsUsage";
import * as g from "./__generated__/PoolSummary";

const GET_POOL_SUMMARY = gql`
  query PoolSummary($id: ID!) {
    pool(id: $id) {
      id
      name
      modems
      portsCount
      summary {
        available {
          id
          comment
        }
        assigned {
          id
          comment
        }
        unavailable {
          id
          comment
        }
        availableCount
        assignedCount
        unavailableCount
      }
    }
  }
`;

export const PoolSummary = () => {
  const { id } = useParams<{ id: string }>();

  const { loading, data } = useQuery<g.PoolSummary, g.PoolSummaryVariables>(GET_POOL_SUMMARY, {
    variables: { id },
  });

  if (loading) {
    return <Spin />;
  }

  return (
    <Fragment>
      <Card title="Summary">
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Statistic
              title="Usage"
              value={`${Math.round(data!.pool.portsCount * 100 / data!.pool.modems.length)}%`}
            />
          </Col>

          <Col span={8}>
            <Statistic
              title="Modems Total"
              value={data?.pool.modems.length}
            />
          </Col>

          <Col span={8}>
            <Statistic
              title="Ports Total"
              value={data?.pool.portsCount}
            />
          </Col>
        </Row>
        <br />
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Statistic
              title="Modems Assigned"
              value={data?.pool.summary.assignedCount}
            />

            <Collapse bordered={false} ghost={true}>
              <Collapse.Panel header="Show" key="1">
                {data?.pool.summary.assigned.map(modem => <p key={modem.id}><Link to={`/modems/${modem.id}`}>{modem.id}</Link>{modem.comment !== "" && ` (${modem.comment})`}</p>)}
              </Collapse.Panel>
            </Collapse>
          </Col>

          <Col span={8}>
            <Statistic
              title="Modems Available"
              value={data?.pool.summary.availableCount}
            />

            <Collapse bordered={false} ghost={true}>
              <Collapse.Panel header="Show" key="1">
                {data?.pool.summary.available.map(modem => <p key={modem.id}><Link to={`/modems/${modem.id}`}>{modem.id}</Link>{modem.comment !== "" && ` (${modem.comment})`}</p>)}
              </Collapse.Panel>
            </Collapse>
          </Col>

          <Col span={8}>
            <Statistic
              title="Modems Unavailable"
              value={data?.pool.summary.unavailableCount}
            />

            <Collapse bordered={false} ghost={true}>
              <Collapse.Panel header="Show" key="1">
                {data?.pool.summary.unavailable.map(modem => <p key={modem.id}><Link to={`/modems/${modem.id}`}>{modem.id}</Link>{modem.comment !== "" && ` (${modem.comment})`}</p>)}
              </Collapse.Panel>
            </Collapse>
          </Col>
        </Row>
      </Card>

      <br />

      <Card title="Modems Usage">
        <PoolModemsUsage id={id} />
      </Card>
    </Fragment>
  );
};
