import { ColumnsType } from "antd/lib/table";
import { SortOrder } from "antd/lib/table/interface";
import gql from "graphql-tag";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import i18n from "../../common/i18n";
import { Date } from "../../components/Date";
import { ListContainer } from "../../containers/ListContainer";
import { useSearch } from "../../hooks";
import * as g from "./__generated__/SearchPortBilling";

const SEARCH_PORT_BILLING = gql`
  query SearchPortBilling($input: SearchInput!) {
    searchPortBilling(input: $input) {
      data {
        id
        user {
          id
          login
          group
        }
        plan {
          id
          name
          tarification {
            time
            traffic
            price
          }
          expiresAt
          trafficRemains
        }
        createdAt
        updatedAt
      }
      page
      pageSize
      total
    }
  }
`;

const columns: ColumnsType<g.SearchPortBilling_searchPortBilling_data> = [
  {
    dataIndex: "id",
    title: i18n.t("port:ID"),
    sorter: true,
    render: (text, record) => <Link to={`/ports/${record.id}`}>{text}</Link>,
  },
  {
    dataIndex: ["user", "login"],
    title: i18n.t("port:User"),
    render: (text, record) => <Link to={`/users/${record.user.id}`}>{text}</Link>
  },
  {
    dataIndex: ["plan", "name"],
    title: i18n.t("port:Plan"),
    sorter: true,
  },
  {
    dataIndex: ["plan", "tarification", "time"],
    title: i18n.t("port:Option (days)"),
    sorter: true,
    render: (text) => parseInt(text, 10) / 86400,
  },
  {
    key: "left",
    title: i18n.t("port:Days left"),
    render: (_, record) => moment(record.plan.expiresAt).diff(moment(), "days"),
  },
  {
    dataIndex: ["plan", "expiresAt"],
    title: i18n.t("port:Expires At"),
    sorter: true,
    render: text => <Date date={text} />,
  },
  {
    dataIndex: "updatedAt",
    title: i18n.t("port:Updated At"),
    sorter: true,
    render: text => <Date date={text} />,
    defaultSortOrder: "descend" as SortOrder,
  },
];

export const PortBilling = () => {
  const { result, search, updateFilter, updateTable } = useSearch<g.SearchPortBilling>(SEARCH_PORT_BILLING);

  return (
    <ListContainer
      create={<span></span>}
      search={search}
      columns={columns}
      data={result.data?.searchPortBilling.data}
      total={result.data?.searchPortBilling.total}
      loading={result.loading}
      onSearch={updateFilter}
      onTableChange={updateTable}
    />
  );
};
