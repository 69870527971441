import { Button, Form, Input, Row } from "antd";
import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { authenticationService } from "../../common/auth";
import i18n from "../../common/i18n";
import styles from "./styles.module.less";

export const Login = () => {
  const history = useHistory();
  const [failed, setFailed] = useState(false);

  const handleOk = (values: any) => {
    authenticationService.login(values.login, values.password)
      .then(() => history.push("/users"))
      .catch(() => setFailed(true));
  };

  return (
    <Fragment>
      <div className={styles.form}>
        <div className={styles.logo}>
          <img alt="logo" src="/logo192.png" />
          <span>{i18n.t("login:Dashboard")}</span>
        </div>

        <Form onFinish={handleOk}>
          {failed && <p>{i18n.t("login:Login failed")}</p>}

          <Form.Item
            name="login"
            rules={[{ required: true }]}
          >
            <Input placeholder={i18n.t("login:Login")} />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true }]}
          >
            <Input type="password" placeholder={i18n.t("Password")} />
          </Form.Item>

          <Row>
            <Button type="primary" htmlType="submit" loading={false} >
              {i18n.t("login:Sign In")}
            </Button>
          </Row>
        </Form>
      </div>
    </Fragment>
  );
};
