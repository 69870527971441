import { ColumnsType } from "antd/lib/table";
import { SortOrder } from "antd/lib/table/interface";
import gql from "graphql-tag";
import React from "react";
import { Link } from "react-router-dom";
import { formatCurrency } from "../../common/formatter";
import i18n from "../../common/i18n";
import { Date } from "../../components/Date";
import { ListContainer } from "../../containers/ListContainer";
import { useSearch } from "../../hooks";
import { OrderInfo } from "./OrderInfo";
import * as g from "./__generated__/SearchOrder";

const SEARCH_ORDER = gql`
  query SearchOrder($input: SearchInput!) {
    searchOrder(input: $input) {
      data {
        id
        status
        gateway
        user
        login
        email
        amount
        amountPaid
        currency
        exchangeRate
        description
        createdAt
        updatedAt
      }
      page
      pageSize
      total
    }
  }
`;

const columns: ColumnsType<g.SearchOrder_searchOrder_data> = [
  {
    dataIndex: "id",
    title: i18n.t("finance:ID"),
    sorter: true,
  },
  {
    dataIndex: "status",
    title: i18n.t("finance:Status"),
  },
  {
    dataIndex: "gateway",
    title: i18n.t("finance:Gateway"),
  },
  {
    dataIndex: "login",
    title: i18n.t("finance:User"),
    render: (_, record) => <Link to={`/users/${record.user}`}>{record.login === "" ? record.email : record.login}</Link>
  },
  {
    dataIndex: "amount",
    title: i18n.t("finance:Amount"),
    render: (_, record) => formatCurrency(record.amount / 100),
  },
  {
    dataIndex: "currency",
    title: i18n.t("finance:Currency"),
  },
  {
    key: "timeline",
    title: "Timeline",
    render: (record) => <OrderInfo id={record.id} />,
  },
  {
    dataIndex: "createdAt",
    title: i18n.t("port:Created At"),
    render: text => <Date date={text} />,
  },
  {
    dataIndex: "updatedAt",
    title: i18n.t("port:Updated At"),
    sorter: true,
    render: text => <Date date={text} />,
    defaultSortOrder: "descend" as SortOrder,
  },
];

export const OrderList = () => {
  const { result, search, updateFilter, updateTable } = useSearch<g.SearchOrder>(SEARCH_ORDER);

  return (
    <ListContainer
      create={<span></span>}
      search={search}
      columns={columns}
      data={result.data?.searchOrder.data}
      total={result.data?.searchOrder.total}
      loading={result.loading}
      onSearch={updateFilter}
      onTableChange={updateTable}
    />
  );
};
