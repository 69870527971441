import { Card, Form, Input, Switch } from "antd";
import gql from "graphql-tag";
import React, { Fragment } from "react";
import { useHistory, useParams } from "react-router-dom";
import i18n from "../../common/i18n";
import { EditForm } from "../../containers/EditForm";
import { useMutation, useQuery } from "../../hooks";
import { BannerPreview } from "./Preview";
import * as g from "./__generated__/Banner";
import * as gd from "./__generated__/DeleteBanner";
import * as gu from "./__generated__/UpdateBanner";

const GET_BANNER = gql`
  query Banner($id: ID!) {
    banner(id: $id) {
      id
      name
      active
      content {
        text
        html
        language
      }
      createdAt
      updatedAt
    }
  }
`;

const UPDATE_BANNER = gql`
  mutation UpdateBanner($id: ID!, $input: BannerUpdateInput!) {
    updateBanner(id: $id, input: $input) {
      id
      name
      active
      content {
        text
        html
        language
      }
      createdAt
      updatedAt
    }
  }
`;

const DELETE_BANNER = gql`
  mutation DeleteBanner($id: ID!) {
    deleteBanner(id: $id)
  }
`;

interface BannerEditFields {
  name: string;
  active: boolean;
  enText: string;
  ruText: string;
}

const bannerEditMap = (banner?: g.Banner_banner) => {
  if (!banner) {
    return {
      name: "",
      active: false,
      enText: "",
      ruText: "",
    };
  }

  return {
    name: banner.name,
    active: banner.active,
    enText: banner.content.find(c => c.language === "en")?.text || "",
    ruText: banner.content.find(c => c.language === "ru")?.text || "",
  };
};

export const BannerEdit = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm<BannerEditFields>();

  const { loading, data } = useQuery<g.Banner, g.BannerVariables>(GET_BANNER, {
    variables: { id },
  });

  const [updateBanner] = useMutation<gu.UpdateBanner, gu.UpdateBannerVariables>(UPDATE_BANNER, {
    okText: i18n.t("banner:Banner updated"),
    refetchQueries: ["Banner"],
  });

  const [deleteBanner] = useMutation<gd.DeleteBanner, gd.DeleteBannerVariables>(DELETE_BANNER, {
    okText: i18n.t("user:Banner deleted"),
    onCompleted: (_) => {
      history.push("/banners");
    }
  });

  return (
    <Fragment>
      <Card
        title={data?.banner.name}
        bordered={false}
        headStyle={{ padding: "0" }}
        bodyStyle={{ padding: "24px 0" }}
      >
        <EditForm
          name="edit-banner"
          deleteTitle={i18n.t("user:Delete banner?")}
          loading={loading}
          form={form}
          initialValues={bannerEditMap(data?.banner)}
          onSave={(values) => updateBanner({ variables: { id, input: values } })}
          onDelete={() => deleteBanner({ variables: { id } })}
        >
          <Form.Item
            name="name"
            label={i18n.t("banner:Name")}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="active"
            label="Active"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            name="preview"
            label="Preview"
          >
            <BannerPreview content={data?.banner.content} />
          </Form.Item>
          <Form.Item
            name="enText"
            label="English"
          >
            <Input.TextArea rows={6} placeholder="Some text" />
          </Form.Item>
          <Form.Item
            name="ruText"
            label="Russian"
          >
            <Input.TextArea rows={6} placeholder="Some text" />
          </Form.Item>
        </EditForm>
        <br />
        <ul>
          <li className="has-line-data" data-line-start="0" data-line-end="1"><code>[b]text[/b]</code></li>
          <li className="has-line-data" data-line-start="1" data-line-end="2"><code>[url]link[/url]</code></li>
          <li className="has-line-data" data-line-start="2" data-line-end="3"><code>[url=link]text[/url]</code></li>
          <li className="has-line-data" data-line-start="3" data-line-end="4"><code>[img]link[/img]</code></li>
          <li className="has-line-data" data-line-start="4" data-line-end="5"><code>[img=link]alt[/img]</code></li>
          <li className="has-line-data" data-line-start="5" data-line-end="6"><code>[center]text[/center]</code></li>
          <li className="has-line-data" data-line-start="6" data-line-end="7"><code>[color=red]text[/color]</code></li>
          <li className="has-line-data" data-line-start="7" data-line-end="8"><code>[size=2]text[/size]</code></li>
        </ul>
      </Card>
    </Fragment>
  );
};
