import { Button, Card, Checkbox, Col, Form, Input, Row, Select, Spin, Transfer } from "antd";
import gql from "graphql-tag";
import React, { Fragment, useReducer } from "react";
import { useHistory, useParams } from "react-router-dom";
import i18n from "../../common/i18n";
import { EditForm } from "../../containers/EditForm";
import { useMutation, useQuery } from "../../hooks";
import * as gd from "./__generated__/DeletePool";
import * as g from "./__generated__/Pool";
import * as gs from "./__generated__/SearchModemPool";
import * as gu from "./__generated__/UpdatePool";

const GET_POOL_SETTINGS = gql`
  query PoolSettings($id: ID!) {
    pool(id: $id) {
      id
      kind
      name
      modems
      releaseByInactivitySettings {
        enabled
        period
        trafficLimit
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

const UPDATE_POOL = gql`
  mutation UpdatePool($id: ID!, $input: PoolUpdateInput!) {
    updatePool(id: $id, input: $input) {
      id
      kind
      name
      modems
      releaseByInactivitySettings {
        enabled
        period
        trafficLimit
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

const DELETE_POOL = gql`
  mutation DeletePool($id: ID!) {
    deletePool(id: $id)
  }
`;

const SEARCH_MODEM = gql`
  query SearchModemPool($input: SearchInput!) {
    searchModem(input: $input) {
      data {
        id
      }
      page
      pageSize
      total
    }
  }
`;

interface IModemPoolEditState {
  filter: string,
  allModems: string[],
  selectedModems: string[]
}

const initialState: IModemPoolEditState = {
  filter: "",
  allModems: [],
  selectedModems: [],
};

const modemPoolEditStateReducer = (
  state: IModemPoolEditState,
  action: { type: string, data: any }
) => {
  switch (action.type) {
    case "INIT":
      return { ...state, selectedModems: action.data.modems };
    case "INIT_ALL_MODEMS":
      return { ...state, allModems: action.data };
    case "UPDATE_SELECTED":
      return { ...state, selectedModems: action.data };
    default:
      throw new Error(`undefined action ${action.type}`);
  }
};

export const PoolSettings = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();

  const [state, dispatch] = useReducer(modemPoolEditStateReducer, initialState);

  const { loading, data } = useQuery<g.Pool, g.PoolVariables>(GET_POOL_SETTINGS, {
    variables: { id },
    onCompleted: (data) => {
      dispatch({ type: "INIT", data: { modems: data.pool.modems } });
    }
  });

  const [updatePool] = useMutation<gu.UpdatePool, gu.UpdatePoolVariables>(UPDATE_POOL, {
    okText: i18n.t("pool:Pool updated"),
    refetchQueries: ["Pool"],
  });

  const [deletePool] = useMutation<gd.DeletePool, gd.DeletePoolVariables>(DELETE_POOL, {
    okText: i18n.t("pool:Pool deleted"),
    onCompleted: (_) => {
      history.push("/pools");
    }
  });

  useQuery<gs.SearchModemPool, gs.SearchModemPoolVariables>(SEARCH_MODEM, {
    variables: {
      input: {
        filter: "",
        sorter: "id_descending",
        page: 1,
        pageSize: 0,
      }

    },
    onCompleted: (data) => {
      dispatch({ type: "INIT_ALL_MODEMS", data: data.searchModem.data.map(x => x.id) });
    }
  });

  if (loading) {
    return <Spin />;
  }

  return (
    <Fragment>
      <Card title="Settings">
        <EditForm
          name="edit-pool"
          deleteTitle={i18n.t("pool:Delete pool?")}
          loading={loading}
          form={form}
          initialValues={data?.pool}
          onSave={(values) => updatePool({ variables: { id, input: {} } })}
          onDelete={() => deletePool({ variables: { id } })}
        >
          <Form.Item
            name="name"
            label={i18n.t("pool:Name")}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="kind"
            label={i18n.t("pool:Type")}
          >
            <Select style={{ width: 160 }}>
              <Select.Option value="fixed">{i18n.t("pool:Fixed")}</Select.Option>
              <Select.Option value="rotated">{i18n.t("pool:Rotated")}</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name={["releaseByInactivitySettings", "enabled"]}
            valuePropName="checked"
            label={i18n.t("pool:Release by inactivity")}
          >
            <Checkbox />
          </Form.Item>

          <Form.Item
            name={["releaseByInactivitySettings", "period"]}
            label={i18n.t("pool:Inactivity period (s)")}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name={["releaseByInactivitySettings", "trafficLimit"]}
            label={i18n.t("pool:Inactivity traffic limit (B)")}
          >
            <Input />
          </Form.Item>
        </EditForm>
      </Card>

      <br />

      <Card title="Modems">
        <Row justify="space-around">
          <Col>
            <Transfer
              dataSource={state.allModems.map((x: any) => { return { key: x, title: x }; })}
              showSearch
              filterOption={(inputValue, option) => option.key!.indexOf(inputValue) > -1}
              listStyle={{
                width: 300,
                height: 400,
              }}
              operations={[i18n.t("pool:Add"), i18n.t("pool:Remove")]}
              targetKeys={state.selectedModems}
              render={item => item.title || i18n.t("pool:Empty")}
              onChange={(targetKeys, _dir, _moveKeys) => dispatch({ type: "UPDATE_SELECTED", data: targetKeys })}
              pagination={true}
            />

            <br />

            <Button
              type="primary"
              onClick={() => updatePool({ variables: { id, input: { modems: state.selectedModems } } })}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Card>
    </Fragment >
  );
};
