import { SearchOutlined } from "@ant-design/icons";
import { Button, Card, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import { SortOrder } from "antd/lib/table/interface";
import gql from "graphql-tag";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import i18n from "../../common/i18n";
import { Date } from "../../components/Date";
import { ListContainer } from "../../containers/ListContainer";
import { useSearch } from "../../hooks";
import { NodeCreate } from "./Create";
import { NodeHeartbeatColumn } from "./Heartbeat";
import * as g from "./__generated__/SearchNode";

const SEARCH_NODE = gql`
query SearchNode($input: SearchInput!) {
  searchNode(input: $input) {
    data {
      id
      ipLastOctet
      heartbeat {
        now
        spoofer
        exporter
      }
      modemSummary {
        online
        offline
        reset
        disconnected
      }
      createdAt
      updatedAt
    }
    page
    pageSize
    total
  }
}
`;

interface ModemSummaryProps {
  nodeID: string;
  online: number;
  offline: number;
  reset: number;
  disconnected: number;
}

const ModemSummary = (props: ModemSummaryProps) => {
  return (
    <Link to={`/modems/list?filter=${props.nodeID}`}>
      <Button icon={<SearchOutlined />} size="small">
        {[props.online, props.offline, props.reset, props.disconnected].join(" / ")}
      </Button>
    </Link>
  );
};

const columns: ColumnsType<g.SearchNode_searchNode_data> = [
  {
    dataIndex: "id",
    title: i18n.t("node:ID"),
    sorter: true,
    render: (text, record) => <Link to={`/nodes/${record.id}`}>{text}</Link>,
  },
  {
    dataIndex: "ipLastOctet",
    title: i18n.t("node:IP"),
    sorter: true,
  },
  {
    key: "status",
    title: i18n.t("node:Status"),
    render: (_, record) => <NodeHeartbeatColumn {...record.heartbeat} />,
  },
  {
    key: "modemSummary",
    title: <Tooltip title={`${i18n.t("node:Online")} / ${i18n.t("node:Offline")} / ${i18n.t("node:Reset")} / ${i18n.t("node:Disconnected")}`}>
      {i18n.t("node:Modems")}
    </Tooltip>,
    render: (_, record) => <ModemSummary nodeID={record.id} {...record.modemSummary} />
  },
  {
    dataIndex: "createdAt",
    title: i18n.t("node:Created At"),
    sorter: true,
    render: text => <Date date={text} />,
  },
  {
    dataIndex: "updatedAt",
    title: i18n.t("node:Updated At"),
    sorter: true,
    render: text => <Date date={text} />,
    defaultSortOrder: "descend" as SortOrder,
  },
];

export const NodeList = () => {
  const { t } = useTranslation("node");

  const { result, search, updateFilter, updateTable } = useSearch<g.SearchNode>(SEARCH_NODE);

  return (
    <Card title={t("Node list")} bordered={false} headStyle={{ padding: "0" }} bodyStyle={{ padding: "24px 0" }}>
      <ListContainer
        create={<NodeCreate />}
        search={search}
        columns={columns}
        data={result.data?.searchNode.data}
        total={result.data?.searchNode.total}
        loading={result.loading}
        onSearch={updateFilter}
        onTableChange={updateTable}
      />
    </Card>
  );
};
