import { Form, Input } from "antd";
import gql from "graphql-tag";
import React, { useState } from "react";
import i18n from "../../common/i18n";
import { CreateForm } from "../../containers/CreateForm";
import { useMutation } from "../../hooks";
import * as g from "./__generated__/CreateBanner";

const CREATE_BANNER = gql`
  mutation CreateBanner($name: String!) {
    createBanner(name: $name) {
      id
    }
  }
`;

export const BannerCreate = () => {
  const [form] = Form.useForm<g.CreateBannerVariables>();
  const [isOpen, toggle] = useState(false);

  const [createBanner] = useMutation<{
    createBanner: g.CreateBanner,
    banner: g.CreateBanner_createBanner,
  }>(CREATE_BANNER, {
    okText: i18n.t("banner:Banner created"),
    refetchQueries: ["SearchBanner"],
    onCompleted: (_) => {
      form.resetFields();
      toggle(false);
    },
  });

  return (
    <CreateForm
      name="create-banner"
      title={i18n.t("banner:Create banner")}
      form={form}
      isOpen={isOpen}
      toggleOpen={() => toggle(!isOpen)}
      onCreate={(values) => createBanner({ variables: values })}
    >
      <Form.Item
        name="name"
        label={i18n.t("banner:Name")}
        rules={[{ required: true }]}
      >
        <Input placeholder="Banner 1" />
      </Form.Item>
    </CreateForm>
  );
};
