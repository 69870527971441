import { Form, Input } from "antd";
import gql from "graphql-tag";
import React, { useState } from "react";
import i18n from "../../common/i18n";
import { CreateForm } from "../../containers/CreateForm";
import { useMutation } from "../../hooks";
import * as g from "./__generated__/CreatePool";

const CREATE_POOL = gql`
  mutation CreatePool($name: String!) {
    createPool(name: $name) {
      id
      name
      assignments {
        port
        modem
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const PoolCreate = () => {
  const [form] = Form.useForm<g.CreatePoolVariables>();
  const [isOpen, toggle] = useState(false);

  const [createPool] = useMutation<{
    createPool: g.CreatePool,
    pool: g.CreatePool_createPool,
  }>(CREATE_POOL, {
    okText: i18n.t("pool:Pool created"),
    refetchQueries: ["SearchPool"],
    onCompleted: (_) => {
      form.resetFields();
      toggle(false);
    },
  });

  return (
    <CreateForm
      name="create-pool"
      title={i18n.t("pool:Create pool")}
      form={form}
      isOpen={isOpen}
      toggleOpen={() => toggle(!isOpen)}
      onCreate={(values) => createPool({ variables: values })}
    >
      <Form.Item
        name="name"
        label={i18n.t("pool:Name")}
        rules={[{ required: true }]}
      >
        <Input placeholder="POOL_UK_01" />
      </Form.Item>
    </CreateForm>
  );
};
