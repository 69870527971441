import { Form, Input } from "antd";
import gql from "graphql-tag";
import React, { useState } from "react";
import i18n from "../../common/i18n";
import { CreateForm } from "../../containers/CreateForm";
import { useMutation } from "../../hooks";
import * as g from "./__generated__/CreateUser";

const CREATE_USER = gql`
  mutation CreateUser($login: String!, $password: String!) {
    createUser(login: $login, password: $password) {
      id
      login
      group
      status
      apiToken
      createdAt
      updatedAt
    }
  }
`;

export const UserCreate = () => {
  const [form] = Form.useForm<g.CreateUserVariables>();
  const [isOpen, toggle] = useState(false);

  const [createUser] = useMutation<{
    createUser: g.CreateUser,
    user: g.CreateUser_createUser,
  }>(CREATE_USER, {
    okText: i18n.t("user:User created"),
    refetchQueries: ["SearchUser"],
    onCompleted: (_) => {
      form.resetFields();
      toggle(false);
    },
  });

  return (
    <CreateForm
      name="create-user"
      title={i18n.t("user:Create user")}
      form={form}
      isOpen={isOpen}
      toggleOpen={() => toggle(!isOpen)}
      onCreate={(values) => createUser({ variables: values })}
    >
      <Form.Item
        name="login"
        label={i18n.t("user:Login")}
        rules={[{ required: true }]}
      >
        <Input placeholder="user" />
      </Form.Item>
      <Form.Item
        name="password"
        label={i18n.t("user:Password")}
        rules={[{ required: true }]}
      >
        <Input placeholder="password" />
      </Form.Item>
    </CreateForm>
  );
};
