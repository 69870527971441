import { RightCircleTwoTone } from "@ant-design/icons";
import { DatePicker, Radio, Space, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import gql from "graphql-tag";
import moment from "moment";
import React, { Fragment, useReducer } from "react";
import { Link } from "react-router-dom";
import { formatCurrency, formatDate } from "../../common/formatter";
import { useQuery } from "../../hooks/apollo";
import { OrderInfo } from "./OrderInfo";
import * as g from "./__generated__/TopUpSummary";

const TOP_UP_SUMMARY = gql`
  query TopUpSummary($input: TopUpSummaryInput!) {
    topUpSummary(input: $input) {
      records {
        name
        value
        source
      }
      buckets {
        name
        payments {
          user
          userLogin
          source
          sourceName
          date
          amount
          description
        }
      }
    }
  }
`;

// const sourceOptions = [
//   { label: "Manual", value: "manual" },
//   { label: "Credit Card", value: "cc" },
// ];

const renderDescription = (text: string) => {
  const orderID = text.match(/Order ([0-9a-fA-F]{24})/);
  if (orderID) {
    return (
      <Space>
        {text}
        <OrderInfo id={orderID[1]} />
      </Space>
    );
  }

  return text;
};

const detailsColumns: ColumnsType<g.TopUpSummary_topUpSummary_buckets_payments> = [
  {
    key: "date",
    title: "Date",
    render: (record) => formatDate(record.date),
  },
  {
    key: "user",
    title: "User",
    render: (record) => record.user === "" ? record.userLogin : <Link to={`/users/${record.user}`}>{record.userLogin}</Link>,
  },
  {
    key: "source",
    title: "Source",
    render: (record) => record.sourceName === "system" ? record.sourceName : <Link to={`/admins/${record.source}`}>{record.sourceName}</Link>,
  },
  {
    key: "description",
    title: "Description",
    render: (record) => renderDescription(record.description),
  },
  {
    key: "amount",
    title: "Amount",
    align: "right",
    render: (record) => <Typography.Text type="success">{formatCurrency(record.amount / 100)}</Typography.Text>,
  },
];

interface TopUpState {
  from: Date
  to: Date
  period: string
  bucket: string
}

const initialState: TopUpState = {
  from: moment().utc().startOf("week").toDate(),
  to: moment().utc().endOf("week").toDate(),
  period: "day",
  bucket: "",
};

const topUpReducer = (
  state: TopUpState,
  action: { type: string, data: any }
) => {
  switch (action.type) {
    case "DATES":
      return { ...state, from: action.data[0], to: action.data[1] };
    case "PERIOD":
      var from = moment().utc().startOf("week").toDate();
      var to = moment().utc().endOf("week").toDate();

      if (action.data === "week") {
        from = moment().utc().startOf("month").startOf("week").toDate();
        to = moment().utc().endOf("month").endOf("week").toDate();
      }

      if (action.data === "month") {
        from = moment().utc().startOf("quarter").toDate();
        to = moment().utc().endOf("quarter").toDate();
      }

      return { ...state, period: action.data, from: from, to: to };
    case "SOURCES":
      return { ...state, sources: action.data };
    case "BUCKET":
      return { ...state, bucket: action.data };
    default:
      throw new Error(`undefined action ${action.type}`);
  }
};

export const TopUp = () => {
  const [state, dispatch] = useReducer(topUpReducer, initialState);
  const result = useQuery<g.TopUpSummary, g.TopUpSummaryVariables>(TOP_UP_SUMMARY, {
    fetchPolicy: "no-cache",
    variables: {
      input: {
        from: state.from,
        to: state.to,
        period: state.period,
      }
    },
    onCompleted: (data) => {
      dispatch({ type: "BUCKET", data: data.topUpSummary.buckets[0].name });
    }
  });

  const columns = [
    {
      dataIndex: "source",
      title: "Source",
    },
    ...result.data?.topUpSummary.records.
      filter((x, i, arr) => arr.findIndex(t => t.name === x.name) === i).
      map(x => {
        return {
          key: x.name, title: x.name, render: (record: any) =>
            <Space>
              {formatCurrency(record[x.name])}
              <RightCircleTwoTone twoToneColor={state.bucket === `${x.name}-${record.key}` ? "#52c41a" : ""} onClick={() => dispatch({ type: "BUCKET", data: `${x.name}-${record.key}` })} />
            </Space>
        };
      }) || []
  ];

  const data = result.data?.topUpSummary.records.reduce((acc, x) => {
    var row = acc.find((t: any) => t.key === x.source);
    row[x.name] = x.value / 100;

    return acc;
  }, [
    { key: "manual", source: "Manual" },
    { key: "wfp", source: "WayForPay" },
    { key: "anymoney", source: "AnyMoney" },
    { key: "binance", source: "Binance Pay" },
    { key: "whitepay", source: "Whitepay" },
    { key: "stripe", source: "Stripe" },
    { key: "ipay", source: "iPay" },
    { key: "pspark", source: "PSPark" },
  ] as any);

  const detailsData = result.data?.topUpSummary.buckets.find(x => x.name === state.bucket)?.payments || [];

  return (
    <Fragment>
      <Space size="large">
        {state.period === "day" &&
          <DatePicker.RangePicker
            allowClear={false}
            value={[moment.utc(state.from), moment.utc(state.to)]}
            onChange={(dates) => dispatch({ type: "DATES", data: [moment.utc(dates![0]), moment.utc(dates![1])] })}
          />
        }
        {state.period === "week" &&
          <DatePicker.RangePicker
            picker="week"
            allowClear={false}
            format="YYYY-Wo"
            value={[moment.utc(state.from), moment.utc(state.to)]}
            onChange={(dates) => dispatch({ type: "DATES", data: [moment.utc(dates![0]).startOf("week"), moment.utc(dates![1]).endOf("week")] })}
          />
        }
        {state.period === "month" &&
          <DatePicker.RangePicker
            picker="month"
            allowClear={false}
            value={[moment.utc(state.from), moment.utc(state.to)]}
            onChange={(dates) => dispatch({ type: "DATES", data: [moment.utc(dates![0]).startOf("month"), moment.utc(dates![1]).endOf("month")] })}
          />
        }
        <Radio.Group
          buttonStyle="solid"
          value={state.period}
          onChange={(period) => dispatch({ type: "PERIOD", data: period.target.value })}
        >
          <Radio.Button value="day">Day</Radio.Button>
          <Radio.Button value="week">Week</Radio.Button>
          <Radio.Button value="month">Month</Radio.Button>
        </Radio.Group>
      </Space>
      <br /><br />
      <Table
        size="middle"
        scroll={{ x: "max-content" }}
        rowKey={record => record.source}
        loading={result.loading}
        columns={columns}
        dataSource={data}
        pagination={false}
      />
      <br /><br />
      <Table
        size="middle"
        rowKey={record => record.date}
        loading={result.loading}
        pagination={false}
        columns={detailsColumns}
        dataSource={detailsData}
      />
    </Fragment>
  );
};
