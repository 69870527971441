import { Col, DatePicker, Input, Row, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import gql from "graphql-tag";
import moment from "moment";
import React, { Fragment, useReducer } from "react";
import { Link } from "react-router-dom";
import { formatDate } from "../../common/formatter";
import { useQuery } from "../../hooks/apollo";
import * as g from "./__generated__/SearchRouterAccessLog";

const SEARCH_ACCESS_LOG = gql`
  query SearchRouterAccessLog($input: SearchRouterAccessLogInput!) {
    searchRouterAccessLog(input: $input) {
      ts
      host
      port
      user
      sourceIp
      targetIp
      targetHost
      targetPort
    }
  }
`;

const columns: ColumnsType<g.SearchRouterAccessLog_searchRouterAccessLog> = [
  {
    key: "date",
    title: "Date",
    render: (_, record) => formatDate(record.ts),
  },
  {
    dataIndex: "host",
    title: "Router",
  },
  {
    key: "port",
    title: "Port",
    render: (_, record) => <Link to={`/ports/${record.port}`}>{record.port}</Link>,
  },
  {
    dataIndex: "user",
    title: "User",
  },
  {
    dataIndex: "sourceIp",
    title: "Source IP",
  },
  {
    dataIndex: "targetIp",
    title: "Target IP",
  },
  {
    dataIndex: "targetHost",
    title: "Target Host",
  },
  {
    dataIndex: "targetPort",
    title: "Target Port",
  },
];

interface accessLogState {
  from: Date
  to: Date
  page: number
  pageSize: number
  filter: string
}

const initialState: accessLogState = {
  from: moment().startOf("week").toDate(),
  to: moment().endOf("day").toDate(),
  page: 1,
  pageSize: 100,
  filter: "",
};

const accessLogReducer = (
  state: accessLogState,
  action: { type: string, data: any }
) => {
  switch (action.type) {
    case "DATES":
      return { ...state, from: action.data[0], to: action.data[1] };
    case "FILTER":
      return { ...state, filter: action.data };
    case "PAGINATE":
      return { ...state, page: action.data.page, pageSize: action.data.pageSize };
    default:
      throw new Error(`undefined action ${action.type}`);
  };
};

export const RouterAccessLog = () => {
  const [state, dispatch] = useReducer(accessLogReducer, initialState);

  const result = useQuery<g.SearchRouterAccessLog, {}>(SEARCH_ACCESS_LOG, {
    fetchPolicy: "no-cache",
    variables: {
      input: {
        from: state.from,
        to: state.to,
        page: state.page,
        pageSize: state.pageSize,
        filter: state.filter,
      }
    }
  });

  return (
    <Fragment>
      <Row gutter={[24, 16]}>
        <Col span={12}>
          <Input.Search
            allowClear={true}
            defaultValue={state.filter}
            placeholder="Search..."
            onSearch={(val) => dispatch({ type: "FILTER", data: val })}
          />
        </Col>
        <Col span={8}>
          <DatePicker.RangePicker
            allowClear={false}
            showTime={true}
            value={[moment(state.from), moment(state.to)]}
            onChange={(dates) => dispatch({ type: "DATES", data: dates })}
          />
        </Col>
      </Row>
      <br />
      <Table
        size="middle"
        rowKey={record => record.ts}
        loading={result.loading}
        columns={columns}
        pagination={{
          pageSize: 100,
        }}
        dataSource={result.data?.searchRouterAccessLog}
      />
    </Fragment>
  );
};
