import { Button, Space, Switch } from "antd";
import { ColumnsType } from "antd/lib/table";
import { SortOrder } from "antd/lib/table/interface";
import gql from "graphql-tag";
import React from "react";
import { Link } from "react-router-dom";
import i18n from "../../common/i18n";
import { Date } from "../../components/Date";
import { ListContainer } from "../../containers/ListContainer";
import { useMutation, useQuery, useSearch } from "../../hooks";
import * as gu from "./__generated__/ResolvePendingUser";
import * as g from "./__generated__/SearchPendingUser";
import * as gsu from "./__generated__/UpdateUserSettings";
import * as gs from "./__generated__/UserSettings";

const SEARCH_PENDING_USER = gql`
  query SearchPendingUser($input: SearchInput!) {
    searchPendingUser(input: $input) {
      data {
        id
        login
        email
        updatedAt
      }
      page
      pageSize
      total
    }
  }
`;

const RESOLVE_PENDING_USER = gql`
  mutation ResolvePendingUser($id: ID!, $action: String!) {
    resolvePendingUser(id: $id, action: $action)
  }
`;

const GET_USER_SETTINGS = gql`
  query UserSettings {
    userSettings {
      activationEnabled
    }
  }
`;

const UPDATE_USER_SETTINGS = gql`
  mutation UpdateUserSettings($input: UserSettingsInput!) {
    updateUserSettings(input: $input) {
      activationEnabled
    }
  }
`;

export const UserPending = () => {
  const { result, search, updateFilter, updateTable } = useSearch<g.SearchPendingUser>(SEARCH_PENDING_USER);

  const [resolvePendingUser] = useMutation<gu.ResolvePendingUser, gu.ResolvePendingUserVariables>(RESOLVE_PENDING_USER, {
    okText: i18n.t("user:Done"),
    refetchQueries: ["SearchPendingUser"],
  });

  const { data } = useQuery<gs.UserSettings, {}>(GET_USER_SETTINGS);
  const [updateUserSettings] = useMutation<gsu.UpdateUserSettings, gsu.UpdateUserSettingsVariables>(UPDATE_USER_SETTINGS, {
    okText: i18n.t("user:Done"),
    refetchQueries: ["UserSettings"],
  });

  const activationSwitch = (
    <Space>
      <Switch checked={data?.userSettings.activationEnabled} onClick={(s) => updateUserSettings({ variables: { input: { activationEnabled: s } } })} />
      <span>Activation</span>
    </Space>
  );

  const columns: ColumnsType<g.SearchPendingUser_searchPendingUser_data> = [
    {
      dataIndex: "login",
      title: i18n.t("user:Login"),
      sorter: true,
      render: (text, record) => <Link to={`/users/${record.id}`}>{text}</Link>,
    },
    {
      dataIndex: "email",
      title: i18n.t("user:Email"),
      sorter: true,
    },
    {
      dataIndex: "updatedAt",
      title: i18n.t("user:Updated At"),
      sorter: true,
      defaultSortOrder: "descend" as SortOrder,
      render: text => <Date date={text} />,
    },
    {
      key: "actions",
      title: i18n.t("user:Actions"),
      render: (_, record) => {
        return (
          <Space>
            <Button
              type="primary"
              size="small"
              onClick={() => resolvePendingUser({ variables: { id: record.id, action: "activate" } })}
            >
              Activate
            </Button>

            <Button
              size="small"
              onClick={() => resolvePendingUser({ variables: { id: record.id, action: "decline" } })}
            >
              Decline
            </Button>
          </Space>
        );
      },
    }
  ];

  return (
    <ListContainer
      create={activationSwitch}
      search={search}
      columns={columns}
      data={result.data?.searchPendingUser.data}
      total={result.data?.searchPendingUser.total}
      loading={result.loading}
      onSearch={updateFilter}
      onTableChange={updateTable}
    />
  );
};
