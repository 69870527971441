import { InputNumber, Select } from "antd";
import React, { useState } from "react";

type Unit = "h" | "d" | "m"

const unitMultipliers = {
  "h": 3600,
  "d": 86400,
  "m": 2592000,
};

// https://github.com/ant-design/ant-design/issues/31431
const unitPrecision = {
  "h": 0,
  "d": 1,
  "m": 2,
};

const periodParser = (unit: Unit) => (value: string | undefined) => {
  const numValue = parseInt(value || "", 10);
  return Number.isNaN(numValue) ? 0 : unitMultipliers[unit] * numValue;
};

const periodFormatter = (unit: Unit) => (value: number | undefined, info: { userTyping: boolean, input: string }) => {
  if (!value) {
    return "0";
  }

  return (value / unitMultipliers[unit]).toFixed(0);
};

export interface InputPeriodProps {
  value?: number;
  onChange?: (value: number) => void;
}

export const InputPeriod = (props: InputPeriodProps) => {
  const defaultUnit = props.value && props.value < 86400 ? "h" : "d";
  const [unit, setUnit] = useState<Unit>(defaultUnit);
  const unitSelector = (
    <Select
      value={unit}
      onChange={setUnit}
    >
      <Select.Option value="h">Hour</Select.Option>
      <Select.Option value="d">Day</Select.Option>
      <Select.Option value="m">Month</Select.Option>
    </Select>
  );

  return (
    <InputNumber
      addonAfter={unitSelector}
      parser={periodParser(unit)}
      formatter={periodFormatter(unit)}
      min={0}
      precision={unitPrecision[unit]}
      step={unitMultipliers[unit]}
      value={props.value}
      onChange={props.onChange}
    />
  );
};
