import { Checkbox, Form, Input, Select } from "antd";
import gql from "graphql-tag";
import React, { Fragment } from "react";
import { useHistory, useParams } from "react-router-dom";
import i18n from "../../common/i18n";
import { EditForm } from "../../containers/EditForm";
import { useMutation, useQuery } from "../../hooks";
import { LogListControlled } from "../Log";
import * as g from "./__generated__/Admin";
import * as gd from "./__generated__/DeleteAdmin";
import * as gu from "./__generated__/UpdateAdmin";

const GET_ADMIN = gql`
  query Admin($id: ID!) {
    admin(id: $id) {
      id
      login
      role
      routerAccess
      createdAt
      updatedAt
    }
  }
`;

const UPDATE_ADMIN = gql`
  mutation UpdateAdmin($id: ID!, $input: AdminUpdateInput!) {
    updateAdmin(id: $id, input: $input) {
      id
      login
      role
      routerAccess
      createdAt
      updatedAt
    }
  }
`;

const DELETE_ADMIN = gql`
  mutation DeleteAdmin($id: ID!) {
    deleteAdmin(id: $id)
  }
`;

interface AdminEditFields {
  login: string;
  password?: string;
  routerAccess?: boolean;
}

export const AdminEdit = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm<AdminEditFields>();

  const { loading, data } = useQuery<g.Admin, g.AdminVariables>(GET_ADMIN, {
    variables: { id },
  });

  const [updateAdmin] = useMutation<gu.UpdateAdmin, gu.UpdateAdminVariables>(UPDATE_ADMIN, {
    okText: i18n.t("admin:Admin updated"),
    refetchQueries: ["Admin"]
  });

  const [deleteAdmin] = useMutation<gd.DeleteAdmin, gd.DeleteAdminVariables>(DELETE_ADMIN, {
    okText: i18n.t("admin:Admin deleted"),
    onCompleted: (_) => {
      history.push("/admins");
    }
  });

  return (
    <Fragment>
      <h3>{i18n.t("admin:Edit")}</h3>
      <EditForm
        name="edit-admin"
        deleteTitle={i18n.t("admin:Delete admin?")}
        loading={loading}
        form={form}
        initialValues={data?.admin}
        onSave={(values) => updateAdmin({ variables: { id, input: values } })}
        onDelete={() => deleteAdmin({ variables: { id } })}
      >
        <Form.Item
          name="login"
          label={i18n.t("admin:Login")}
        >
          <Input disabled={true} />
        </Form.Item>
        <Form.Item
          name="role"
          label={i18n.t("admin:Role")}
        >
          <Select>
            <Select.Option value="support">support</Select.Option>
            <Select.Option value="noc">noc</Select.Option>
            <Select.Option value="manager">manager</Select.Option>
            <Select.Option value="admin">admin</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="routerAccess"
          label={i18n.t("admin:Router access")}
          extra={i18n.t("admin:Allows to connect through any port on router and bypass restrictions")}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name="password"
          label={i18n.t("admin:Password")}
          extra={i18n.t("admin:Leave empty to preserve current password")}
        >
          <Input />
        </Form.Item>
      </EditForm>
      <br />
      <h3>{i18n.t("admin:Log")}</h3>
      {data?.admin && <LogListControlled causes={[data!.admin.id]} />}
    </Fragment>
  );
};
