import { CopyOutlined } from "@ant-design/icons";
import { Checkbox, Space, message } from "antd";
import { ColumnsType } from "antd/lib/table";
import { SortOrder } from "antd/lib/table/interface";
import gql from "graphql-tag";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
import i18n from "../../common/i18n";
import { Date } from "../../components/Date";
import { ListContainer } from "../../containers/ListContainer";
import { useSearch } from "../../hooks";
import { PromoCreate } from "./Create";
import * as g from "./__generated__/SearchPromo";

const SEARCH_PROMO = gql`
  query SearchPromo($input: SearchInput!) {
    searchPromo(input: $input) {
      data {
        id
        name
        token
        active
        views
        registrations
        createdAt
        updatedAt
      }
      page
      pageSize
      total
    }
  }
`;

export const PromoList = () => {
  const { result, search, updateFilter, updateTable } = useSearch<g.SearchPromo>(SEARCH_PROMO);

  const columns: ColumnsType<g.SearchPromo_searchPromo_data> = [
    {
      dataIndex: "name",
      title: i18n.t("promo:Name"),
      sorter: true,
      render: (text, record) => <Link to={`/promos/${record.id}`}>{text}</Link>,
    },
    {
      dataIndex: "token",
      title: i18n.t("promo:Token"),
      sorter: true,
      render: text => {
        return (
          <Space size="small">
            <CopyToClipboard
              text={`https://my.ltesocks.io/#/register?promo=${text}`}
              onCopy={() => message.info(i18n.t("promo:Link copied"))}
            >
              <CopyOutlined />
            </CopyToClipboard>
            {text}
          </Space>
        );
      }
    },
    {
      key: "active",
      title: i18n.t("promo:Active"),
      render: (_, record) => <Checkbox checked={record.active} disabled={true} />
    },
    {
      dataIndex: "views",
      title: i18n.t("promo:Views"),
    },
    {
      dataIndex: "registrations",
      title: i18n.t("promo:Registrations"),
    },
    {
      dataIndex: "createdAt",
      title: i18n.t("promo:Created At"),
      sorter: true,
      render: text => <Date date={text} />,
    },
    {
      dataIndex: "updatedAt",
      title: i18n.t("promo:Updated At"),
      sorter: true,
      render: text => <Date date={text} />,
      defaultSortOrder: "descend" as SortOrder,
    },
  ];


  return (
    <ListContainer
      create={<PromoCreate />}
      search={search}
      columns={columns}
      data={result.data?.searchPromo.data}
      total={result.data?.searchPromo.total}
      loading={result.loading}
      onSearch={updateFilter}
      onTableChange={updateTable}
    />
  );
};
