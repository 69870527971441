import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Row, Space, Spin } from "antd";
import gql from "graphql-tag";
import React, { Fragment, useReducer } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "../../hooks";
import { InputDataAmount, InputPeriod, InputUSD } from "../Inputs";
import * as g from "./__generated__/PlanOptions";
import * as gu from "./__generated__/UpdatePlanOptions";

const GET_PLAN_OPTIONS = gql`
  query PlanOptions($id: ID!) {
    plan(id: $id) {
      id
      tarifications {
        time
        traffic
        price
      }
      partnerTarifications {
        time
        traffic
        price
      }
      partnerTarifications2 {
        time
        traffic
        price
      }
      partnerTarifications3 {
        time
        traffic
        price
      }
      partnerTarifications4 {
        time
        traffic
        price
      }
      partnerTarifications5 {
        time
        traffic
        price
      }
      datapacks {
        price
        amount
      }
    }
  }
`;

const UPDATE_PLAN_OPTIONS = gql`
  mutation UpdatePlanOptions($id: ID!, $input: PlanUpdateInput!) {
    updatePlan(id: $id, input: $input) {
      id
      tarifications {
        time
        traffic
        price
      }
      partnerTarifications {
        time
        traffic
        price
      }
      partnerTarifications2 {
        time
        traffic
        price
      }
      partnerTarifications3 {
        time
        traffic
        price
      }
      partnerTarifications4 {
        time
        traffic
        price
      }
      partnerTarifications5 {
        time
        traffic
        price
      }
      datapacks {
        price
        amount
      }
    }
  }
`;

interface Option {
  time: number;
  traffic: number;
  price: number;
}

interface PartnerOptions {
  time: number;
  traffic: number;
  price1: number;
  price2: number;
  price3: number;
  price4: number;
  price5: number;
}

interface Datapack {
  price: number;
  amount: number;
}

interface EditOptionsState {
  options: Option[];
  partnerOptions: PartnerOptions[];
  datapacks: Datapack[];
}

const defaultState = {
  options: [] as Option[],
  partnerOptions: [] as PartnerOptions[],
  datapacks: [] as Datapack[],
};

const initPartnerOptions = (data: any): PartnerOptions[] => {
  return data.partnerTarifications.map((x: any) => ({
    time: x.time,
    traffic: x.traffic,
    price1: x.price,
    price2: data.partnerTarifications2.find((y: any) => y.time === x.time && y.traffic === x.traffic)?.price || 0,
    price3: data.partnerTarifications3.find((y: any) => y.time === x.time && y.traffic === x.traffic)?.price || 0,
    price4: data.partnerTarifications4.find((y: any) => y.time === x.time && y.traffic === x.traffic)?.price || 0,
    price5: data.partnerTarifications5.find((y: any) => y.time === x.time && y.traffic === x.traffic)?.price || 0,
  }));
};

const convertPartnerOptions = (partnerOptions: PartnerOptions[]): any => {
  const partnerTarifications = partnerOptions.map(x => ({ time: x.time, traffic: x.traffic, price: x.price1 }));
  const partnerTarifications2 = partnerOptions.map(x => ({ time: x.time, traffic: x.traffic, price: x.price2 }));
  const partnerTarifications3 = partnerOptions.map(x => ({ time: x.time, traffic: x.traffic, price: x.price3 }));
  const partnerTarifications4 = partnerOptions.map(x => ({ time: x.time, traffic: x.traffic, price: x.price4 }));
  const partnerTarifications5 = partnerOptions.map(x => ({ time: x.time, traffic: x.traffic, price: x.price5 }));

  return { partnerTarifications, partnerTarifications2, partnerTarifications3, partnerTarifications4, partnerTarifications5 };
};

const editOptionsReducer = (
  state: EditOptionsState,
  action: { type: string, data: any }
): EditOptionsState => {
  switch (action.type) {
    case "INIT":
      return {
        ...state,
        options: action.data.tarifications,
        partnerOptions: initPartnerOptions(action.data),
        datapacks: action.data.datapacks,
      };
    case "ADD_OPTION":
      return {
        ...state,
        options: [...state.options, { time: 604800, traffic: -1, price: 10000 }]
      };
    case "DELETE_OPTION":
      return {
        ...state,
        options: state.options.filter((_, i) => i !== action.data.index),
      };
    case "UPDATE_OPTION":
      return {
        ...state,
        options: state.options.map((x, i) => (i === action.data.index) ? { ...x, [action.data.prop]: action.data.value } : x),
      };
    case "ADD_PARTNER_OPTION":
      return {
        ...state,
        partnerOptions: [...state.partnerOptions, { time: 604800, traffic: -1, price1: 10000, price2: 10000, price3: 10000, price4: 10000, price5: 10000 }]
      };
    case "DELETE_PARTNER_OPTION":
      return {
        ...state,
        partnerOptions: state.partnerOptions.filter((_, i) => i !== action.data.index),
      };
    case "UPDATE_PARTNER_OPTION":
      return {
        ...state,
        partnerOptions: state.partnerOptions.map((x, i) => (i === action.data.index) ? { ...x, [action.data.prop]: action.data.value } : x),
      };
    case "ADD_DATAPACK":
      return {
        ...state,
        datapacks: [...state.datapacks, { price: 500, amount: 5120 }]
      };
    case "DELETE_DATAPACK":
      return {
        ...state,
        datapacks: state.datapacks.filter((_, i) => i !== action.data.index),
      };
    case "UPDATE_DATAPACK":
      return {
        ...state,
        datapacks: state.datapacks.map((x, i) => (i === action.data.index) ? { ...x, [action.data.prop]: action.data.value } : x),
      };
    default:
      throw new Error(`undefined action ${action.type}`);
  }
};

export const EditOptions = () => {
  const { id } = useParams<{ id: string }>();
  const [state, dispatch] = useReducer(editOptionsReducer, defaultState);

  const { loading } = useQuery<g.PlanOptions, g.PlanOptionsVariables>(GET_PLAN_OPTIONS, {
    variables: { id },
    onCompleted: (data) => {
      dispatch({ type: "INIT", data: data.plan });
    }
  });

  const [updatePlan] = useMutation<gu.UpdatePlanOptions, gu.UpdatePlanOptionsVariables>(UPDATE_PLAN_OPTIONS, {
    okText: "Plan updated",
    refetchQueries: ["Plan"]
  });


  if (loading) {
    return <Spin />;
  }

  return (
    <Fragment>
      <h3>Options</h3><br />
      <Space direction="vertical" style={{ width: "100%" }}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Button
              icon={<PlusOutlined />}
              onClick={() => dispatch({ type: "ADD_OPTION", data: {} })}
            >
              New
            </Button>
          </Col>
        </Row>

        {state.options.map((x, i) =>
          <Row gutter={[16, 16]} key={`options-${i}`}>
            <Col span={6}>
              <InputPeriod value={x.time} onChange={(val) => dispatch({ type: "UPDATE_OPTION", data: { index: i, prop: "time", value: val } })} />
            </Col>
            <Col span={6}>
              <InputDataAmount value={x.traffic} onChange={(val) => dispatch({ type: "UPDATE_OPTION", data: { index: i, prop: "traffic", value: val } })} onSetInfinite={() => dispatch({ type: "UPDATE_OPTION", data: { index: i, prop: "traffic", value: -1 } })} />
            </Col>
            <Col span={2}>
              <InputUSD value={x.price} onChange={(val) => dispatch({ type: "UPDATE_OPTION", data: { index: i, prop: "price", value: val } })} />
            </Col>
            <Col span={1}>
              <Button icon={<MinusOutlined />} onClick={() => dispatch({ type: "DELETE_OPTION", data: { index: i } })} />
            </Col>
          </Row>
        )}
      </Space>
      <br /><br /><br />
      <h3>Partner Options</h3><br />
      <Space direction="vertical" style={{ width: "100%" }}>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Button
              icon={<PlusOutlined />}
              onClick={() => dispatch({ type: "ADD_PARTNER_OPTION", data: {} })}
            >
              New
            </Button>
          </Col>
          <Col span={2}><b>Partner1</b></Col>
          <Col span={2}><b>Partner2</b></Col>
          <Col span={2}><b>Partner3</b></Col>
          <Col span={2}><b>Partner4</b></Col>
          <Col span={2}><b>Partner5</b></Col>
        </Row>


        {state.partnerOptions.map((x, i) =>
          <Row gutter={[16, 16]} key={`partner-options-${i}`}>
            <Col span={6}>
              <InputPeriod value={x.time} onChange={(val) => dispatch({ type: "UPDATE_PARTNER_OPTION", data: { index: i, prop: "time", value: val } })} />
            </Col>
            <Col span={6}>
              <InputDataAmount value={x.traffic} onChange={(val) => dispatch({ type: "UPDATE_PARTNER_OPTION", data: { index: i, prop: "traffic", value: val } })} onSetInfinite={() => dispatch({ type: "UPDATE_PARTNER_OPTION", data: { index: i, prop: "traffic", value: -1 } })} />
            </Col>
            <Col span={2}>
              <InputUSD value={x.price1} onChange={(val) => dispatch({ type: "UPDATE_PARTNER_OPTION", data: { index: i, prop: "price1", value: val } })} />
            </Col>
            <Col span={2}>
              <InputUSD value={x.price2} onChange={(val) => dispatch({ type: "UPDATE_PARTNER_OPTION", data: { index: i, prop: "price2", value: val } })} />
            </Col>
            <Col span={2}>
              <InputUSD value={x.price3} onChange={(val) => dispatch({ type: "UPDATE_PARTNER_OPTION", data: { index: i, prop: "price3", value: val } })} />
            </Col>
            <Col span={2}>
              <InputUSD value={x.price4} onChange={(val) => dispatch({ type: "UPDATE_PARTNER_OPTION", data: { index: i, prop: "price4", value: val } })} />
            </Col>
            <Col span={2}>
              <InputUSD value={x.price5} onChange={(val) => dispatch({ type: "UPDATE_PARTNER_OPTION", data: { index: i, prop: "price5", value: val } })} />
            </Col>
            <Col span={1}>
              <Button icon={<MinusOutlined />} onClick={() => dispatch({ type: "DELETE_PARTNER_OPTION", data: { index: i } })} />
            </Col>
          </Row>
        )}
      </Space>
      <br /><br /><br />
      <h3>Datapacks</h3><br />
      <Space direction="vertical">
        <Button
          icon={<PlusOutlined />}
          onClick={() => dispatch({ type: "ADD_DATAPACK", data: {} })}
        >
          New
        </Button>

        {state.datapacks.map((x, i) =>
          <Space key={`datapack-${i}`}>
            <InputDataAmount value={x.amount} onChange={(val) => dispatch({ type: "UPDATE_DATAPACK", data: { index: i, prop: "amount", value: val } })} onSetInfinite={() => dispatch({ type: "UPDATE_DATAPACK", data: { index: i, prop: "amount", value: -1 } })} />
            <span>for</span>
            <InputUSD value={x.price} onChange={(val) => dispatch({ type: "UPDATE_DATAPACK", data: { index: i, prop: "price", value: val } })} />
            <Button icon={<MinusOutlined />} onClick={() => dispatch({ type: "DELETE_DATAPACK", data: { index: i } })} />
          </Space>
        )}
      </Space>
      <br /><br /><br /><br />
      <Button
        htmlType="submit"
        type="primary"
        onClick={() => updatePlan({ variables: { id, input: { tarifications: state.options, datapacks: state.datapacks, ...convertPartnerOptions(state.partnerOptions) } } })}
      >
        Save
      </Button>
    </Fragment >
  );
};
