import { Form, Input } from "antd";
import gql from "graphql-tag";
import React, { useState } from "react";
import i18n from "../../common/i18n";
import { CreateForm } from "../../containers/CreateForm";
import { useMutation } from "../../hooks";
import * as g from "./__generated__/CreatePlan";

const CREATE_PLAN = gql`
  mutation CreatePlan($name: String!) {
    createPlan(name: $name) {
      id
      name
      description
      pool
      reservation
      active
      autoRenew
      vpnAccess
      tarifications {
        time
        traffic
        price
      }
      datapacks {
        price
        amount
      }
      createdAt
      updatedAt
    }
  }
`;

export const PlanCreate = () => {
  const [form] = Form.useForm<g.CreatePlanVariables>();
  const [isOpen, toggle] = useState(false);

  const [createPlan] = useMutation<{
    createUser: g.CreatePlan,
    user: g.CreatePlan_createPlan,
  }>(CREATE_PLAN, {
    okText: i18n.t("user:Plan created"),
    refetchQueries: ["SearchPlan"],
    onCompleted: (_) => {
      form.resetFields();
      toggle(false);
    },
  });

  return (
    <CreateForm
      name="create-plan"
      title={i18n.t("plan:Create plan")}
      form={form}
      isOpen={isOpen}
      toggleOpen={() => toggle(!isOpen)}
      onCreate={(values) => createPlan({ variables: values })}
    >
      <Form.Item
        name="name"
        label={i18n.t("plan:Name")}
        rules={[{ required: true }]}
      >
        <Input placeholder="Plan" />
      </Form.Item>
    </CreateForm>
  );
};
