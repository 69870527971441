import { QuestionCircleOutlined } from "@ant-design/icons";
import { Popover, Space, Timeline, Typography } from "antd";
import gql from "graphql-tag";
import React from "react";
import { formatCurrency, formatDate } from "../../common/formatter";
import { useQuery } from "../../hooks/apollo";
import * as g from "./__generated__/PaymentOrderInfo";

const GET_ORDER_INFO = gql`
  query PaymentOrderInfo($id: ID!) {
    paymentOrderInfo(id: $id) {
      order {
        id
        status
        gateway
        amount
        amountPaid
        currency
        exchangeRate
        createdAt
        updatedAt
      }
      responses {
        ... on PaymentGatewayResponseWFP {
          amount
          processingDate
          cardPan
          cardType
          transactionStatus
          reason
          baseAmount
          baseCurrency
        }
        ... on PaymentGatewayResponseAnyMoney {
          lid
          time
          outCurr
          outAmount
          status
        }
        ... on PaymentGatewayResponseBinance {
          time
          bizStatus
          totalFee
          currency
          transactionId
        }
        ... on PaymentGatewayResponseWhitepay {
          whitepayId
          currency
          expectedAmount
          receivedTotal
          status
          createdAt
        }
      }
    }
  }
`;

const renderResponses = (responses: g.PaymentOrderInfo_paymentOrderInfo_responses[]) => {
  if (responses.length === 0) {
    return;
  }

  switch (responses[0].__typename) {
    case "PaymentGatewayResponseWFP":
      return renderWFP(responses as g.PaymentOrderInfo_paymentOrderInfo_responses_PaymentGatewayResponseWFP[]);
    case "PaymentGatewayResponseAnyMoney":
      return renderAnyMoney(responses as g.PaymentOrderInfo_paymentOrderInfo_responses_PaymentGatewayResponseAnyMoney[]);
    case "PaymentGatewayResponseBinance":
      return renderBinance(responses as g.PaymentOrderInfo_paymentOrderInfo_responses_PaymentGatewayResponseBinance[]);
    case "PaymentGatewayResponseWhitepay":
      return renderWhitepay(responses as g.PaymentOrderInfo_paymentOrderInfo_responses_PaymentGatewayResponseWhitepay[]);
  }
};

const renderWFP = (responses: g.PaymentOrderInfo_paymentOrderInfo_responses_PaymentGatewayResponseWFP[]) => {
  const items = responses.map(resp => {
    switch (resp.transactionStatus) {
      case "Approved":
        return (
          <Timeline.Item
            key={resp.processingDate}
            label={formatDate(resp.processingDate)}
            color="green"
          >
            <Space direction="vertical">
              {resp.transactionStatus}
              <Typography.Text>{resp.baseAmount} {resp.baseCurrency}</Typography.Text>
              <Typography.Text>{resp.cardType} {resp.cardPan}</Typography.Text>
            </Space>
          </Timeline.Item>
        );
      case "Expired":
      case "Declined":
        return (
          <Timeline.Item
            key={resp.processingDate}
            label={formatDate(resp.processingDate)}
            color="red"
          >
            <Space direction="vertical">
              {resp.transactionStatus}
              <Typography.Text>{resp.reason}</Typography.Text>
            </Space>
          </Timeline.Item>
        );
      default:
        return (
          <Timeline.Item
            key={resp.processingDate}
            label={formatDate(resp.processingDate)}
          >
            {resp.transactionStatus}
          </Timeline.Item>
        );
    }
  });

  return (
    <Timeline
      mode="left"
      style={{ width: "30vw" }}
    >
      {items}
    </Timeline>
  );
};

const renderAnyMoney = (responses: g.PaymentOrderInfo_paymentOrderInfo_responses_PaymentGatewayResponseAnyMoney[]) => {
  const items = responses.map(resp => {
    switch (resp.status) {
      case "new":
      case "pending":
        return (
          <Timeline.Item
            key={resp.time}
            label={formatDate(resp.time)}
          >
            <Space direction="vertical">
              {resp.status}
              <Typography.Text>Local ID: {resp.lid}</Typography.Text>
            </Space>
          </Timeline.Item>
        );
      case "done":
        return (
          <Timeline.Item
            key={resp.time}
            label={formatDate(resp.time)}
            color="green"
          >
            <Space direction="vertical">
              {resp.status}
              <Typography.Text>{resp.outAmount} {resp.outCurr}</Typography.Text>
            </Space>
          </Timeline.Item>
        );
      case "expired":
      case "canceled":
        return (
          <Timeline.Item
            key={resp.time}
            label={formatDate(resp.time)}
            color="red"
          >
            {resp.status}
          </Timeline.Item>
        );
      default:
        return (
          <Timeline.Item
            key={resp.time}
            label={formatDate(resp.time)}
          >
            {resp.status}
          </Timeline.Item>
        );
    }
  });

  return (
    <Timeline
      mode="left"
      style={{ width: "30vw" }}
    >
      {items}
    </Timeline>
  );
};

const renderBinance = (responses: g.PaymentOrderInfo_paymentOrderInfo_responses_PaymentGatewayResponseBinance[]) => {
  const items = responses.map(resp => {
    switch (resp.bizStatus) {
      case "PAY_SUCCESS":
        return (
          <Timeline.Item
            key={resp.time}
            label={formatDate(resp.time)}
            color="green"
          >
            <Space direction="vertical">
              {resp.bizStatus}
              <Typography.Text>{resp.totalFee} {resp.currency}</Typography.Text>
              <Typography.Text>{resp.transactionId}</Typography.Text>
            </Space>
          </Timeline.Item>
        );
      case "PAY_CLOSED":
        return (
          <Timeline.Item
            key={resp.time}
            label={formatDate(resp.time)}
            color="red"
          >
            {resp.bizStatus}
          </Timeline.Item>
        );
      default:
        return (
          <Timeline.Item
            key={resp.time}
            label={formatDate(resp.time)}
          >
            {resp.bizStatus}
          </Timeline.Item>
        );
    }
  });

  return (
    <Timeline
      mode="left"
      style={{ width: "30vw" }}
    >
      {items}
    </Timeline>
  );
};

const renderWhitepay = (responses: g.PaymentOrderInfo_paymentOrderInfo_responses_PaymentGatewayResponseWhitepay[]) => {
  const items = responses.map(resp => {
    switch (resp.status) {
      case "COMPLETE":
        return (
          <Timeline.Item
            key={resp.createdAt}
            label={formatDate(resp.createdAt)}
            color="green"
          >
            <Space direction="vertical">
              {resp.status}
              <Typography.Text>{resp.expectedAmount} {resp.currency} expected</Typography.Text>
              <Typography.Text>{resp.receivedTotal} {resp.currency} received</Typography.Text>
              <Typography.Text>{resp.whitepayId}</Typography.Text>
            </Space>
          </Timeline.Item>
        );
      default:
        return (
          <Timeline.Item
            key={resp.createdAt}
            label={formatDate(resp.createdAt)}
          >
            {resp.status}
          </Timeline.Item>
        );
    }
  });

  return (
    <Timeline
      mode="left"
      style={{ width: "30vw" }}
    >
      {items}
    </Timeline>
  );
};

export const OrderInfo = (props: { id: string }) => {
  return (
    <Popover
      title="Gateway Info"
      content={<OrderInfoContent id={props.id} />}
      overlayStyle={{ width: "30vw" }}
    >
      <QuestionCircleOutlined />
    </Popover>
  );
};

const OrderInfoContent = (props: { id: string }) => {
  const result = useQuery<g.PaymentOrderInfo, g.PaymentOrderInfoVariables>(GET_ORDER_INFO, {
    variables: { id: props.id },
  });

  var order = {
    id: "N/A",
    status: "N/A",
    gateway: "N/A",
    currency: "N/A",
    exchangeRate: 0,
    amount: 0,
    amountPaid: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
  };

  if (result.data) {
    order = result.data!.paymentOrderInfo.order;
  }

  return (
    <Space direction="vertical">
      <Space direction="vertical">
        <Typography.Text>ID: {order.id}</Typography.Text>
        <Typography.Text>Status: {order.status}</Typography.Text>
        <Typography.Text>Gateway: {order.gateway}</Typography.Text>
        <Typography.Text>Currency: {order.currency}</Typography.Text>
        {order.exchangeRate > 0 && <Typography.Text>Exchange Rate: {order.exchangeRate}</Typography.Text>}
        <Typography.Text>Amount: {formatCurrency(order.amount / 100)}</Typography.Text>
        <Typography.Text>Amount Paid: {formatCurrency(order.amountPaid / 100)}</Typography.Text>
        <Typography.Text>Created At: {formatDate(order.createdAt)}</Typography.Text>
        <Typography.Text>Updated At {formatDate(order.updatedAt)}</Typography.Text>
      </Space>
      <br />
      {result.data && renderResponses(result.data!.paymentOrderInfo.responses)}
    </Space>
  );
};
