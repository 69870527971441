import { Card } from "antd";
import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import { LogListControlled, PoolAssignmentLog } from "../Log";

export const PoolLog = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <Fragment>
      <Card title="Log">
        <LogListControlled subjects={[id]} />
      </Card>

      <Card title="Assignment Log">
        <PoolAssignmentLog id={id} />
      </Card>
    </Fragment>
  );
};
