import { Form, Input } from "antd";
import gql from "graphql-tag";
import React, { useState } from "react";
import i18n from "../../common/i18n";
import { CreateForm } from "../../containers/CreateForm";
import { useMutation } from "../../hooks";
import * as g from "./__generated__/CreatePromo";

const CREATE_PROMO = gql`
  mutation CreatePromo($name: String!, $token: String!) {
    createPromo(name: $name, token: $token) {
      id
    }
  }
`;

export const PromoCreate = () => {
  const [form] = Form.useForm<g.CreatePromoVariables>();
  const [isOpen, toggle] = useState(false);

  const [createPromo] = useMutation<{
    createPromo: g.CreatePromo,
    promo: g.CreatePromo_createPromo,
  }>(CREATE_PROMO, {
    okText: i18n.t("promo:Promo Campaign created"),
    refetchQueries: ["SearchPromo"],
    onCompleted: (_) => {
      form.resetFields();
      toggle(false);
    },
  });

  return (
    <CreateForm
      name="create-promo"
      title={i18n.t("promo:Create promo campaign")}
      form={form}
      isOpen={isOpen}
      toggleOpen={() => toggle(!isOpen)}
      onCreate={(values) => createPromo({ variables: values })}
    >
      <Form.Item
        name="name"
        label={i18n.t("promo:Name")}
        rules={[{ required: true }]}
      >
        <Input placeholder="Promo Campaign 1" />
      </Form.Item>
      <Form.Item
        name="token"
        label={i18n.t("promo:Token")}
        rules={[{ required: true }]}
      >
        <Input placeholder="token" />
      </Form.Item>
    </CreateForm>
  );
};
