import React from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { history } from "../common/history";
import { AdminEdit, AdminList } from "../components/Admin";
import { BannerEdit, BannerList } from "../components/Banner";
import { OrderList, TopUp } from "../components/Finance";
import { IPInfo } from "../components/IPInfo";
import { LogList } from "../components/Log";
import { Login } from "../components/Login";
import { ModemEdit, ModemList, ModemStuckedList } from "../components/Modem";
import { NodeEdit, NodeList, NodeMikrotik } from "../components/Node";
import { PlanEdit, PlanList } from "../components/Plan";
import { PoolEdit, PoolList } from "../components/Pool";
import { PortBilling, PortEdit, PortList } from "../components/Port";
import { PrivateRoute } from "../components/PrivateRoute";
import { PromoEdit, PromoList } from "../components/Promo";
import { RoleEdit, RoleList } from "../components/Role";
import { RouterAccessLog, RouterRestrictions } from "../components/Router";
import { SignatureList } from "../components/Signature";
import { UserEdit, UserInactive, UserList, UserPending, UserRequests } from "../components/User";
import { VPNContainer, VPNPortEdit } from "../components/VPN";

export const App = () => {
  return (
    <Router history={history}>
      <Switch>
        <PrivateRoute exact={true} path="/admins" component={AdminList} />
        <PrivateRoute path="/admins/:id" component={AdminEdit} />

        <PrivateRoute exact={true} path="/banners" component={BannerList} />
        <PrivateRoute path="/banners/:id" component={BannerEdit} />

        <PrivateRoute exact={true} path="/finance/topup" component={TopUp} />
        <PrivateRoute exact={true} path="/finance/orders" component={OrderList} />

        <PrivateRoute path="/ips/:ip" component={IPInfo} />

        <PrivateRoute exact={true} path="/logs" component={LogList} />

        <PrivateRoute exact={true} path="/modems/list" component={ModemList} />
        <PrivateRoute exact={true} path="/modems/stuck" component={ModemStuckedList} />
        <PrivateRoute path="/modems/:id" component={ModemEdit} />
        <PrivateRoute exact={true} path="/modems" component={NodeList}><Redirect to="/modems/list" /></PrivateRoute>

        <PrivateRoute exact={true} path="/nodes/list" component={NodeList} />
        <PrivateRoute exact={true} path="/nodes/mikrotik" component={NodeMikrotik} />
        <PrivateRoute path="/nodes/:id" component={NodeEdit} />
        <PrivateRoute exact={true} path="/nodes" component={NodeList}><Redirect to="/nodes/list" /></PrivateRoute>

        <PrivateRoute exact={true} path="/plans" component={PlanList} />
        <PrivateRoute path="/plans/:id" component={PlanEdit} />

        <PrivateRoute exact={true} path="/pools" component={PoolList} />
        <PrivateRoute path="/pools/:id" component={PoolEdit} />

        <PrivateRoute exact={true} path="/ports/list" component={PortList} />
        <PrivateRoute exact={true} path="/ports/billing" component={PortBilling} />
        <PrivateRoute path="/ports/:id" component={PortEdit} />
        <PrivateRoute exact={true} path="/ports" component={NodeList}><Redirect to="/ports/list" /></PrivateRoute>

        <PrivateRoute exact={true} path="/promos" component={PromoList} />
        <PrivateRoute path="/promos/:id" component={PromoEdit} />

        <PrivateRoute exact={true} path="/roles" component={RoleList} />
        <PrivateRoute exact={true} path="/roles/:id" component={RoleEdit} />

        <PrivateRoute exact={true} path="/routers/restrictions" component={RouterRestrictions} />
        <PrivateRoute exact={true} path="/routers/log" component={RouterAccessLog} />
        <PrivateRoute exact={true} path="/routers" component={NodeList}><Redirect to="/routers/log" /></PrivateRoute>

        <PrivateRoute exact={true} path="/signatures" component={SignatureList} />

        <PrivateRoute exact={true} path="/users/list" component={UserList} />
        <PrivateRoute exact={true} path="/users/pending" component={UserPending} />
        <PrivateRoute exact={true} path="/users/inactive" component={UserInactive} />
        <PrivateRoute exact={true} path="/users/requests" component={UserRequests} />
        <PrivateRoute path="/users/:id" component={UserEdit} />
        <PrivateRoute exact={true} path="/users" component={UserList}><Redirect to="/users/list" /></PrivateRoute>

        <PrivateRoute exact={true} path="/vpn" component={VPNContainer} />
        <PrivateRoute path="/vpn/:id" component={VPNPortEdit} />

        <Route exact={true} path="/login" component={Login} />

        <Redirect to="/users" />
      </Switch>
    </Router>
  );
};
