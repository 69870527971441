import { ApartmentOutlined, ApiOutlined, AuditOutlined, BankOutlined, ClusterOutlined, DeploymentUnitOutlined, DesktopOutlined, DollarOutlined, FundOutlined, PhoneOutlined, PictureOutlined, ProfileOutlined, UserOutlined, WindowsOutlined } from "@ant-design/icons";
import React from "react";

export const sections: { [index: string]: { icon: any, text: string } } = {
  "admins": { icon: <AuditOutlined />, text: "Administrators" },
  "banners": { icon: <PictureOutlined />, text: "Banners" },
  "finance": { icon: <BankOutlined />, text: "Finance" },
  "ips": { icon: <DesktopOutlined />, text: "IPs" },
  "logs": { icon: <ProfileOutlined />, text: "Logs" },
  "modems": { icon: <PhoneOutlined />, text: "Modems" },
  "nodes": { icon: <DesktopOutlined />, text: "Nodes" },
  "plans": { icon: <DollarOutlined />, text: "Plans" },
  "pools": { icon: <DeploymentUnitOutlined />, text: "Pools" },
  "ports": { icon: <ApiOutlined />, text: "Ports" },
  "promos": { icon: <FundOutlined />, text: "Promo Campaigns" },
  "roles": { icon: <AuditOutlined />, text: "Roles" },
  "routers": { icon: <ClusterOutlined />, text: "Routers" },
  "signatures": { icon: <WindowsOutlined />, text: "Signatures" },
  "stuck": { icon: <PhoneOutlined />, text: "Stuck modems" },
  "users": { icon: <UserOutlined />, text: "Users" },
  "vpn": { icon: <ApartmentOutlined />, text: "OpenVPN" },
};
