import { Col, Row } from "antd";
import React, { Fragment } from "react";
import { PortCommon, PortCredentials, PortSignature, PortTags, PortTarget } from "./Settings/";

export const PortSettings = () => {
  return (
    <Fragment>
      <Row gutter={24}>
        <Col span={12}><PortSignature /></Col>
        <Col span={12}><PortTarget /></Col>
      </Row>
      <br />
      <PortCredentials />
      <br />
      <Row gutter={24}>
        <Col span={12}><PortTags /></Col>
        <Col span={12}><PortCommon /></Col>
      </Row>
    </Fragment >
  );
};
