import { Checkbox } from "antd";
import { ColumnsType } from "antd/lib/table";
import { SortOrder } from "antd/lib/table/interface";
import gql from "graphql-tag";
import React from "react";
import { Link } from "react-router-dom";
import i18n from "../../common/i18n";
import { Date } from "../../components/Date";
import { ListContainer } from "../../containers/ListContainer";
import { useSearch } from "../../hooks";
import { BannerCreate } from "./Create";
import { BannerPreview } from "./Preview";
import * as g from "./__generated__/SearchBanner";

const SEARCH_BANNER = gql`
  query SearchBanner($input: SearchInput!) {
    searchBanner(input: $input) {
      data {
        id
        name
        active
        content {
          text
          html
          language
        }
        createdAt
        updatedAt
      }
      page
      pageSize
      total
    }
  }
`;

export const BannerList = () => {
  const { result, search, updateFilter, updateTable } = useSearch<g.SearchBanner>(SEARCH_BANNER);

  const columns: ColumnsType<g.SearchBanner_searchBanner_data> = [
    {
      dataIndex: "name",
      title: i18n.t("banner:Name"),
      sorter: true,
      render: (text, record) => <Link to={`/banners/${record.id}`}>{text}</Link>,
    },
    {
      key: "active",
      title: i18n.t("banner:Active"),
      render: (_, record) => <Checkbox checked={record.active} disabled={true} />
    },
    {
      key: "preview",
      title: i18n.t("banner:Preview"),
      render: (record) => <BannerPreview content={record.content} />,
    },
    {
      dataIndex: "createdAt",
      title: i18n.t("banner:Created At"),
      sorter: true,
      render: text => <Date date={text} />,
    },
    {
      dataIndex: "updatedAt",
      title: i18n.t("banner:Updated At"),
      sorter: true,
      render: text => <Date date={text} />,
      defaultSortOrder: "descend" as SortOrder,
    },
  ];

  return (
    <ListContainer
      create={<BannerCreate />}
      search={search}
      columns={columns}
      data={result.data?.searchBanner.data}
      total={result.data?.searchBanner.total}
      loading={result.loading}
      onSearch={updateFilter}
      onTableChange={updateTable}
    />
  );
};
