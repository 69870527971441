import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, ServerError } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { authenticationService } from "./auth";
import { history } from "./history";

const authLink = setContext((_, { headers, ...context }) => {
  const token = authenticationService.currentUserValue?.token;

  return {
    headers: {
      ...headers,
      ...(token ? { authorization: `Bearer ${token}` } : {}),
    },
    ...context,
  };
});

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_BASE_URL}/web/admin/graphql`,
  headers: {
    "App-Version": process.env.REACT_APP_REVISION,
  }
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors && graphQLErrors.filter(e => e).length > 0) {
    graphQLErrors.forEach(({ message = "" }) => {
      console.log(message);
      if (message === "unathorized") {
        if (history && history.location && history.location.pathname !== "/login") {
          authenticationService.logout();
          history.push("/login");
        }
      }

      if (message === "forbidden") {
        console.log("FORBIDDEN");
      }
    });
  }

  if (networkError && (networkError as ServerError).statusCode === 401) {
    authenticationService.logout();
    history.push("/login");
  }

  if (networkError && (networkError as ServerError).statusCode === 403) {
    console.log("FORBIDDEN");
  }

  if (networkError && (networkError as ServerError).statusCode >= 500) {
    console.log("SERVER ERROR");
  }
});

const cache = new InMemoryCache();

export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache,
  connectToDevTools: process.env.NODE_ENV === "development",
});
