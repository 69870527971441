import { DownloadOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Modal, Row, Select, Space, Spin, Table, notification } from "antd";
import { ColumnsType } from "antd/lib/table";
import gql from "graphql-tag";
import React, { Fragment, useReducer } from "react";
import { useParams } from "react-router-dom";
import * as gg from "../../../__generated__/globalTypes";
import { formatBandwidth } from "../../../common/formatter";
import { useMutation, useQuery } from "../../../hooks";
import { InputBandwidth } from "../../Inputs";
import { downloadConfig } from "../DownloadConfig";
import * as g from "./__generated__/PortCredentials";
import * as gu from "./__generated__/UpdatePortCredentials";
import * as gf from "./__generated__/VPNCredentialsConfig";

const GET_PORT_CREDENTIALS = gql`
  query PortCredentials($id: ID!) {
    port(id: $id) {
      id
      credentials {
        kind
        ipFilter
        login
        digest
        speedLimit
      }
    }
  }
`;

const UPDATE_PORT_CREDENTIALS = gql`
  mutation UpdatePortCredentials($id: ID!, $input: [PortCredentialsInput!]!) {
    updatePortCredentials(id: $id, input: $input) {
      id
      credentials {
        kind
        ipFilter
        login
        digest
        speedLimit
      }
    }
  }
`;

const VPN_CONFIG = gql`
  query VPNCredentialsConfig($port: String!, $server: String!, $login: String!, $passwordDigest: String!) {
    vpnCredentialsConfig(port: $port, server: $server, login: $login, passwordDigest: $passwordDigest)
  }
`;

interface IPortCredentialsState {
  value: g.PortCredentials_port_credentials[];
  isOpen: boolean;
}

const initialState: IPortCredentialsState = {
  value: [],
  isOpen: false,
};

const portCredentialsReducer = (
  state: IPortCredentialsState,
  action: { type: string, data: any },
) => {
  switch (action.type) {
    case "INIT":
      return { ...state, value: action.data.credentials };
    case "ADD":
      return { ...state, isOpen: !state.isOpen, value: [...state.value, action.data] };
    case "TOGGLE":
      return { ...state, isOpen: !state.isOpen };
    case "DELETE_IP":
      return { ...state, value: state.value.filter(x => x.ipFilter !== action.data) };
    case "DELETE_PASSWORD":
      return { ...state, value: state.value.filter(x => x.digest !== action.data) };
    default:
      throw new Error(`undefined action ${action.type}`);
  }
};

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export const PortCredentials = () => {
  const { id } = useParams<{ id: string }>();
  const [state, dispatch] = useReducer(portCredentialsReducer, initialState);
  const [addForm] = Form.useForm();

  const { loading } = useQuery<g.PortCredentials, g.PortCredentialsVariables>(GET_PORT_CREDENTIALS, {
    variables: { id },
    onCompleted: (data) => {
      dispatch({ type: "INIT", data: data.port });
    }
  });

  const { "refetch": fetchConfig } = useQuery<gf.VPNCredentialsConfig, gf.VPNCredentialsConfigVariables>(VPN_CONFIG, {
    skip: true,
    fetchPolicy: "no-cache",
  });

  const [updateCredentials] = useMutation<gu.UpdatePortCredentials, gu.UpdatePortCredentialsVariables>(UPDATE_PORT_CREDENTIALS, {
    refetchQueries: ["PortCredentials"],
    okText: "Port updated",
  });

  const ipColumns: ColumnsType<g.PortCredentials_port_credentials> = [
    {
      key: "ipFilter",
      title: "IP Filter",
      dataIndex: "ipFilter",
    },
    {
      key: "speedLimit",
      title: "Speed Limit",
      render: (_text, record) => record.speedLimit === 0 ? "∞" : formatBandwidth(record.speedLimit),
    },
    {
      key: "delete",
      title: "Delete",
      width: 100,
      render: (_text, record) => <Button onClick={() => dispatch({ type: "DELETE_IP", data: record.ipFilter })}>Delete</Button>
    },
  ];

  const passwordColumns: ColumnsType<g.PortCredentials_port_credentials> = [
    {
      key: "login",
      title: "Login",
      dataIndex: "login",
    },
    {
      key: "speedLimit",
      title: "Speed Limit",
      render: (_text, record) => record.speedLimit === 0 ? "∞" : formatBandwidth(record.speedLimit),
    },
    {
      key: "vpnConfig",
      title: "VPN Config",
      render: (_text, record) => (
        <Button
          icon={<DownloadOutlined />}
          onClick={() => fetchConfig({ port: id, server: "vpn1.ltesocks.io", login: record.login!, passwordDigest: record.digest! })
            .then(z => downloadConfig(id, z.data.vpnCredentialsConfig))
            .catch(e => notification.error({ message: "Error", description: e.message }))
          }
        />
      ),
    },
    {
      key: "delete",
      title: "Delete",
      width: 100,
      render: (_text, record) => <Button onClick={() => dispatch({ type: "DELETE_PASSWORD", data: record.digest })}>Delete</Button>
    },
  ];

  if (loading) {
    return <Spin />;
  }

  const newDialogFooter = (
    <Space>
      <Button onClick={() => dispatch({ type: "TOGGLE", data: {} })}>
        Cancel
      </Button>
      <Button type="primary" onClick={() => addForm.submit()}>
        Create
      </Button>
    </Space>
  );

  return (
    <Card title="Credentials">
      <Modal
        title="New credential"
        visible={state.isOpen}
        centered={true}
        footer={newDialogFooter}
        forceRender={true}
        onCancel={() => dispatch({ type: "TOGGLE", data: {} })}
      >
        <Form
          {...formLayout}
          form={addForm}
          name="add-credentials"
          initialValues={{ kind: "ip", speedLimit: 0 }}
          onFinish={(values) => dispatch({ type: "ADD", data: { ...values, digest: `${Date.now()}` } })}
        >
          <Form.Item name="kind" label="Kind" rules={[{ required: true }]}>
            <Select style={{ width: 120 }}>
              <Select.Option value="ip">IP</Select.Option>
              <Select.Option value="password">Password</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              if (getFieldValue("kind") === "ip") {
                return <Form.Item name="ipFilter" label="IP Filter" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>;
              }

              if (getFieldValue("kind") === "password") {
                return (
                  <Fragment>
                    <Form.Item name="login" label="Login" rules={[{ required: true }]}>
                      <Input />
                    </Form.Item>
                    <Form.Item name="password" label="Password" rules={[{ required: true }]}>
                      <Input />
                    </Form.Item>
                  </Fragment>
                );
              }
            }}
          </Form.Item>
          <Form.Item name="speedLimit" label="Speed Limit" rules={[{ required: true }]}>
            <InputBandwidth />
          </Form.Item>
        </Form>
      </Modal>

      <Space style={{ width: "100%" }} direction="vertical">
        <Row gutter={24}>
          <Col span={12}>
            <Table
              size="small"
              rowKey={x => `ip-${x.digest}`}
              columns={passwordColumns}
              dataSource={state.value.filter((x: any) => x.kind === gg.PortCredentialsKind.password)}
              pagination={false}
            />
          </Col>
          <Col span={12}>
            <Table
              size="small"
              rowKey={x => `ip-${x.ipFilter}`}
              columns={ipColumns}
              dataSource={state.value.filter((x: any) => x.kind === gg.PortCredentialsKind.ip)}
              pagination={false}
            />
          </Col>
        </Row>

        <Space style={{ margin: "16px 0" }}>
          <Button
            type="primary"
            onClick={() => updateCredentials({ variables: { id, input: state.value } })}
          >
            Update
          </Button>

          <Button
            icon={<PlusOutlined />}
            onClick={() => dispatch({ type: "TOGGLE", data: {} })}
          >
            New
          </Button>
        </Space>
      </Space>
    </Card >
  );
};
