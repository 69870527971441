import React, { Fragment } from "react";
import i18n from "../../common/i18n";
import { PortWithVPNAccessList } from "./ListPort";
import { VPNServerList } from "./ListServer";
import { PortVPNList } from "./ListVPNPort";

export { VPNServerCreate } from "./CreateServer";
export { VPNPortEdit } from "./EditVPNPort";
export { VPNServerList } from "./ListServer";

export const VPNContainer = () => {
  return (
    <Fragment>
      <h2>{i18n.t("vpn:Servers")}</h2>
      <VPNServerList />

      <h2>{i18n.t("vpn:Ports with VPN access")}</h2>
      <PortWithVPNAccessList />


      <h2>{i18n.t("vpn:Ports with custom target")}</h2>
      <PortVPNList />
    </Fragment>
  );
};
