import { ColumnsType } from "antd/lib/table";
import gql from "graphql-tag";
import React from "react";
import { Link } from "react-router-dom";
import i18n from "../../common/i18n";
import { ListContainer } from "../../containers/ListContainer";
import { useSearch } from "../../hooks";
import * as g from "./__generated__/SearchRole";

const SEARCH_ROLE = gql`
  query SearchRole($input: SearchInput!) {
    searchRole(input: $input) {
      data {
        id
        description
      }
      page
      pageSize
      total
    }
  }
`;

export const RoleList = () => {
  const { result, search, updateFilter, updateTable } = useSearch<g.SearchRole>(SEARCH_ROLE);

  const columns: ColumnsType<g.SearchRole_searchRole_data> = [
    {
      dataIndex: "id",
      title: i18n.t("role:ID"),
      render: (text, record) => <Link to={`/roles/${record.id}`}>{text}</Link>,
    },
    {
      dataIndex: "description",
      title: i18n.t("role:Description"),
    },
  ];

  return (
    <ListContainer
      create={<></>}
      search={search}
      columns={columns}
      data={result.data?.searchRole.data}
      total={result.data?.searchRole.total}
      loading={result.loading}
      onSearch={updateFilter}
      onTableChange={updateTable}
    />
  );
};
