import { Button, Form, Modal } from "antd";
import { FormInstance } from "antd/lib/form";
import React, { Fragment } from "react";
import i18n from "../common/i18n";

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

interface CreateFormProps<T> {
  name: string;
  title: string;
  form: FormInstance<T>;
  initialValues?: Partial<T>;
  isOpen: boolean;
  toggleOpen: () => void;
  onCreate: (values: T) => void;
}

export const CreateForm = <T,>(props: React.PropsWithChildren<CreateFormProps<T>>) => {
  const footer = (
    <Fragment>
      <Button onClick={() => props.toggleOpen()}>
        {i18n.t("create:Cancel")}
      </Button>
      <Button type="primary" onClick={() => props.form.submit()}>
        {i18n.t("create:Create")}
      </Button>
    </Fragment>
  );

  return (
    <Fragment>
      <Button onClick={() => props.toggleOpen()}>
        {i18n.t("create:Create")}
      </Button>
      <Modal
        title={props.title}
        visible={props.isOpen}
        centered={true}
        footer={footer}
        onCancel={() => props.toggleOpen()}
      >
        <Form
          {...formLayout}
          form={props.form}
          name={props.name}
          initialValues={props.initialValues}
          onFinish={props.onCreate}
        >
          {props.children}
        </Form>
      </Modal>
    </Fragment>
  );
};
