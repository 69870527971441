import { Column } from "@ant-design/charts";
import { Col, DatePicker, Form, Row, Spin } from "antd";
import gql from "graphql-tag";
import moment from "moment";
import React, { Fragment, useReducer } from "react";
import { useQuery } from "../../hooks/apollo";
import * as g from "./__generated__/PoolModemsUsage";

const POOL_MODEMS_USAGE = gql`
  query PoolModemsUsage($id: ID!, $from: Time!, $to: Time!) {
    poolModemsUsage(id: $id, from: $from, to: $to) {
      ts
      status
      value
    }
  }
`;

const { RangePicker } = DatePicker;

interface PoolModemsUsageState {
  from: Date
  to: Date
}

const initialState = () => {
  return {
    from: moment().add(-4, "hour").toDate(),
    to: moment().toDate(),
  };
};

const poolModemsUsageReducer = (
  state: PoolModemsUsageState,
  action: { type: string, data: any }
) => {
  switch (action.type) {
    case "DATES":
      return { ...state, from: action.data[0], to: action.data[1] };
    default:
      throw new Error(`undefined action ${action.type}`);
  }
};

const config = {
  isStack: true,
  xField: "ts",
  yField: "value",
  seriesField: "status",
  meta: {
    "status": {
      values: ["available", "assigned", "unavailable"],
    },
  },
  label: {
    layout: [
      { type: "interval-adjust-position" },
      { type: "interval-hide-overlap" },
      { type: "adjust-color" },
    ],
  },
  color: (point: any) => {
    switch (point.status) {
      case "available":
        return "#3dbd7d";
      case "unavailable":
        return "#f46e65";
      case "assigned":
        return "#49a9ee";
      default:
        return "#d9d9d9";
    }
  }
};

export const PoolModemsUsage = (props: { id: string }) => {
  const [state, dispatch] = useReducer(poolModemsUsageReducer, initialState());
  const result = useQuery<g.PoolModemsUsage, g.PoolModemsUsageVariables>(POOL_MODEMS_USAGE, {
    fetchPolicy: "no-cache",
    variables: {
      id: props.id,
      from: state.from,
      to: state.to,
    }
  });

  if (result.loading) {
    return <Spin />;
  }

  const data = result.data!.poolModemsUsage.map(point => {
    return {
      ts: moment(point.ts).format("HH:mm"),
      status: point.status,
      value: point.value,
    };
  });

  return (
    <Fragment>
      <Form name="control">
        <Row gutter={24}>
          <Col span={16}>
            <RangePicker
              allowClear={false}
              showTime={true}
              value={[moment(state.from), moment(state.to)]}
              onChange={(dates) => dispatch({ type: "DATES", data: dates })}
            />
          </Col>
        </Row>
      </Form>
      <br />
      <Column data={data} {...config} />
    </Fragment>
  );
};
