import { Button, Card, Select, Space, Spin } from "antd";
import gql from "graphql-tag";
import React, { useReducer } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "../../../hooks";
import * as g from "./__generated__/PortSignature";
import * as gs from "./__generated__/SearchPortSignature";
import * as gu from "./__generated__/UpdatePortSignature";

const GET_PORT_SIGNATURE = gql`
  query PortSignature($id: ID!) {
    port(id: $id) {
      id
      signature {
        id
        raw
      }
    }
  }
`;

const SEARCH_SIGNATURE = gql`
  query SearchPortSignature($input: SearchInput!) {
    searchSignature(input: $input) {
      data {
        id
        raw
      }
    }
  }
`;

const UPDATE_PORT_SIGNATURE = gql`
  mutation UpdatePortSignature($id: ID!, $signature: String!) {
    updatePortSignature(id: $id, signature: $signature) {
      id
      signature {
        id
        raw
      }
    }
  }
`;

interface IPortSignatureState {
  value: string;
  filter: string
}

const initialState: IPortSignatureState = {
  value: "Linux",
  filter: "",
};

const portSignatureReducer = (
  state: IPortSignatureState,
  action: { type: string, data: any },
) => {
  switch (action.type) {
    case "INIT":
      return { ...state, value: action.data.signature.id };
    case "SEARCH":
      return { ...state, filter: action.data };
    case "SELECT":
      return { ...state, value: action.data };
    default:
      throw new Error(`undefined action ${action.type}`);
  }
};

export const PortSignature = () => {
  const { id } = useParams<{ id: string }>();
  const [state, dispatch] = useReducer(portSignatureReducer, initialState);

  const { loading } = useQuery<g.PortSignature, g.PortSignatureVariables>(GET_PORT_SIGNATURE, {
    variables: { id },
    onCompleted: (data) => {
      dispatch({ type: "INIT", data: data.port });
    }
  });

  const { "data": signatureData } = useQuery<gs.SearchPortSignature, gs.SearchPortSignatureVariables>(SEARCH_SIGNATURE, {
    fetchPolicy: "no-cache",
    variables: {
      input: {
        filter: state.filter,
        page: 1,
        pageSize: 0,
      }
    }
  });

  const [updateSignature] = useMutation<gu.UpdatePortSignature, gu.UpdatePortSignatureVariables>(UPDATE_PORT_SIGNATURE, {
    okText: "Port updated",
  });

  if (loading) {
    return <Spin />;
  }


  return (
    <Card title="Signature">
      <Space direction="vertical" style={{ width: "100%" }}>
        <Select
          value={state.value}
          placeholder="Select signature"
          filterOption={false}
          showSearch={true}
          onSearch={(val) => dispatch({ type: "SEARCH", data: val })}
          onChange={(val) => dispatch({ type: "SELECT", data: val })}
          style={{ width: "100%" }}
        >
          {signatureData?.searchSignature.data.map(d =>
            <Select.Option key={d.id} value={d.id}>{d.id}</Select.Option>
          )}
        </Select>

        <Button
          type="primary"
          style={{ margin: "8px 0" }}
          onClick={() => updateSignature({ variables: { id, signature: state.value } })}
        >
          Update
        </Button>
      </Space>
    </Card>
  );
};
