import { SearchOutlined } from "@ant-design/icons";
import { Button, Checkbox } from "antd";
import { ColumnsType } from "antd/lib/table";
import { SortOrder } from "antd/lib/table/interface";
import gql from "graphql-tag";
import React from "react";
import { Link } from "react-router-dom";
import i18n from "../../common/i18n";
import { Date } from "../../components/Date";
import { ListContainer } from "../../containers/ListContainer";
import { useSearch } from "../../hooks";
import { PlanCreate } from "./Create";
import * as g from "./__generated__/SearchPlan";

const SEARCH_PLAN = gql`
  query SearchPlan($input: SearchInput!) {
    searchPlan(input: $input) {
      data {
        id
        name
        description
        pool
        reservation
        active
        autoRenew
        vpnAccess
        tarifications {
          time
          traffic
          price
        }
        datapacks {
          price
          amount
        }
        portsCount
        createdAt
        updatedAt
      }
      page
      pageSize
      total
    }
  }
`;

const columns: ColumnsType<g.SearchPlan_searchPlan_data> = [
  {
    dataIndex: "name",
    title: i18n.t("plan:Name"),
    sorter: true,
    render: (text, record) => <Link to={`/plans/${record.id}`}>{text}</Link>,
  },
  {
    key: "ports",
    title: i18n.t("user:Ports"),
    render: (_, record) => {
      return (
        <Link to={`/ports/billing?filter=${record.name}`}>
          <Button icon={<SearchOutlined />} size="small">
            {` ${record.portsCount}`}
          </Button>
        </Link>
      );
    }
  },
  {
    dataIndex: "active",
    title: i18n.t("plan:Active"),
    render: (_, record) => <Checkbox checked={record.active} disabled={true} />
  },
  {
    dataIndex: "vpnAcess",
    title: i18n.t("plan:VPN Access"),
    render: (_, record) => <Checkbox checked={record.vpnAccess} disabled={true} />
  },
  {
    dataIndex: "tarifications",
    title: i18n.t("plan:Tarification options"),
    render: (_, record) => record.tarifications.length
  },
  {
    dataIndex: "createdAt",
    title: i18n.t("plan:Created At"),
    sorter: true,
    render: text => <Date date={text} />,
  },
  {
    dataIndex: "updatedAt",
    title: i18n.t("plan:Updated At"),
    sorter: true,
    render: text => <Date date={text} />,
    defaultSortOrder: "descend" as SortOrder,
  },
];

export const PlanList = () => {
  const { result, search, updateFilter, updateTable } = useSearch<g.SearchPlan>(SEARCH_PLAN);

  return (
    <ListContainer
      create={<PlanCreate />}
      search={search}
      columns={columns}
      data={result.data?.searchPlan.data}
      total={result.data?.searchPlan.total}
      loading={result.loading}
      onSearch={updateFilter}
      onTableChange={updateTable}
    />
  );
};
