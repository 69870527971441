import { Button, Space } from "antd";
import { ColumnsType } from "antd/lib/table";
import { SortOrder } from "antd/lib/table/interface";
import gql from "graphql-tag";
import React from "react";
import { Link } from "react-router-dom";
import i18n from "../../common/i18n";
import { Date } from "../../components/Date";
import { ListContainer } from "../../containers/ListContainer";
import { useMutation, useSearch } from "../../hooks";
import * as gu from "./__generated__/ResolveLimitsIncreaseRequest";
import * as g from "./__generated__/SearchLimitsIncreaseRequests";

const SEARCH_LIMITS_INCREASE_REQUESTS = gql`
  query SearchLimitsIncreaseRequests($input: SearchInput!) {
    searchLimitsIncreaseRequests(input: $input) {
      data {
        id
        userId
        login
        portsLimit 
        createdAt
      }
      page
      pageSize
      total
    }
  }
`;

const RESOLVE_LIMITS_INCREASE_REQUEST = gql`
  mutation ResolveLimitsIncreaseRequest($id: ID!, $allowed: Boolean!, $newLimit: Int!) {
    resolveLimitsIncreaseRequest(id: $id, allowed: $allowed, newLimit: $newLimit)
  }
`;

export const UserRequests = () => {
  const { result, search, updateFilter, updateTable } = useSearch<g.SearchLimitsIncreaseRequests>(SEARCH_LIMITS_INCREASE_REQUESTS);

  const [resolveLimitsIncreaseRequest] = useMutation<gu.ResolveLimitsIncreaseRequest, gu.ResolveLimitsIncreaseRequestVariables>(RESOLVE_LIMITS_INCREASE_REQUEST, {
    okText: i18n.t("user:Done"),
    refetchQueries: ["SearchLimitsIncreaseRequests"],
  });

  const columns: ColumnsType<g.SearchLimitsIncreaseRequests_searchLimitsIncreaseRequests_data> = [
    {
      dataIndex: "login",
      title: i18n.t("user:Login"),
      sorter: true,
      render: (text, record) => <Link to={`/users/${record.userId}`}>{text}</Link>,
    },
    {
      dataIndex: "portsLimit",
      title: i18n.t("user:Current Ports Limit"),
    },
    {
      dataIndex: "newLimit",
      title: i18n.t("user:New Ports Limit"),
      render: (_, record) => record.portsLimit * 2,
    },
    {
      dataIndex: "createdAt",
      title: i18n.t("user:Created At"),
      sorter: true,
      defaultSortOrder: "descend" as SortOrder,
      render: text => <Date date={text} />,
    },
    {
      key: "actions",
      title: i18n.t("user:Actions"),
      render: (_, record) => {
        return (
          <Space>
            <Button
              type="primary"
              size="small"
              onClick={() => resolveLimitsIncreaseRequest({ variables: { id: record.id, allowed: true, newLimit: record.portsLimit * 2 } })}
            >
              Accept
            </Button>

            <Button
              size="small"
              onClick={() => resolveLimitsIncreaseRequest({ variables: { id: record.id, allowed: false, newLimit: 0 } })}
            >
              Reject
            </Button>
          </Space>
        );
      }
    },
  ];

  return (
    <ListContainer
      create={<></>}
      search={search}
      columns={columns}
      data={result.data?.searchLimitsIncreaseRequests.data}
      total={result.data?.searchLimitsIncreaseRequests.total}
      loading={result.loading}
      onSearch={updateFilter}
      onTableChange={updateTable}
    />
  );
};
