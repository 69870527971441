import { Card, Spin, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import gql from "graphql-tag";
import React, { Fragment, useReducer } from "react";
import { Link, useParams } from "react-router-dom";
import i18n from "../../common/i18n";
import { Date } from "../../components/Date";
import { useQuery } from "../../hooks";
import * as g from "./__generated__/PoolAssignments";

const GET_POOL_ASSIGNMENTS = gql`
  query PoolAssignments($id: ID!) {
    pool(id: $id) {
      id
      ports
      modems
      assignments {
        port
        modem
        updatedAt
      }
    }
  }
`;

interface IAssignmentsState {
  ports: string[]
  modems: string[]
  assignments: { port: string; modem: string }[]
}

const initialState: IAssignmentsState = {
  ports: [],
  modems: [],
  assignments: [],
};

const assignmentsReducer = (
  state: IAssignmentsState,
  action: { type: string, data: any }
) => {
  switch (action.type) {
    case "INIT":
      return { ...state, ports: action.data.ports, modems: action.data.modems, assignments: action.data.assignments };
    default:
      throw new Error(`undefined action ${action.type}`);
  }
};

export const PoolAssignments = () => {
  const { id } = useParams<{ id: string }>();

  const [state, dispatch] = useReducer(assignmentsReducer, initialState);

  const { loading } = useQuery<g.PoolAssignments, g.PoolAssignmentsVariables>(GET_POOL_ASSIGNMENTS, {
    variables: { id },
    onCompleted: (data) => {
      dispatch({ type: "INIT", data: data.pool });
    }
  });

  if (loading) {
    return <Spin />;
  }

  const columns: ColumnsType<g.PoolAssignments_pool_assignments> = [
    {
      key: "port",
      title: i18n.t("pool:Port"),
      dataIndex: "port",
      render: (text, record) => <Link to={`/ports/${record.port}`}>{text}</Link>
    },
    {
      key: "modem",
      title: i18n.t("pool:Modem"),
      dataIndex: "modem",
      render: (text, record) => <Link to={`/modem/${record.modem}`}>{text}</Link>
    },
    {
      key: "updatedAt",
      title: i18n.t("pool:Updated At"),
      dataIndex: "updatedAt",
      render: text => <Date date={text} />,
    },
  ];

  return (
    <Fragment>
      <Card title="Assignments">
        <Table
          rowKey={record => record.port}
          size="middle"
          columns={columns}
          pagination={false}
          dataSource={state.assignments}
        />
      </Card>
    </Fragment>
  );
};
